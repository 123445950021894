import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Make sure to install axios for making HTTP requests
import {
	getLeague,
	getLeagues,
	updateLeague,
	uploadImage,
} from '../../services/leagueService';
import { getSeries, getSports } from '../../services/series';

const AdminLeaguesPage = () => {
	const navigate = useNavigate();

	const [leagues, setLeagues] = useState([]);
	const [sports, setSports] = useState([]);
	const [series, setSeries] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [sortOrder, setSortOrder] = useState('asc'); // asc or desc
	const [filterSeries, setFilterSeries] = useState('');
	const [filterSport, setFilterSport] = useState('');
	const [image, setImage] = useState(null);
	const [roundImage, setRoundImage] = useState(null);
	const [imageMobile, setImageMobile] = useState(null);
	const [roundImageMobile, setRoundImageMobile] = useState(null);
	const [leagueName, setLeagueName] = useState('');
	const [overallPrizeDescription, setOverallPrizeDescription] = useState('');
	const [roundPrizeDescription, setRoundPrizeDescription] = useState('');
	const [overallPrizeUrl, setOverallPrizeUrl] = useState('');
	const [roundPrizeUrl, setRoundPrizeUrl] = useState('');

	const filteredLeagues = leagues
		.filter((league) => {
			return (
				league.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
				(filterSeries ? league.series.name === filterSeries : true) &&
				(filterSport ? league.sport.name === filterSport : true)
			);
		})
		.sort((a, b) => {
			if (sortOrder === 'asc') {
				return a.name.localeCompare(b.name);
			} else {
				return b.name.localeCompare(a.name);
			}
		});

	useEffect(() => {
		fetchLeagues();
		fetchSports();
		fetchSeries();
	}, []);

	const fetchLeagues = async () => {
		try {
			const response = await getLeagues();
			setLeagues(response.data);
		} catch (error) {
			console.error('Error fetching leagues:', error);
		}
	};

	const fetchSports = async () => {
		try {
			const res = await getSports();
			setSports(res.data);
		} catch (error) {
			console.error('Error fetching sports:', error);
		}
	};

	const fetchSeries = async () => {
		try {
			const res = await getSeries();
			setSeries(res.data);
		} catch (error) {
			console.error('Error fetching series:', error);
		}
	};

	const makeLeagueFeatured = async (leagueId) => {
		try {
			const league = leagues.find((l) => l._id === leagueId);
			const featured = league.featured ? true : false;
			const updatedLeague = { ...league, featured: !featured };

			const res = await updateLeague(leagueId, updatedLeague);
			if (res) {
				console.log('League updated:', res.data);
				fetchLeagues(); // Refresh leagues to reflect changes
			}
		} catch (error) {
			console.error('Error making league featured:', error);
		}
	};

	const handleImageChange = (e) => {
		setImage(e.target.files[0]);
	};

	const handleRoundImageChange = (e) => {
		setRoundImage(e.target.files[0]);
	};

	const handleImageMobileChange = (e) => {
		setImageMobile(e.target.files[0]);
	};

	const handleRoundImageMobileChange = (e) => {
		setRoundImageMobile(e.target.files[0]);
	};

	const handleUpdateLeague = async (league) => {
		try {
			// const league = leagues.find((l) => l._id === leagueId);
			const updatedLeague = {
				...league,
			};

			console.log('updating league. ', updatedLeague);
			const res = await updateLeague(league._id, updatedLeague);
			if (res) {
				console.log('League updated:', res.data);
				fetchLeagues(); // Refresh leagues to reflect changes
			}
		} catch (error) {
			alert('Error updating league:' + error);
		}
	};

	const handlePrizeDescriptionChange = (leagueToUpdate, newValue) => {
		const updatedLeagues = leagues.map((league) => {
			if (league === leagueToUpdate) {
				return {
					...league,
					overallPrizeDescription: newValue,
				};
			}
			return league;
		});

		setLeagues(updatedLeagues);
	};

	const handlePrizeUrlChange = (leagueToUpdate, newValue) => {
		const updatedLeagues = leagues.map((league) => {
			if (league === leagueToUpdate) {
				return {
					...league,
					overallPrizeUrl: newValue,
				};
			}
			return league;
		});

		setLeagues(updatedLeagues);
	};

	const handleRoundPrizeDescriptionChange = (leagueToUpdate, newValue) => {
		const updatedLeagues = leagues.map((league) => {
			if (league === leagueToUpdate) {
				return {
					...league,
					roundPrizeDescription: newValue,
				};
			}
			return league;
		});

		setLeagues(updatedLeagues);
	};

	const handleRoundPrizeUrlChange = (leagueToUpdate, newValue) => {
		const updatedLeagues = leagues.map((league) => {
			if (league === leagueToUpdate) {
				return {
					...league,
					roundPrizeUrl: newValue,
				};
			}
			return league;
		});

		setLeagues(updatedLeagues);
	};

	const handleClearImage = async (league) => {
		try {
			const updatedLeague = {
				...league,
				image: '',
			};

			const res = await updateLeague(league._id, updatedLeague);
			if (res) {
				console.log('League updated:', res.data);
				fetchLeagues(); // Refresh leagues to reflect changes
			}
		} catch (error) {
			alert('Error updating league:' + error);
		}
	};

	const handleClearRoundImage = async (league) => {
		try {
			const updatedLeague = {
				...league,
				roundImage: '',
			};

			const res = await updateLeague(league._id, updatedLeague);
			if (res) {
				console.log('League updated:', res.data);
				fetchLeagues(); // Refresh leagues to reflect changes
			}
		} catch (error) {
			alert('Error updating league:' + error);
		}
	};

	const handleSubmit = async (e, league) => {
		e.preventDefault();

		const formData = new FormData();
		formData.append('image', image);
		formData.append('roundImage', roundImage);
		formData.append('imageMobile', imageMobile);
		formData.append('roundImageMobile', roundImageMobile);

		try {
			const res = await uploadImage(league._id, formData);

			alert('Images uploaded successfully');
		} catch (error) {
			console.error('Error uploading images:', error);
			alert('Failed to upload images');
		}
	};

	return (
		<div className='container bg-light py-4'>
			<h1 className='text-center mb-4'>All User Leagues</h1>
			<div className='mb-4'>
				<Row className='g-3 mx-2'>
					<Col md>
						<input
							type='text'
							className='form-control'
							placeholder='Search leagues...'
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
					</Col>
					<Col md>
						<select
							className='form-select'
							value={filterSeries}
							onChange={(e) => setFilterSeries(e.target.value)}
						>
							<option value=''>Filter by Series</option>
							{Array.isArray(series) &&
								series.map((series) => (
									<option key={series._id} value={series.name}>
										{series.name}
									</option>
								))}
						</select>
					</Col>
					<Col md>
						<select
							className='form-select'
							value={filterSport}
							onChange={(e) => setFilterSport(e.target.value)}
						>
							<option value=''>Filter by Sport</option>
							{Array.isArray(sports) &&
								sports.map((sport) => (
									<option key={sport._id} value={sport.name}>
										{sport.name}
									</option>
								))}
						</select>
					</Col>
					<Col md>
						<select
							className='form-select'
							value={sortOrder}
							onChange={(e) => setSortOrder(e.target.value)}
						>
							<option value='asc'>Sort Alphabetically A-Z</option>
							<option value='desc'>Sort Alphabetically Z-A</option>
						</select>
					</Col>
				</Row>
			</div>
			{filteredLeagues.map((league) => (
				<Row className='g-4 mb-2 mx-3'>
					<Col key={league._id}>
						<Card>
							<Card.Body style={{ fontSize: '0.7rem' }}>
								<Row>
									<Col>
										<Card.Subtitle
											className='text-muted'
											style={{ fontSize: '0.7rem' }}
										>
											{league.series.name} : {league.sport.name}
										</Card.Subtitle>
									</Col>
									<Col>
										<Card.Text>{league.name}</Card.Text>
									</Col>
									<Col>
										<Card.Text>Teams: {league.teams.length}</Card.Text>
									</Col>
									<Col> {league.private ? 'Private' : 'Public'}</Col>
									<Col>
										<Button
											variant='outline-primary'
											onClick={() => navigate(`/league/${league._id}`)}
											style={{ fontSize: '0.7rem' }}
										>
											Go to League
										</Button>
									</Col>
									<Col>
										<Button
											variant={
												`${!league?.featured ? 'outline-' : ''}` + 'secondary'
											}
											className='ms-2'
											onClick={() => makeLeagueFeatured(league._id)}
											style={{ fontSize: '0.7rem' }}
										>
											{league?.featured ? 'Featured' : 'Not Featured'}
										</Button>
									</Col>
								</Row>
								Users can edit league details
								<Form>
									{/* <Form.Group controlId='formBasicEmail' className='mb-3'>
										<Form.Label>Name</Form.Label>
										<Form.Control
											type='text'
											placeholder='Enter league name'
											value={league.name}
											onChange={(e) => setLeagueName(e.target.value)}
										/>
									</Form.Group> */}
									<Form.Group controlId='formBasicEmail' className='mb-3'>
										<Form.Label>Overall Prize Description</Form.Label>
										<Form.Control
											type='text'
											placeholder='Enter overall prize description'
											value={league.overallPrizeDescription}
											onChange={(e) =>
												handlePrizeDescriptionChange(league, e.target.value)
											}
										/>
									</Form.Group>
									<Form.Group controlId='formBasicEmail' className='mb-3'>
										<Form.Label>Round Prize Description</Form.Label>
										<Form.Control
											type='text'
											placeholder='Enter Round Prize Description'
											value={league.roundPrizeDescription}
											onChange={(e) =>
												handleRoundPrizeDescriptionChange(
													league,
													e.target.value,
												)
											}
										/>
									</Form.Group>
									<Form.Group controlId='formBasicEmail' className='mb-3'>
										<Form.Label>Overall Prize URL</Form.Label>
										<Form.Control
											type='text'
											placeholder='Enter overall prize URL'
											value={league.overallPrizeUrl}
											onChange={(e) =>
												handlePrizeUrlChange(league, e.target.value)
											}
										/>
									</Form.Group>
									<Form.Group controlId='formBasicEmail' className='mb-3'>
										<Form.Label>Round Prize URL</Form.Label>
										<Form.Control
											type='text'
											placeholder='Enter Round Prize URL'
											value={league.roundPrizeUrl}
											onChange={(e) =>
												handleRoundPrizeUrlChange(league, e.target.value)
											}
										/>
									</Form.Group>

									<div className='text-end mb-2'>
										<Button
											variant='primary'
											onClick={() => handleUpdateLeague(league)}
										>
											Update League
										</Button>
									</div>
								</Form>
								<Row>
									<Accordion>
										<Accordion.Item eventKey='0'>
											<Accordion.Header>Images</Accordion.Header>
											<Accordion.Body>
												<Form onSubmit={(e) => handleSubmit(e, league)}>
													<Form.Group controlId='formFile' className='mb-3'>
														<Form.Label>Main Image: {league?.image}</Form.Label>
														<Form.Control
															type='file'
															onChange={handleImageChange}
														/>
													</Form.Group>
													<div className='d-flex flex-row justify-content-end my-2'>
														<Button
															variant='secondary'
															size='sm'
															onClick={() => handleClearImage(league)}
														>
															Clear Image
														</Button>
													</div>
													<Form.Group
														controlId='formFileMultiple'
														className='mb-3'
													>
														<Form.Label>
															Round Image: {league?.roundImage}
														</Form.Label>
														<Form.Control
															type='file'
															onChange={handleRoundImageChange}
														/>
													</Form.Group>
													<div className='d-flex flex-row justify-content-end my-2'>
														<Button
															variant='secondary'
															size='sm'
															onClick={() => handleClearRoundImage(league)}
														>
															Clear Round Image
														</Button>
													</div>
													{/* <Form.Group
														controlId='formFileMultiple'
														className='mb-3'
													>
														<Form.Label>Main Image Mobile</Form.Label>
														<Form.Control
															type='file'
															onChange={handleImageMobileChange}
														/>
													</Form.Group>
													<Form.Group
														controlId='formFileMultiple'
														className='mb-3'
													>
														<Form.Label>Round Image Mobile</Form.Label>
														<Form.Control
															type='file'
															onChange={handleRoundImageMobileChange}
														/>
													</Form.Group> */}
													<Button variant='primary' type='submit'>
														Upload Images
													</Button>
												</Form>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			))}
		</div>
	);
};

export default AdminLeaguesPage;
