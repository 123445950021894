import React, { useState, useEffect } from 'react';
import {
	Card,
	Button,
	ToggleButton,
	ToggleButtonGroup,
	Col,
	Row,
} from 'react-bootstrap';
import { FaUsers } from 'react-icons/fa';
import { LuDot } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';

const LeaguesComponent = ({ userLeagues, allLeagues, sports, series }) => {
	const navigate = useNavigate();

	const [toggleValue, setToggleValue] = useState(
		userLeagues.length > 0 ? 'myLeagues' : 'allLeagues',
	);

	const [searchQuery, setSearchQuery] = useState('');
	const [sortOrder, setSortOrder] = useState('asc'); // asc or desc
	const [filterSeries, setFilterSeries] = useState('');
	const [filterSport, setFilterSport] = useState('');
	const [visibleLeagues, setVisibleLeagues] = useState(5);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

	const filteredLeagues = (
		toggleValue === 'myLeagues' ? userLeagues : allLeagues
	)
		.filter((league) => {
			return (
				league.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
				(filterSeries ? league.series.name === filterSeries : true) &&
				(filterSport ? league.sport.name === filterSport : true)
			);
		})
		.sort((a, b) => {
			if (a.featured && !b.featured) return -1;
			if (!a.featured && b.featured) return 1;
			if (sortOrder === 'asc') {
				return a.name.localeCompare(b.name);
			} else {
				return b.name.localeCompare(a.name);
			}
		});

	useEffect(() => {
		if (userLeagues.length > 0) {
			setToggleValue('myLeagues');
		} else {
			setToggleValue('allLeagues');
		}
	}, [userLeagues]);

	const handleToggleChange = (value) => {
		setToggleValue(value);
	};

	const handleShowMore = () => {
		setVisibleLeagues((prevVisible) => prevVisible + 10);
	};

	return (
		<div className='mx-sm-4 mt-5'>
			<div className='d-flex flex-row justify-content-center mb-3'>
				{userLeagues?.length > 0 ? (
					<ToggleButtonGroup
						type='radio'
						name='leagueToggle'
						value={toggleValue}
						onChange={handleToggleChange}
						className='mb-3'
					>
						<ToggleButton value='myLeagues' variant='dark' id={'myLeagues'}>
							My Leagues
						</ToggleButton>
						<ToggleButton value='allLeagues' variant='dark' id={'allLeagues'}>
							All Leagues
						</ToggleButton>
					</ToggleButtonGroup>
				) : (
					<Card className='border-0'>
						<Card.Body className='bg-secondary px-4 rounded-2 text-white fs-5  py-2'>
							Leagues
						</Card.Body>
					</Card>
				)}
			</div>
			<div className='mb-4'>
				<Row className='g-3 mx-2'>
					<Col md>
						<input
							type='text'
							className='form-control'
							placeholder='Search leagues...'
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
					</Col>
					<Col md>
						<select
							className='form-select'
							value={filterSeries}
							onChange={(e) => setFilterSeries(e.target.value)}
						>
							<option value=''>Filter by Series</option>
							{Array.isArray(series) &&
								series.map((series) => (
									<option key={series._id} value={series.name}>
										{series.name}
									</option>
								))}
						</select>
					</Col>
					<Col md>
						<select
							className='form-select'
							value={filterSport}
							onChange={(e) => setFilterSport(e.target.value)}
						>
							<option value=''>Filter by Sport</option>
							{Array.isArray(sports) &&
								sports.map((sport) => (
									<option key={sport._id} value={sport.name}>
										{sport.name}
									</option>
								))}
						</select>
					</Col>
					<Col md>
						<select
							className='form-select'
							value={sortOrder}
							onChange={(e) => setSortOrder(e.target.value)}
						>
							<option value='asc'>Sort Alphabetically A-Z</option>
							<option value='desc'>Sort Alphabetically Z-A</option>
						</select>
					</Col>
				</Row>
			</div>
			<div className=''>
				{toggleValue === 'myLeagues' && (
					<div className='pb-4'>
						{filteredLeagues.slice(0, visibleLeagues).map((league) => (
							<Card key={league._id} className='mb-4 mx-sm-3 shadow'>
								<Card.Header className=' bg-light text-dark'>
									<Row>
										<Col md={7}>
											<Card.Title className='mt-2 ms-2'>
												<div className='d-flex flex-row justify-content-md-between justify-content-center'>
													<div className=''>
														<FaUsers className='mb-1 me-2' />
														{league.name}
													</div>
													{!isMobile && (
														<div
															className=' text-muted'
															style={{ fontSize: '0.8rem' }}
														>
															{league.series.name} <LuDot /> {league.sport.name}
														</div>
													)}
												</div>
											</Card.Title>
										</Col>
										{isMobile && (
											<Col
												md
												className='text-center flex-grow text-muted mb-2'
												style={{ fontSize: '0.8rem' }}
											>
												{league.series.name} <LuDot /> {league.sport.name}
											</Col>
										)}
										<Col className='text-md-end text-center mt-md-0 mt-2'>
											<Button
												variant='dark'
												className='ms-auto me-2 px-3'
												onClick={() => navigate(`/league/${league._id}`)}
											>
												View League
											</Button>
										</Col>
									</Row>
								</Card.Header>
								<Card.Body>
									<Card.Text className='text-md-start text-center'>
										{' '}
										{league.description}
									</Card.Text>
									<Row className='d-flex flex-row justify-content-start w-100 text-md-start text-center'>
										<Col
											className='text-md-end text-center'
											style={{ fontSize: '0.8rem' }}
										>
											<Card.Text className='text-muted'>
												Teams: {league.teams.length}
											</Card.Text>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						))}
					</div>
				)}

				{toggleValue === 'allLeagues' && (
					<div className='pb-4'>
						{filteredLeagues.slice(0, visibleLeagues).map((league) => (
							<Card key={league._id} className='mb-4 mx-sm-3 shadow'>
								<Card.Header className=' bg-light text-dark'>
									<Row>
										<Col md={7}>
											<Card.Title className='mt-2 ms-2'>
												<div className='d-flex flex-row justify-content-md-between justify-content-center'>
													<div className=''>
														<FaUsers className='mb-1 me-2' />
														{league.name}
													</div>
													{!isMobile && (
														<div
															className=' text-muted'
															style={{ fontSize: '0.8rem' }}
														>
															{league.series.name} <LuDot /> {league.sport.name}
														</div>
													)}
												</div>
											</Card.Title>
										</Col>
										{isMobile && (
											<Col
												md
												className='text-center flex-grow text-muted mb-2'
												style={{ fontSize: '0.8rem' }}
											>
												{league.series.name} <LuDot /> {league.sport.name}
											</Col>
										)}
										<Col className='text-md-end text-center mt-md-0 mt-2'>
											<Button
												variant='dark'
												className='ms-auto me-2 px-3'
												onClick={() => navigate(`/league/${league._id}`)}
											>
												View League
											</Button>
										</Col>
									</Row>
								</Card.Header>
								<Card.Body>
									<Card.Text className='text-md-start text-center'>
										{' '}
										{league.description}
									</Card.Text>
									<Row className='d-flex flex-row justify-content-start w-100 text-md-start text-center'>
										<Col
											className='text-md-end text-center'
											style={{ fontSize: '0.8rem' }}
										>
											<Card.Text className='text-muted'>
												Teams: {league.teams.length}
											</Card.Text>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						))}
					</div>
				)}
				<div className='d-flex flex-row justify-content-center mb-3'>
					{filteredLeagues.length > visibleLeagues && (
						<Button variant='outline-success' onClick={handleShowMore}>
							Show 10 More
						</Button>
					)}
				</div>
			</div>
		</div>
	);
};

export default LeaguesComponent;
