import React, { useState, useEffect } from "react";
import {
	Container,
	Row,
	Col,
	Button,
	Form,
	Card,
	Modal,
} from "react-bootstrap";
import {
	createRider,
	deleteRider,
	updateRider,
} from "../../../../services/riderService";
import { getSeries, getSports } from "../../../../services/series";

const CreateRiderForm = ({ riderData, setSelectedRider, fetchRiders }) => {
	const [sportsList, setSportsList] = useState([]);
	const [seriesList, setSeriesList] = useState([]);
	const [showDeleteRider, setShowDeleteRider] = useState(false);
	const [formData, setFormData] = useState(
		riderData || {
			first_name: "",
			last_name: "",
			country: "",
			href: "",
			DOB: "",
			team: "",
			team_short: "",
			gender: "",
			status: "",
			ratings: [],
			sports: [],
			series: [],
		},
	);

	useEffect(() => {
		fetchSports();
		fetchSeries();
	}, []);

	useEffect(() => {
		console.log("HERE", riderData);
		if (riderData) {
			if (!riderData.status) riderData.status = "";
			setFormData(riderData);
		} else
			setFormData({
				first_name: "",
				last_name: "",
				country: "",
				href: "",
				DOB: "",
				team: "",
				team_short: "",
				gender: "",
				ratings: [],
				sports: [],
				series: [],
			});
	}, [riderData]);

	const clearRider = () => {
		setSelectedRider({
			first_name: "",
			last_name: "",
			country: "",
			href: "",
			DOB: "",
			team: "",
			team_short: "",
			gender: "",
			status: "",
			ratings: [],
			sports: [],
			series: [],
		});
	};

	const fetchSports = async () => {
		try {
			const res = await getSports();
			if (res) {
				setSportsList(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const fetchSeries = async () => {
		try {
			const res = await getSeries();
			if (res) {
				setSeriesList(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			if (riderData?._id) {
				const res = await updateRider(riderData._id, formData);
				if (res) {
					fetchRiders();
					clearRider();
				}
			} else {
				const res = await createRider(formData);
				if (res) {
					fetchRiders();
					clearRider();
				}
			}
		} catch (error) {
			alert("Error creating rider:" + error);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSportAndSeriesChange = (e) => {
		const { name, value, options } = e.target;

		const selectedValues = Array.from(options)
			.filter((option) => option.selected)
			.map((option) => option.value);
		setFormData({
			...formData,
			[name]: selectedValues,
		});
	};

	const handleRatingChange = (e) => {
		const { name, value } = e.target;
		const sportId = name.split("-")[1];
		setFormData({
			...formData,
			ratings: [
				...formData.ratings.filter((rating) => rating.sport !== sportId),
				{
					sport: sportId,
					rating: value,
				},
			],
		});
	};

	const handleDeleteRider = async () => {
		try {
			const res = await deleteRider(riderData._id);
			if (res) {
				fetchRiders();
				setShowDeleteRider(false);
				clearRider();
			}
		} catch (error) {
			alert("Error deleting rider:" + error);
		}
	};

	return (
		<>
			<Card className="p-4 shadow border-0 mt-4" style={{ fontSize: "0.8rem" }}>
				<Card.Title>Create a new rider</Card.Title>
				<Card.Body>
					<Form onSubmit={handleSubmit}>
						<Row>
							<Col sm={4}>
								<Button variant="outline-secondary" onClick={clearRider}>
									Clear Selected Rider
								</Button>
							</Col>
						</Row>
						<Row className="mt-3">
							<Col sm={3}>
								<Form.Group controlId="first_name">
									<Form.Label>First Name</Form.Label>
									<Form.Control
										type="text"
										name="first_name"
										value={formData.first_name}
										onChange={handleChange}
									/>
								</Form.Group>
							</Col>
							<Col sm={3}>
								<Form.Group controlId="last_name">
									<Form.Label>Last Name</Form.Label>
									<Form.Control
										type="text"
										name="last_name"
										value={formData.last_name}
										onChange={handleChange}
									/>
								</Form.Group>
							</Col>
							<Col sm={3}>
								<Form.Group controlId="country">
									<Form.Label>Country</Form.Label>
									<Form.Control
										type="text"
										name="country"
										value={formData.country}
										onChange={handleChange}
									/>
								</Form.Group>
							</Col>
							<Col sm={3}>
								<Form.Group controlId="gender">
									<Form.Label>Gender</Form.Label>
									<Form.Control
										as="select"
										name="gender"
										value={formData.gender}
										onChange={handleChange}
									>
										<option style={{ fontSize: "0.8rem" }} value=""></option>
										<option style={{ fontSize: "0.8rem" }} value="m">
											Male
										</option>
										<option style={{ fontSize: "0.8rem" }} value="f">
											Female
										</option>
									</Form.Control>
								</Form.Group>
							</Col>
						</Row>
						<Row className="mt-3">
							<Col sm={3}>
								<Form.Group controlId="DOB">
									<Form.Label>Date of Birth</Form.Label>
									<Form.Control
										type="date"
										name="DOB"
										value={formData.DOB}
										onChange={handleChange}
									/>
								</Form.Group>
							</Col>
							<Col sm={3}>
								<Form.Group controlId="team">
									<Form.Label>Team</Form.Label>
									<Form.Control
										type="text"
										name="team"
										value={formData.team}
										onChange={handleChange}
									/>
								</Form.Group>
							</Col>
							<Col sm={3}>
								<Form.Group controlId="team_short">
									<Form.Label>Team Short</Form.Label>
									<Form.Control
										type="text"
										name="team_short"
										value={formData.team_short}
										onChange={handleChange}
									/>
								</Form.Group>
							</Col>
							<Col sm={3}>
								<Form.Group controlId="status">
									<Form.Label>Status</Form.Label>
									<Form.Control
										as="select"
										name="status"
										value={formData.status}
										onChange={handleChange}
									>
										<option style={{ fontSize: "0.8rem" }} value="Active">
											Active
										</option>
										<option style={{ fontSize: "0.8rem" }} value="Injured">
											Injured
										</option>
										<option style={{ fontSize: "0.8rem" }} value="Ineligible">
											Ineligible
										</option>
									</Form.Control>
								</Form.Group>
							</Col>
						</Row>
						<Row className="mt-3">
							<Col sm={6}>
								<Form.Group controlId="sports">
									<Form.Label>Select Sports</Form.Label>
									<Form.Control
										as="select"
										multiple
										name="sports"
										value={formData.sports}
										onChange={handleSportAndSeriesChange}
									>
										{sportsList.map((sport) => (
											<option
												style={{ fontSize: "0.8rem" }}
												key={sport?._id}
												value={sport?._id}
											>
												{sport.name}
											</option>
										))}
									</Form.Control>
								</Form.Group>
							</Col>
							<Col sm={6}>
								<Form.Group controlId="series">
									<Form.Label>Select Series</Form.Label>
									<Form.Control
										as="select"
										multiple
										name="series"
										value={formData.series}
										onChange={handleSportAndSeriesChange}
									>
										{seriesList.map((serie) => (
											<option
												style={{ fontSize: "0.8rem" }}
												key={serie._id}
												value={serie._id}
											>
												{serie.name}
											</option>
										))}
									</Form.Control>
								</Form.Group>
							</Col>
						</Row>
						{/* Ratings for each selected sport */}
						{sportsList.map(
							(sport) =>
								formData.sports.includes(sport?._id) && (
									<Row key={sport._id} className="mt-3">
										<Col>
											<Form.Group controlId={`ratings-${sport._id}`}>
												<Form.Label>
													Ratings for {sport.name} (Out of 100)
												</Form.Label>
												<Form.Control
													type="number"
													name={`ratings-${sport._id}`}
													value={
														formData.ratings.find(
															(rating) => rating.sport === sport._id,
														)?.rating || ""
													}
													onChange={handleRatingChange}
												/>
											</Form.Group>
										</Col>
									</Row>
								),
						)}
						<Row>
							<Col>
								<Button variant="primary" type="submit" className="mt-3">
									{riderData?._id ? "Update Rider" : "Create Rider"}
								</Button>
							</Col>
							<Col>
								<Button
									variant="dark"
									onClick={() => setShowDeleteRider(true)}
									className="mt-3"
								>
									Delete Rider
								</Button>
							</Col>
						</Row>
					</Form>
				</Card.Body>
			</Card>
			<Modal show={showDeleteRider} onHide={() => setShowDeleteRider(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Delete Rider</Modal.Title>
				</Modal.Header>
				<Modal.Body>Are you sure you want to delete this rider?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowDeleteRider(false)}>
						Close
					</Button>
					<Button variant="danger" onClick={handleDeleteRider}>
						Delete
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default CreateRiderForm;
