import apiInstance from './api';

export const getSnapshots = async () => {
	return apiInstance.get(`/snapshots/names`);
};

export const takeSnapshot = async (snapshotData) => {
	return apiInstance.post(`/snapshots`, snapshotData);
};

export const deleteSnapshot = async (name) => {
	return apiInstance.delete(`/snapshots/snapshot/${name}`);
};

export const getSnapshotsByTeam = async (teamId) => {
	return apiInstance.get(`/snapshots/team/${teamId}`);
};
