import axios from 'axios';
import apiInstance from './api';

const API_URL = process.env.REACT_APP_API_URL;

export const signUp = async (userData) => {
	console.log(userData);
	return apiInstance.post(`/users`, userData);
};

export const signIn = async (userData) => {
	return apiInstance.post(`/users/login`, userData);
};

export const signOut = async () => {
	return apiInstance.post(`/users/logout`);
};

export const googleSignIn = async (googleData) => {
	console.log(process.env.REACT_APP_API_URL);
	return apiInstance.post(`/users/google`, googleData);
};

export const linkAccount = async (googleData) => {
	return apiInstance.post(`/users/linkaccount`, googleData);
};

export const forgotPassword = async (email) => {
	return apiInstance.post(`/users/forgotpassword`, { email });
};

export const resetPassword = async (token, password) => {
	return apiInstance.post(`/users/resetpassword/${token}`, { password });
};
