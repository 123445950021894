import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { updateVideo } from '../../../../services/videoService';
import { useState } from 'react';

function VideoSection({ title, videos, handleUpdateVideo, setVideos }) {
	const [thumbnailUrls, setThumbnailUrls] = useState({});

	const extractVideoID = (url) => {
		const regExp =
			/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
		const match = url.match(regExp);

		return match && match[2].length === 11 ? match[2] : null;
	};

	// Function to generate thumbnail URL and initiate update
	const generateThumbnail = (video) => {
		if (!video.videoUrl) {
			alert('Please enter a video URL');
			return;
		}
		const videoID = extractVideoID(video.videoUrl);
		if (videoID) {
			const thumbnailUrl = `https://img.youtube.com/vi/${videoID}/sddefault.jpg`;
			setVideos((prevVideos) => {
				const updatedVideos = prevVideos.map((v) => {
					if (v._id === video._id) {
						return { ...v, thumbnailUrl };
					}
					return v;
				});
				return updatedVideos;
			});
		} else {
			console.error('Invalid YouTube URL');
		}
	};

	return (
		<Card className='mb-3'>
			<Card.Header>{title}</Card.Header>
			<Card.Body>
				{Array.isArray(videos) &&
					videos.length > 0 &&
					videos.map((video) => (
						<Card>
							<Card.Body>
								<Row>
									<Col md={4}>
										<iframe src={video.videoUrl} title={video.title} />
									</Col>
									<Col md={8}>
										<Form
											key={video._id}
											onSubmit={(event) => handleUpdateVideo(event, video._id)}
										>
											<Form.Group controlId={`formTitle-${video._id}`}>
												<Form.Label>Title</Form.Label>
												<Form.Control
													type='text'
													name='title'
													defaultValue={video.title}
												/>
											</Form.Group>
											<Form.Group controlId={`formVideoUrl-${video._id}`}>
												<Form.Label>Video URL</Form.Label>
												<Form.Control
													type='text'
													name='videoUrl'
													defaultValue={video.videoUrl}
												/>
											</Form.Group>
											<Form.Group controlId={`formThumbnailUrl-${video._id}`}>
												<Form.Label>Thumbnail URL</Form.Label>
												<Form.Control
													type='text'
													name='thumbnailUrl'
													value={video.thumbnailUrl || ''}
													defaultValue={video.thumbnailUrl || ''}
												/>
											</Form.Group>
											<Form.Group controlId={`formOrder-${video._id}`}>
												<Form.Label>Order</Form.Label>
												<Form.Control
													type='number'
													name='order'
													defaultValue={video?.order}
												/>
											</Form.Group>

											<div className='d-flex flex-row justify-content-end mt-3'>
												<Button
													variant='secondary'
													type='button'
													onClick={() => generateThumbnail(video)}
												>
													Generate Thumbnail
												</Button>
												<Button
													variant='primary'
													type='submit'
													className='px-5'
												>
													Update
												</Button>
											</div>
										</Form>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					))}
			</Card.Body>
		</Card>
	);
}

export default VideoSection;
