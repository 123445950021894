import React from 'react';
import { Container, Row, Col, Nav, Form, Button } from 'react-bootstrap';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { signUp } from '../../services/mailChimpService';

const Footer = () => {
	const [showThankYou, setShowThankYou] = React.useState(false);

	const handleSubmitEmail = async (email) => {
		try {
			const res = await signUp({ email: email });
			if (res.status == 201) {
				setShowThankYou(true);
			}
		} catch (error) {
			console.log(error);
			setShowThankYou(true);
		}
	};

	return (
		<footer className=' py-3 bg-dark text-white'>
			<Container className='' style={{ fontSize: '0.7rem' }}>
				<Row>
					<Col md={6} className='text-sm-start text-center'>
						<Nav defaultActiveKey='/home' className='flex-column'>
							<Nav.Link href='/about'>About Us</Nav.Link>
							<Nav.Link href='/contact'>Contact Us</Nav.Link>
							<Nav.Link href='/privacy-policy'>Privacy Policy</Nav.Link>
							<Nav.Link href='/termsandconditions'>
								Terms and Conditions
							</Nav.Link>
						</Nav>
					</Col>
					<Col md={6}>
						{showThankYou ? (
							<div className='text-sm-end text-center'>
								<h4>Thank you for signing up! </h4>
								<h6> Stay tuned for exciting announcements. </h6>
							</div>
						) : (
							<div className='text-sm-start text-center mt-sm-0 mt-3'>
								<h5 style={{ fontSize: '0.7rem' }}>
									{' '}
									Subscribe to our Newsletter
								</h5>
								<Form
									className='text-sm-end text-center'
									onSubmit={handleSubmitEmail}
								>
									<Form.Group
										controlId='newsletterEmail'
										style={{ fontSize: '0.7rem' }}
									>
										<Form.Control
											type='email'
											placeholder='Enter email'
											style={{ fontSize: '0.7rem' }}
										/>
									</Form.Group>
									<Button
										variant='primary'
										type='submit'
										className='mt-2'
										style={{ fontSize: '0.7rem' }}
									>
										Subscribe
									</Button>
								</Form>
							</div>
						)}
						<div className='social-icons mt-3 text-sm-end text-center'>
							<a
								href='https://www.facebook.com/theridecompanion/'
								className='me-2 text-white'
							>
								<FaFacebookF />
							</a>
							<a
								href='https://twitter.com/ride_companion?lang=en'
								className='me-2 text-white'
							>
								<FaTwitter />
							</a>
							<a
								href='https://www.instagram.com/theracecompanion/'
								className='text-white'
							>
								<FaInstagram />
							</a>
						</div>
					</Col>
				</Row>
				<Row>
					<Col className='text-center py-3'>
						© {new Date().getFullYear()} The Race Companion. All Rights
						Reserved.
					</Col>
				</Row>
			</Container>
		</footer>
	);
};

export default Footer;
