import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button, Modal } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import { FaCircle, FaDotCircle, FaEdit } from "react-icons/fa";
import { LuDot } from "react-icons/lu";
import { getCode, getName } from "country-list";
import { MdEdit, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { FaMapMarkedAlt } from "react-icons/fa";
import { Navigate, useNavigate } from "react-router-dom";
import { FiLogIn } from "react-icons/fi";
import { FaFlag, FaLocationPin, FaUsers } from "react-icons/fa6";
import { getGlobalLeague } from "../../../services/leagueService";
import {
	getDisabled,
	getDisabledSportSeries,
} from "../../../services/disableService";
import { IoMdGlobe } from "react-icons/io";
import hardline_logo_small from "../../../assets/Hardline_Logo_Small.png";

const EventCard = ({ event }) => {
	const [isMobile, setIsMobile] = useState(false);
	const navigate = useNavigate();
	const [showModal, setShowModal] = useState(false);
	const [globalLeague, setGlobalLeague] = useState(null);
	const [disable, setDisable] = useState(false);

	const isHardline = event?.series?.name === "Red Bull Hardline";

	// Assuming event.roundNumber is provided. If not, you'll need to calculate it based on your logic.
	// const roundNumber = event.roundNumber || 'N/A'; // Placeholder calculation
	const getFullYear = (date) => {
		return new Date(date).getFullYear();
	};

	useEffect(() => {
		fetchGlobalLeague();
		fetchDisable();
	}, [event]);

	const fetchGlobalLeague = async () => {
		try {
			const res = await getGlobalLeague(event.sport._id, event.series._id);
			setGlobalLeague(res.data);
		} catch (error) {
			console.error("Error fetching global league:", error);
		}
	};

	const fetchDisable = async () => {
		try {
			const res = await getDisabledSportSeries(
				event.sport._id,
				event.series._id,
			);
			setDisable(res.data[0].disable);
		} catch (error) {
			console.error("Error fetching disable:", error);
		}
	};

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768); // Adjust threshold as needed
		};

		handleResize(); // Initial check
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const handlePickTeam = (event) => {
		//check if user is logged in
		try {
			const userId = localStorage.getItem("userId");
			if (!userId) {
				setShowModal(true);
				return;
			}

			//navigate to pick team page
			navigate(`/editteam?sport=${event.sport._id}&series=${event.series._id}`);
		} catch (error) {
			console.error("Error handling pick team:", error);
		}
	};

	const handleGoToLeague = (event) => {
		navigate(`/league/${globalLeague?._id}`);
	};

	return (
		<>
			<Card className="my-3 mx-md-0 mx-2 shadow bg-white text-dark">
				<Card.Body className="pt-2 pb-2">
					<Row className="">
						<Col
							xl={4}
							xs={12}
							className="text-center"
							style={{ overflow: "hidden" }}
						>
							<img
								className="rounded mb-lg-0 mb-3 pt-1"
								src={event?.eventImage}
								alt={event?.name}
								style={{ width: "19em", height: "9.5rem" }}
							/>
						</Col>
						<Col xl={8} xs={12}>
							<Card
								style={{
									border: "none",
									borderRight: "2px solid gray",
									borderBottom: "2px solid gray",
									borderTopRightRadius: "0",
									borderBottomLeftRadius: "0",
									borderColor: "lightgray",
								}}
								className="mb-2 pb-3 bg-white text-dark"
							>
								<div className="d-flex flex-row justify-content-between">
									<div className="d-flex flex-column">
										<Card.Title className="fs-2 fw-bold mt-2 mb-2">
											<ReactCountryFlag
												countryCode={event?.country}
												svg
												className="me-2 mb-1"
												style={{ width: "2rem", height: "2rem" }}
											/>{" "}
											{event?.location.toUpperCase()}
										</Card.Title>
										<Card.Text className=" fs-5">
											{event?.series.name.toUpperCase()} <LuDot />{" "}
											{event?.sport.name.toUpperCase()}
											{/* {getFullYear(event?.date)} */}
										</Card.Text>
									</div>
									{!isMobile && (
										<div className="me-4 mt-2">
											{isHardline ? (
												<img
													style={{ width: "5.5rem", height: "auto" }}
													src={hardline_logo_small}
												/>
											) : (
												<Button
													variant="dark py-3"
													onClick={() => window.open(event?.eventUrl, "_blank")}
												>
													<div className="d-flex flex-column align-items-center">
														<FaMapMarkedAlt />
														<div className="">Event Site</div>
													</div>
												</Button>
											)}
										</div>
									)}
								</div>
							</Card>
							<Row className="mt-3">
								<Col lg={6}>
									<div className="d-flex flex-row mb-3 mb-sm-0">
										<Card
											style={{
												minWidth: "fit-content",
												fontSize: "0.9rem",
												textWrap: "nowrap",
											}}
											className="bg-dark text-white px-2 py-1 ms-0"
										>
											<Card.Text>
												<FaFlag className="mb-1 me-2" />
												<span className="" style={{ textWrap: "nowrap" }}>
													Round {event?.round}
												</span>
											</Card.Text>
										</Card>
										<div
											className="pt-1 ms-2"
											style={{
												overflow: "hidden",
												textWrap: "nowrap",
												textOverflow: "ellipsis",
											}}
										>
											{/* <FaLocationPin className='mb-1 me-2' /> */}
											{getName(event?.country)}
										</div>
									</div>
								</Col>
								<Col lg={3} className="text-sm-end text-center">
									<Button
										variant="outline-dark"
										className="fw-bold w-100 mt-2 mt-lg-0"
										onClick={() => {
											handleGoToLeague(event);
										}}
									>
										<IoMdGlobe className="mb-1 me-1" />
										Global League
									</Button>
								</Col>
								<Col className="text-sm-end text-center" lg={3}>
									<Button
										variant="primary"
										className="fw-bold w-100 pe-0 ps-0 mt-2 mt-lg-0 mb-2 mb-lg-0"
										onClick={() => {
											handlePickTeam(event);
										}}
										style={{ textWrap: "nowrap" }}
									>
										<MdEdit className="mb-1 me-1" />
										Edit Team
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Card.Body>
			</Card>
			<Modal
				show={showModal}
				onHide={() => setShowModal(false)}
				// size=''
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<FiLogIn className="mb-1 me-2" />
						Sign In
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>You must be signed in to pick your team.</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="primary"
						onClick={() => {
							navigate("/login");
						}}
					>
						Sign In
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default EventCard;
