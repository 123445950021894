import apiInstance from './api';

export const getVideos = async () => {
	try {
		const res = await apiInstance.get('/videos');
		return res.data;
	} catch (error) {
		console.error('Error getting videos:', error);
		return null;
	}
};

export const getVideosByType = async (type) => {
	try {
		const res = await apiInstance.get(`/videos/${type}`);
		return res.data;
	} catch (error) {
		console.error('Error getting video:', error);
		return null;
	}
};

export const createVideo = async (video) => {
	try {
		const res = await apiInstance.post('/videos', video);
		return res.data;
	} catch (error) {
		console.error('Error creating video:', error);
		return null;
	}
};

export const updateVideo = async (id, updates) => {
	try {
		const res = await apiInstance.put(`/videos/${id}`, updates);
		return res.data;
	} catch (error) {
		console.error('Error updating video:', error);
		return null;
	}
};

export const deleteVideo = async (id) => {
	try {
		const res = await apiInstance.delete(`/videos/${id}`);
		return res.data;
	} catch (error) {
		console.error('Error deleting video:', error);
		return null;
	}
};
