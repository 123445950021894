import React, { useState, useEffect } from 'react';
import {
	Form,
	Button,
	Modal,
	DropdownButton,
	Dropdown,
	Card,
	Table,
} from 'react-bootstrap';
import { getEvents } from '../../services/eventService';
import {
	deleteSnapshot,
	getSnapshots,
	takeSnapshot,
} from '../../services/snapshotService';

const AdminSnapshotForm = () => {
	const [events, setEvents] = useState([]);
	const [selectedEvent, setSelectedEvent] = useState('');
	const [snapshotName, setSnapshotName] = useState('');
	const [snapshots, setSnapshots] = useState([]);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);

	useEffect(() => {
		fetchEvents();
		fetchSnapshots();
	}, []);

	const fetchEvents = async () => {
		try {
			const res = await getEvents();
			setEvents(res.data);
		} catch (error) {
			console.error('Error fetching events:', error);
		}
	};

	const fetchSnapshots = async () => {
		try {
			const res = await getSnapshots();
			console.log(res);
			setSnapshots(res.data);
		} catch (error) {
			console.error('Error fetching snapshots:', error);
		}
	};

	const handleEventSelect = (eventId) => {
		setSelectedEvent(eventId);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setShowConfirmationModal(true);
	};

	const event = events.find((event) => event._id === selectedEvent || {});

	const handleConfirm = async () => {
		if (selectedEvent == '') {
			alert('Please select an event');
			setShowConfirmationModal(false);
			return;
		}

		const data = {
			eventId: selectedEvent,
			name: snapshotName,
		};

		try {
			const res = await takeSnapshot(data);
			console.log(res);
		} catch (error) {
			alert('Error taking snapshot');
			console.error('Error taking snapshot:', error);
		}

		setShowConfirmationModal(false);
	};

	const handleDelete = async (snapshot) => {
		try {
			const res = await deleteSnapshot(snapshot.name);
			console.log(res);
			fetchSnapshots();
		} catch (error) {
			alert('Error deleting snapshot');
			console.error('Error deleting snapshot:', error);
		}
	};

	return (
		<div
			className='container bg-light py-4 px-4'
			style={{ minHeight: '100vh' }}
		>
			<h1 className='text-center'>Snapshot Manager</h1>
			<p className='text-center'>Make sure you know what you're doing. </p>
			<Card>
				<Card.Body>
					<Form onSubmit={handleSubmit}>
						<Form.Group controlId='eventSelect'>
							<Form.Label>Select Event</Form.Label>
							<DropdownButton
								id='dropdown-basic-button'
								title={
									selectedEvent
										? events.find((event) => event._id === selectedEvent).name
										: 'Select an Event'
								}
							>
								{events.map((event) => (
									<Dropdown.Item
										key={event._id}
										onClick={() => handleEventSelect(event._id)}
									>
										{event.name}
									</Dropdown.Item>
								))}
							</DropdownButton>
						</Form.Group>
						<Form.Group controlId='snapshotName' className='mt-3'>
							<Form.Label>Snapshot Name</Form.Label>
							<Form.Control
								type='text'
								placeholder='Enter snapshot name (Anything unique, to be able to track the snapshot)'
								value={snapshotName}
								onChange={(e) => setSnapshotName(e.target.value)}
							/>
						</Form.Group>
						<Button variant='primary' type='submit' className='mt-2'>
							Take Snapshot
						</Button>
					</Form>
				</Card.Body>
			</Card>
			<Card className='mt-3'>
				<Card.Body>
					<Card.Title>Snapshots</Card.Title>
					<Table striped bordered hover>
						<thead>
							<tr>
								<th>Name</th>
								<th>Sport</th>
								<th>Series</th>
								<th>Date Created</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{snapshots.map((snapshot) => (
								<tr key={snapshot?._id}>
									<td>{snapshot?.name}</td>
									<td>{snapshot?.sport?.name}</td>
									<td>{snapshot?.series?.name}</td>
									<td>{new Date(snapshot.dateCreated).toDateString()}</td>
									<td>
										<Button
											variant='outline-primary'
											onClick={() => handleDelete(snapshot)}
										>
											Delete
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
			<Modal
				show={showConfirmationModal}
				onHide={() => setShowConfirmationModal(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>Confirm Snapshot</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						Are you sure you want to take a snapshot for the event:{' '}
						<strong>
							{events.find((event) => event._id === selectedEvent)?.name}
						</strong>
						: <strong>{snapshotName}</strong>?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='secondary'
						onClick={() => setShowConfirmationModal(false)}
					>
						Cancel
					</Button>
					<Button variant='primary' onClick={handleConfirm}>
						Yes, Take Snapshot
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default AdminSnapshotForm;
