// FantasyPage.js
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ScheduleRow from "./Components/ScheduleRow";
import CountdownTimer from "./Components/CountdownTimer";
import Leagues from "./Components/Leagues";
import Teams from "./Components/Teams";
import { getEvent, getEvents } from "../../services/eventService";
import { getSports, getSeries } from "../../services/series";
import {
	createLeague,
	getLeagues,
	getUserLeagues,
} from "../../services/leagueService";
import { getTeams } from "../../services/teamService";
import {
	Container,
	Row,
	Col,
	Button,
	Modal,
	Dropdown,
	Form,
	Card,
	Tab,
	Tabs,
	Nav,
} from "react-bootstrap";
import OffCanvasSidebar from "./Components/Sidebar";
import { RxHamburgerMenu } from "react-icons/rx";
import CreateLeagueModal from "./Components/CreateLeagueModal";
import { all } from "axios";
import HomeTopRow from "../HomePage/components/HomeTopRow";
import { LuDot } from "react-icons/lu";
import { RiDashboard3Line } from "react-icons/ri";
import { MdEdit, MdPlaylistAdd } from "react-icons/md";
import { FaHome, FaMap, FaUsers } from "react-icons/fa";
import leftAd from "../../assets/Tower_Ad_Left.jpg";
import rightAd from "../../assets/Tower_Ad_Right.jpg";
import topAd from "../../assets/BannerAd_NonMobile_2.png";
import mobileAd from "../../assets/Banner_Ad_Mobile.jpg";
import "./Fantasy.css";
import EventCard from "./Components/EventCard";
import { CiFilter } from "react-icons/ci";
import Prizes from "./Components/Prizes";
import Rules from "./Components/Rules";
import LeftAd from "../Ads/LeftAd";
import RightAd from "../Ads/RightAd";
import TopAd from "../Ads/TopAd";
import MobileAd from "../Ads/MobileAd";
import { FaTrophy } from "react-icons/fa6";
import { Helmet } from "react-helmet";
import FeaturedLeaguesComponent from "./Components/FeaturedLeagues";
import TopRowSeries from "../HomePage/components/TopRowSeries";
import TopRowSport from "../HomePage/components/TopRowSport";
import { CSSTransition } from "react-transition-group";
import "../HomePage/HomePage.css";
import { FiLogIn } from "react-icons/fi";

const FantasyPage = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [events, setEvents] = useState([]);
	const [allEvents, setAllEvents] = useState([]);
	const [sport, setSport] = useState(null);
	const [sports, setSports] = useState([]);
	const [sportsDropdown, setSportsDropdown] = useState(null);
	const [series, setSeries] = useState(null);
	const [seriesDropdown, setSeriesDropdown] = useState(null);
	const [seriesList, setSeriesList] = useState([]);
	const [teams, setTeams] = useState([]);
	const [leagues, setLeagues] = useState([]);
	const [userLeagues, setUserLeagues] = useState([]);
	const [showSidebar, setShowSidebar] = useState(false);
	const [showCreateLeague, setShowCreateLeague] = useState(false);
	const [showCreateTeam, setShowCreateTeam] = useState(false);
	const [showMustSignIn, setShowMustSignIn] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [event, setEvent] = useState(null);
	const [defaultActiveKey, setDefaultActiveKey] = useState("");
	const [showModal, setShowModal] = useState(false);

	const filteredEvents = events.filter((event) => {
		const matchesSeries = series ? event.series._id === series._id : true;
		const matchesSport = sport ? event.sport._id === sport._id : true;
		return matchesSeries && matchesSport;
	});
	const futureEvents = filteredEvents.filter(
		(event) =>
			new Date(event.date) > new Date(Date.now() - 2 * 24 * 60 * 60 * 1000),
	);
	// @ts-ignore
	futureEvents.sort((a, b) => new Date(a.date) - new Date(b.date));
	const nearestFutureEvent = futureEvents[0];

	const eventSports = filteredEvents.reduce((acc, event) => {
		if (!acc.some((sport) => sport.name === event.sport.name)) {
			acc.push(event.sport);
		}
		return acc;
	}, []);

	useEffect(() => {
		fetchEvents();
		fetchLeagues();
		fetchUserLeagues();
	}, []);

	useEffect(() => {
		// fetchEvents();
		fetchTeams();
		fetchLeagues();
		fetchUserLeagues();
	}, [sport, series]);

	useEffect(() => {
		setEvent(nearestFutureEvent);

		//if event sports is greater than 1, then set sports to event sports.
		if (eventSports.length > 1) {
			setSports(eventSports);
		} else {
			setSports([]);
		}
	}, [series]);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		handleResize(); // Initial check
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const fetchEvents = async () => {
		try {
			const res = await getEvents();
			if (res) {
				// filter events to only show future events
				const _futureEvents = res.data.filter(
					(event) =>
						new Date(event.date) >
						new Date(Date.now() - 2 * 24 * 60 * 60 * 1000),
				);

				setEvents(_futureEvents);
				setEvent(nearestFutureEvent);

				const response = await getSeries();
				if (!response) {
					return;
				}

				//Want to filter series to events that have this series.
				const eventSeries = response.data.filter((series) => {
					return _futureEvents.some((event) => event.series._id === series._id);
				}, []);

				setSeriesList(eventSeries);

				const eventSports = _futureEvents.reduce((acc, event) => {
					if (!acc.some((sport) => sport.name === event.sport.name)) {
						acc.push(event.sport);
					}
					return acc;
				}, []);

				if (eventSports.length > 1) {
					setSports(eventSports);
				}
			}
		} catch (error) {
			console.error("Error fetching events:", error);
		}
	};

	const fetchTeams = async () => {
		try {
			// Fetch teams based on the selected sport/series
			const params = {};

			if (sport) params.sport = sport?._id;
			if (series) params.series = series?._id;

			const res = await getTeams(params);
			setTeams(res.data);
		} catch (error) {
			console.error("Error fetching teams:", error);
		}
	};

	const fetchLeagues = async () => {
		try {
			// Fetch leagues based on the selected sport/series
			const params = {};
			if (sport) params.sport = sport;
			if (series) params.series = series;

			const res = await getLeagues(params);
			//filter so that leagues with filtered = true appear first:

			const sorted = res.data.sort((a, b) =>
				a.filtered === b.filtered ? 0 : a.filtered ? -1 : 1,
			);
			setLeagues(sorted);
		} catch (error) {
			console.error("Error fetching leagues:", error);
		}
	};

	const fetchUserLeagues = async () => {
		try {
			// Fetch leagues based on the selected sport/series
			const params = {};
			if (sport) params.sport = sport;
			if (series) params.series = series;

			const res = await getUserLeagues(params);
			const sorted = res.data.sort((a, b) =>
				a.filtered === b.filtered ? 0 : a.filtered ? -1 : 1,
			);

			setUserLeagues(sorted);
		} catch (error) {
			console.error("Error fetching leagues:", error);
		}
	};

	const handleSelectedSeries = async (series) => {
		setSeries(series);
		setSport(null);
	};

	const handleSelectSport = (sport) => {
		setSport(sport);
	};

	useEffect(() => {
		// Update defaultActiveKey based on leagues data
		if (leagues.some((league) => league.image || league.roundImage)) {
			setDefaultActiveKey("prizes");
		} else {
			setDefaultActiveKey("countdown");
		}
	}, [leagues]);

	const handleCreateLeague = async (leagueData) => {
		try {
			const res = await createLeague(leagueData);
			if (res.status === 200 || res.status === 201) {
				fetchLeagues();
				fetchUserLeagues();
				setShowCreateLeague(false);
				const league = res.data;
				navigate(`/league/${league._id}`);
			} else {
				// Handle other status codes
				console.log(
					"Error creating league: Unexpected status code",
					res.status,
				);
			}
		} catch (error) {
			console.error("Error creating league:", error);
			if (
				error.response &&
				(error.response.status === 400 || error.response.status === 401)
			) {
				setShowCreateLeague(false);
				setShowMustSignIn(true);
			}
		}
	};

	const handleEditTeam = () => {
		//check if user is logged in
		try {
			const userId = localStorage.getItem("userId");
			if (!userId) {
				setShowModal(true);
				return;
			}

			//navigate to pick team page
			navigate(`/editteam?sport=${event.sport._id}&series=${event.series._id}`);
		} catch (error) {
			console.error("Error handling pick team:", error);
		}
	};

	const handleCreateTeam = async () => {
		try {
			const res = await getTeams({ sport, series });
			navigate(`/editteam?sport=${sportsDropdown}&series=${seriesDropdown}`);
		} catch (error) {
			console.error("Error fetching teams:", error);
			if (
				error.response &&
				(error.response.status === 400 || error.response.status === 401)
			) {
				setShowCreateTeam(false);
				setShowMustSignIn(true);
			}
		}
	};

	return (
		<Container fluid className="w-100 mx-0 px-0" style={{ overflow: "hidden" }}>
			<Helmet>
				<title>Fantasy Homepage</title>
				<meta
					name="description"
					content="The Race Companion Fantasy Homepage. Fantasy leagues for Enduro, Downhill and Slopestyle mountain biking.  Follow along with Crankworx, UCI Downhill & Enduro, Red Bull Hardline and Red Bull Rampage."
				/>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Helmet>
			<Row className="m-0 p-0">{isMobile ? <MobileAd /> : <TopAd />}</Row>
			{Array.isArray(seriesList) && seriesList?.length > 0 && (
				<TopRowSeries
					series={seriesList}
					selectedSeries={series}
					handleSelectSeries={handleSelectedSeries}
				/>
			)}
			<HomeTopRow
				events={filteredEvents}
				setSelectedEvent={setEvent}
				selectedEvent={event}
			/>
			<Row noGutters>
				{/* Left tower ad */}
				<Col lg={2} className="d-none d-lg-block text-start">
					<LeftAd />
				</Col>
				<Col xs={12} lg={8} className="bg-light" style={{ zIndex: 1000 }}>
					<CSSTransition
						in={sports && sports.length > 0}
						timeout={500}
						classNames="transition"
						unmountOnExit
					>
						<div>
							<TopRowSport
								sports={sports}
								selectedSport={sport}
								handleSelectSport={handleSelectSport}
							/>
						</div>
					</CSSTransition>
					<Row className="text-start mt-3 mx-sm-0 mx-2">
						<Col md className="text-center">
							<Button
								variant="success"
								className="me-3 w-100 fw-bold mb-sm-0 mb-3"
								onClick={() => handleEditTeam()}
							>
								<MdEdit className="me-2 mb-1" />
								Edit Team
							</Button>
						</Col>
						<Col md className="text-center">
							<Button
								variant="success"
								className="me-3 w-100 fw-bold mb-sm-0 mb-3"
								onClick={() => setShowCreateTeam(true)}
							>
								<MdPlaylistAdd className="me-2 mb-1" />
								Create a Team
							</Button>
						</Col>
						<Col md className="text-center">
							<Button
								variant="success"
								className=" w-100 fw-bold"
								onClick={() => setShowCreateLeague(true)}
							>
								<FaUsers className="me-2 mb-1" />
								Create a League
							</Button>
						</Col>
					</Row>
					{event && event?._id && <EventCard event={event} />}
					<Container className="pt-2 bg-light" style={{ minHeight: "100vh" }}>
						{/* <ScheduleRow events={events} /> */}

						<div className="d-flex flex-row justify-content-center">
							{sport && series ? (
								<Card
									className="border-0 border-primary bg-light"
									style={{ maxWidth: "fit-content" }}
								>
									<Card.Body
										className="px-sm-5 py-2"
										style={{ maxWidth: "fit-content" }}
									>
										<Card.Title className="fs-2 fw-bold mb-0">
											{series.name} <LuDot /> {sport.name}
										</Card.Title>
										{/* <Card.Text className='fs-4 text-muted'>Dashboard</Card.Text> */}
									</Card.Body>
								</Card>
							) : (
								<></>
								// <Card
								// 	className='border-0 border-primary bg-light'
								// 	style={{ maxWidth: 'fit-content' }}
								// >
								// 	<Card.Body
								// 		className='px-5 py-2'
								// 		style={{ maxWidth: 'fit-content' }}
								// 	>
								// 		<Card.Title className='fs-2 fw-bold mb-0'>
								// 			Fantasy <RiDashboard3Line className='mx-2' /> Dashboard
								// 		</Card.Title>
								// 		{/* <Card.Text className='fs-4 text-muted'>Dashboard</Card.Text> */}
								// 	</Card.Body>
								// </Card>
							)}
						</div>

						{leagues && defaultActiveKey != "" && (
							<Tab.Container
								activeKey={defaultActiveKey}
								onSelect={(e) => setDefaultActiveKey(e)}
							>
								<div className="mb-3">
									<Card className="border-0 bg-light">
										<Card.Header className="rounded bg-success border shadow-sm">
											<Nav variant="pills" className="nav-justified">
												<Nav.Item>
													<Nav.Link eventKey="countdown">
														<FaHome className="mb-1 me-2" /> Home
													</Nav.Link>
												</Nav.Item>
												<Nav.Item>
													<Nav.Link eventKey="prizes">
														{" "}
														<FaTrophy className="mb-1 me-2" /> Prizes
													</Nav.Link>
												</Nav.Item>
												<Nav.Item>
													<Nav.Link eventKey="rules">
														<FaMap className="mb-1 me-2" /> Rules
													</Nav.Link>
												</Nav.Item>
											</Nav>
										</Card.Header>
										<Card.Body>
											<Tab.Content>
												<Tab.Pane eventKey="countdown">
													<CountdownTimer event={nearestFutureEvent} />
												</Tab.Pane>
												<Tab.Pane eventKey="rules">
													<Rules />
												</Tab.Pane>
											</Tab.Content>
										</Card.Body>
									</Card>
								</div>
							</Tab.Container>
						)}

						{defaultActiveKey == "prizes" &&
							leagues &&
							Array.isArray(leagues) &&
							leagues.length > 0 && <Prizes leagues={leagues} />}

						{/* <CountdownTimer event={nearestFutureEvent} /> */}
						<Teams
							teams={teams}
							setCreateLeague={setShowCreateLeague}
							setCreateTeam={setShowCreateTeam}
						/>
						<FeaturedLeaguesComponent
							featuredLeagues={leagues.filter((league) => league.featured)}
						/>
						<Leagues
							allLeagues={leagues}
							userLeagues={userLeagues}
							sports={sports}
							series={seriesList}
						/>
						<CreateLeagueModal
							show={showCreateLeague}
							handleClose={() => setShowCreateLeague(false)}
							events={events}
							sports={sports}
							seriesList={seriesList}
							handleCreateLeague={handleCreateLeague}
						/>
						<Modal
							show={showMustSignIn}
							onHide={() => setShowMustSignIn(false)}
						>
							<Modal.Header closeButton>
								<Modal.Title>Sign In Required</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<p>You must be signed in to create a league.</p>
							</Modal.Body>
							<Modal.Footer>
								<Button
									variant="primary"
									onClick={() => {
										localStorage.setItem("location", location.pathname);
										navigate("/login");
									}}
								>
									Sign In
								</Button>
							</Modal.Footer>
						</Modal>
						<Modal
							show={showCreateTeam}
							onHide={() => setShowCreateTeam(false)}
						>
							<Modal.Header closeButton className="bg-success text-white">
								<Modal.Title>
									{" "}
									<MdPlaylistAdd className="me-2 mb-1" /> Create a Team
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<p>Choose a series and sport</p>
								<Form.Group>
									<Form.Label>Series</Form.Label>
									<Form.Control
										as="select"
										value={seriesDropdown?.name}
										onChange={(e) => setSeriesDropdown(e.target.value)}
									>
										<option value="">Select a series</option>
										{seriesList.map((series) => (
											<option key={series._id} value={series._id}>
												{series.name}
											</option>
										))}
									</Form.Control>
								</Form.Group>
								<Form.Group>
									<Form.Label>Sports</Form.Label>
									<Form.Control
										as="select"
										value={sportsDropdown?.name}
										onChange={(e) => setSportsDropdown(e.target.value)}
									>
										<option value="">Select a sport</option>
										{sports.map((sport) => (
											<option key={sport._id} value={sport._id}>
												{sport.name}
											</option>
										))}
									</Form.Control>
								</Form.Group>
							</Modal.Body>
							<Modal.Footer>
								<Button
									variant="outline-secondary"
									onClick={() => setShowCreateTeam(false)}
								>
									Close
								</Button>
								<Button
									variant="primary"
									onClick={() => {
										handleCreateTeam();
									}}
								>
									Create
								</Button>
							</Modal.Footer>
						</Modal>
					</Container>
				</Col>
				<Col lg={2} className="d-none d-lg-block text-end">
					<RightAd />
				</Col>
			</Row>
			<Modal
				show={showModal}
				onHide={() => setShowModal(false)}
				// size=''
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<FiLogIn className="mb-1 me-2" />
						Sign In
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>You must be signed in to pick your team.</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="primary"
						onClick={() => {
							navigate("/login");
						}}
					>
						Sign In
					</Button>
				</Modal.Footer>
			</Modal>
		</Container>
	);
};

export default FantasyPage;
