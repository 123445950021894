import React, { useEffect, useMemo, useState } from "react";
import {
	Card,
	Table,
	Button,
	Row,
	Col,
	Dropdown,
	DropdownButton,
	Form,
	Modal,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { TbMathFunction } from "react-icons/tb";
import { createTeam, updateTeam } from "../../../services/teamService";
import getCountryISO2 from "country-iso-3-to-2";
import ReactCountryFlag from "react-country-flag";
import { FaRegHandPointRight, FaGamepad } from "react-icons/fa6";
import { LiaTimesSolid } from "react-icons/lia";
import { calculateMultiplier } from "../../../utils/utils";
import Prizes from "../../Fantasy/Components/Prizes";
import positionsPoints from "../../../assets/Crankworx_downhill_points.json";
import { LuUserX } from "react-icons/lu";
import { LuDot } from "react-icons/lu";
import { IoWarning } from "react-icons/io5";

const MultiplierMode = ({
	numberOfMen,
	numberOfWomen,
	eligibleRiders,
	team,
	setTeam,
	globalLeague,
	disable,
}) => {
	const [riders, setRiders] = useState([]);
	const [sortKey, setSortKey] = useState("last_name"); // Default sort by last name
	const [sortOrder, setSortOrder] = useState("asc"); // Sort order: 'asc' or 'desc'
	const [filterGender, setFilterGender] = useState(""); // Filter by gender
	const [filterCountry, setFilterCountry] = useState(""); // Filter by country
	const [viewScoring, setViewScoring] = useState(false);
	const [showDisabledModal, setShowDisabledModal] = useState(false);
	const [showIneligibleRidersModal, setShowIneligibleRidersModal] =
		useState(false);

	// Get query params for sport and series from URL
	const location = useLocation();
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(location.search);
	const sport = searchParams.get("sport");
	const series = searchParams.get("series");
	const leagueId = searchParams.get("leagueId");

	const uniqueCountries = useMemo(() => {
		const countrySet = new Set(
			eligibleRiders.map((rider) => rider.country.toUpperCase().trim()),
		);
		return Array.from(countrySet).sort();
	}, [eligibleRiders]);

	useEffect(() => {
		if (team && Array.isArray(team.riders) && team.riders.length > 0) {
			setRiders(team.riders);
		}
	}, [team]);

	const addRiderToTeam = (rider) => {
		if (
			!riders.some((teamRider) => teamRider.rider._id === rider._id) &&
			riders.length < numberOfMen + numberOfWomen
		) {
			setRiders([...riders, { rider: rider, rank: 0 }]);
		}
	};

	const removeRiderFromTeam = (riderId) => {
		setRiders(riders.filter((rider) => rider.rider._id !== riderId));
	};

	const validateTeam = () => {
		const men = riders.filter((rider) => rider.rider.gender === "m").length;
		const women = riders.filter((rider) => rider.rider.gender === "f").length;
		return men === numberOfMen && women === numberOfWomen;
	};

	const saveTeam = async () => {
		if (!validateTeam()) {
			alert(
				"Please complete your team according to the game mode's requirements.",
			);
		}

		if (
			riders.some(
				(rider) => rider.rider?.status?.toLowerCase() === "ineligible",
			)
		) {
			setShowIneligibleRidersModal(true);
			return;
		}

		try {
			if (team) {
				const teamData = {
					...team,
					riders: riders,
				};

				const res = await updateTeam(team._id, teamData);
				if (res) {
					if (leagueId) {
						navigate(`/league/${leagueId}`);
					} else {
						navigate("/fantasy");
					}
				}
			} else {
				const teamData = {
					league: leagueId ? leagueId : null,
					riders: riders,
					name: team?.name ? team.name : "",
					sport: team?.sport ? team.sport : sport,
					series: team?.series ? team.series : series,
				};

				const res = await createTeam(teamData);
				if (res) {
					if (leagueId) {
						navigate(`/league/${leagueId}`);
					} else {
						navigate("/fantasy");
					}
				}
			}
		} catch (error) {
			alert("Error saving team:" + error);
		}
	};

	const isAddDisabled = (gender, rider) => {
		const isAlreadyInTeam = riders.some(
			(teamRider) => teamRider.rider._id === rider._id,
		);
		if (isAlreadyInTeam) return true;

		return gender === "m"
			? riders.filter((r) => r.rider.gender === "m").length >= numberOfMen
			: riders.filter((r) => r.rider.gender === "f").length >= numberOfWomen;
	};

	const isSaveDisabled = () => {
		return riders.length !== numberOfMen + numberOfWomen;
	};

	// Sort and filter riders before rendering
	const sortedAndFilteredRiders = eligibleRiders
		.filter(
			(rider) =>
				(filterGender ? rider.gender === filterGender : true) &&
				(filterCountry
					? rider?.country?.toLowerCase() === filterCountry?.toLowerCase()
					: true),
		)
		.sort((a, b) => {
			let compareA =
				sortKey === "rating"
					? a.ratings.find((r) => r.sport.toString() === sport)?.rating || 0
					: a[sortKey];
			let compareB =
				sortKey === "rating"
					? b.ratings.find((r) => r.sport.toString() === sport)?.rating || 0
					: b[sortKey];

			if (sortOrder === "asc") {
				return compareA > compareB ? 1 : -1;
			} else {
				return compareA < compareB ? 1 : -1;
			}
		});

	return (
		<div>
			<Prizes leagues={[globalLeague]} />
			<Card className="mb-3 shadow">
				<Card.Header>
					{" "}
					<FaGamepad className="me-2 mb-1" />
					Multiplier Mode
				</Card.Header>
				<Card.Body style={{ fontSize: "0.8rem" }}>
					<Card.Text>
						<FaRegHandPointRight className="me-2" />
						Build your dream team by selecting {numberOfMen} Men, and{" "}
						{numberOfWomen} Women.
					</Card.Text>
					<Card.Text>
						<FaRegHandPointRight className="me-2" />
						Your points are awarded by points scored by the athlete at the event
						multiplied by the athletes assigned multiplier value.
					</Card.Text>
					<Card.Text className="fst-italic">
						<FaRegHandPointRight className="me-2 " />
						Rider's Points
						<LiaTimesSolid className="mx-1 " /> Multiplier (
						<TbMathFunction className="" />) = Your Score!
					</Card.Text>
					<Card.Text className="fst-italic">
						<FaRegHandPointRight className="me-2 " />
						Dan Booker scores 50 points
						<LiaTimesSolid className="mx-1 " /> 5 (Multiplier) (
						<TbMathFunction className="" />) = 250 Points
					</Card.Text>
					<div className="d-flex flex-row justify-content-md-end justify-content-center">
						<Button
							variant="dark"
							size="sm"
							onClick={() => setViewScoring(true)}
						>
							Crankworx Points
						</Button>
					</div>
				</Card.Body>
			</Card>
			<Card className="mb-3 shadow">
				<Card.Header className="bg-success text-white">My Team</Card.Header>
				<Card.Body>
					<Card className="mb-1 py-0 shadow">
						<Card.Body className="py-1 my-0">
							<Row className="align-items-center py-0 my-0 text-sm-start text-center fw-bold">
								<Col md={4} className="text-start my-2 my-sm-0">
									Rider
								</Col>
								<Col xs={6} md={6} className="text-center">
									Multiplier
								</Col>
								<Col
									xs={12}
									md={2}
									className="d-flex justify-content-center mt-2 mt-md-0"
								></Col>
							</Row>
						</Card.Body>
					</Card>
					{riders.map((rider) => (
						<Card
							key={rider.rider._id + "current"}
							className="mb-1 py-0 shadow"
						>
							<Card.Body className="py-1 my-0">
								<Row className="align-items-center py-0 my-0 text-sm-start text-center">
									<Col md={4} className="text-start my-2 my-sm-0">
										{rider?.rider?.status?.toLowerCase() === "ineligible" && (
											<>
												<LuUserX className="mb-1 me-1 text-danger" />
												<span className="text-danger fst-italic">
													Ineligible
												</span>
											</>
										)}{" "}
										{getCountryISO2(
											rider.rider.country.toUpperCase().trim(),
										) ? (
											<ReactCountryFlag
												countryCode={getCountryISO2(
													rider.rider.country.toUpperCase().trim(),
												)}
												svg
												style={{ width: "3.5rem" }}
											/>
										) : (
											rider.rider.country
										)}
										<b>{rider.rider.last_name}</b> {rider.rider.first_name}{" "}
										<span style={{ fontSize: "0.6rem" }}>
											({rider.rider.gender})
										</span>
									</Col>
									<Col xs={6} md={6} className="text-center">
										{rider.rider.ratings.map((rating) => {
											if (rating.sport.toString() === sport) {
												return (
													<span key={rating._id}>
														<TbMathFunction className="mb-1 me-2" />
														{Math.floor(calculateMultiplier(rating.rating))}
													</span>
												);
											}
											return null;
										})}
									</Col>
									<Col
										xs={12}
										md={2}
										className="d-flex justify-content-center mt-2 mt-md-0"
									>
										<Button
											variant="outline-dark"
											size="sm"
											className="w-100"
											onClick={() => removeRiderFromTeam(rider.rider._id)}
										>
											Remove
										</Button>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					))}
				</Card.Body>
				<Card.Footer>
					<Row>
						<Col className="w-100 text-end">
							<Button
								onClick={() => {
									if (disable) {
										setShowDisabledModal(true);
										return;
									}

									saveTeam();
								}}
								disabled={isSaveDisabled()}
								className="px-4 "
							>
								Save Team
							</Button>
						</Col>
					</Row>
				</Card.Footer>
			</Card>

			<Card>
				<Card.Header>Eligible Riders</Card.Header>
				<Card.Body>
					<Row className="mb-3">
						<Col sm={1}>
							<DropdownButton title="Sort" size="sm" className=" mx-2">
								<Dropdown.Item
									onClick={() => {
										setSortKey("last_name");
										setSortOrder("asc");
									}}
								>
									Last Name Asc
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setSortKey("last_name");
										setSortOrder("desc");
									}}
								>
									Last Name Desc
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setSortKey("rating");
										setSortOrder("asc");
									}}
								>
									Rating Asc
								</Dropdown.Item>
								<Dropdown.Item
									onClick={() => {
										setSortKey("rating");
										setSortOrder("desc");
									}}
								>
									Rating Desc
								</Dropdown.Item>
							</DropdownButton>
						</Col>
						<Col sm={2}>
							<Form.Select
								size="sm"
								className="d-inline w-auto"
								onChange={(e) => setFilterGender(e.target.value)}
								value={filterGender}
							>
								<option value="">Filter by Gender</option>
								<option value="m">Male</option>
								<option value="f">Female</option>
							</Form.Select>
						</Col>
						<Col sm={2}>
							<Form.Select
								size="sm"
								value={filterCountry}
								onChange={(e) => setFilterCountry(e.target.value)}
								className="w-auto"
							>
								<option value="">Filter by Country</option>
								{uniqueCountries.map((country) => (
									<option key={country} value={country}>
										{country}
									</option>
								))}
							</Form.Select>
						</Col>
					</Row>
					<Card className="mb-1 py-0 shadow">
						<Card.Body className="py-1 my-0">
							<Row className="align-items-center py-0 my-0 text-sm-start text-center fw-bold">
								<Col md={4} className="text-start my-2 my-sm-0">
									Rider
								</Col>
								<Col xs={6} md={6} className="text-center">
									Multiplier
								</Col>
								<Col
									xs={12}
									md={2}
									className="d-flex justify-content-center mt-2 mt-md-0"
								></Col>
							</Row>
						</Card.Body>
					</Card>
					{sortedAndFilteredRiders.map(
						(rider) =>
							rider?.status?.toLowerCase() !== "ineligible" && (
								<Card key={rider._id} className="mb-1 py-0 shadow">
									<Card.Body className="py-1 my-0">
										<Row className="align-items-center py-0 my-0 text-sm-start text-center">
											<Col md={4} className="text-start my-2 my-sm-0">
												{getCountryISO2(rider.country.toUpperCase().trim()) ? (
													<ReactCountryFlag
														countryCode={getCountryISO2(
															rider.country.toUpperCase().trim(),
														)}
														svg
														style={{ width: "3.5rem" }}
													/>
												) : (
													rider.country
												)}
												<b>{rider.last_name}</b> {rider.first_name}{" "}
												<span style={{ fontSize: "0.6rem" }}>
													({rider.gender})
												</span>
											</Col>
											<Col xs={6} md={6} className="text-center">
												{rider.ratings.map((rating) => {
													if (rating.sport.toString() === sport) {
														return (
															<span key={rating._id}>
																<TbMathFunction className="mb-1 me-2" />
																{Math.floor(calculateMultiplier(rating.rating))}
															</span>
														);
													}
													return null;
												})}
											</Col>
											<Col
												xs={12}
												md={2}
												className="d-flex justify-content-center mt-2 mt-md-0"
											>
												<Button
													variant={
														isAddDisabled(rider.gender, rider)
															? "secondary"
															: "dark"
													}
													size="sm"
													className="w-100"
													onClick={() => addRiderToTeam(rider)}
													disabled={isAddDisabled(rider.gender, rider)}
												>
													Add to Team
												</Button>
											</Col>
										</Row>
									</Card.Body>
								</Card>
							),
					)}
				</Card.Body>
			</Card>
			<Modal
				show={viewScoring}
				onHide={() => setViewScoring(false)}
				size="lg"
				centered
			>
				<Modal.Header
					closeButton
					data-bs-theme="dark"
					className="bg-dark text-light"
				>
					<Modal.Title>
						<b>Crankworx Downhill</b> <LuDot className="mb-1 me-2" />
						Rider Points
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="px-5">
					<Table striped hover size="sm">
						<thead>
							<tr>
								<th>Position</th>
								<th>Points</th>
							</tr>
						</thead>
						<tbody>
							{Object.entries(positionsPoints).map(
								([position, points], index) => (
									<tr key={index}>
										<td>{position}</td>
										<td>{points}</td>
									</tr>
								),
							)}
						</tbody>
					</Table>
				</Modal.Body>
			</Modal>
			<Modal
				show={showDisabledModal}
				onHide={() => setShowDisabledModal(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<IoWarning className="me-2 mb-1" />
						Event Underway
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>An event is currently underway.</p>
					<p>
						Changes to your team will no longer be reflected in the current
						event.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="primary"
						onClick={() => {
							saveTeam();
						}}
					>
						Save Team
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showIneligibleRidersModal}
				onHide={() => setShowIneligibleRidersModal(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<IoWarning className="me-2 mb-1" />
						Ineligible Riders
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						You have selected riders that are ineligible for the current game
						mode.
					</p>
					<p>
						Please remove the riders marked as ineligible before saving your
						team.
					</p>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default MultiplierMode;
