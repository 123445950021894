// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import ReactCountryFlag from 'react-country-flag';
import {
	BiDotsHorizontal,
	BiDotsHorizontalRounded,
	BiDotsVerticalRounded,
} from 'react-icons/bi';
import { FaCalendar } from 'react-icons/fa';

const CountdownTimer = ({ event }) => {
	const calculateTimeLeft = () => {
		if (!event) {
			return {};
		}

		const difference = new Date(event.date) - new Date();
		let timeLeft = {};

		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			};
		}

		return timeLeft;
	};

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	useEffect(() => {
		const timer = setTimeout(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);

		return () => clearTimeout(timer);
	});

	const timerComponents = [];

	Object.keys(timeLeft).forEach((interval) => {
		if (!timeLeft[interval]) {
			return;
		}

		timerComponents.push(
			<span key={interval}>
				{timeLeft[interval]} {interval}{' '}
			</span>,
		);
	});

	return (
		<div className='w-100 d-flex flex-row justify-content-center mt-3 mb-5'>
			<Card
				style={{ width: '30rem' }}
				className='p-3 bg-dark border-0 border-2 shadow text-white'
			>
				<Card.Body className='text-center p-0'>
					<div className='d-flex flex-row justify-content-center'>
						<Card className='bg-dark border-0 text-white fw-bold fs-5'>
							<Card.Body>
								<Card.Text>
									{event?.series.name} <BiDotsVerticalRounded />{' '}
									{event?.location} <BiDotsVerticalRounded />{' '}
									{event?.sport.name}
								</Card.Text>
							</Card.Body>
						</Card>
					</div>

					<div className='d-flex flex-row justify-content-center'>
						<Card style={{}} className='mx-1'>
							<Card.Body
								className='fs-2 fw-bold py-1 ps-md-3 ps-2 text-start text-md-center'
								style={{ width: '4.5rem', height: '3rem' }}
							>
								{timeLeft.days}
							</Card.Body>
							<Card.Footer
								className='text-muted p-0'
								style={{ fontSize: '0.6rem' }}
							>
								Days
							</Card.Footer>
						</Card>
						<Card style={{}} className='mx-1'>
							<Card.Body
								className='fs-2 fw-bold py-1 ps-md-3 ps-2 text-start text-md-center'
								style={{ width: '4.5rem', height: '3rem' }}
							>
								{timeLeft.hours}
							</Card.Body>
							<Card.Footer
								className='text-muted p-0'
								style={{ fontSize: '0.6rem' }}
							>
								Hours
							</Card.Footer>
						</Card>
						<Card style={{}} className='mx-1'>
							<Card.Body
								className='fs-2 fw-bold py-1 ps-md-3 ps-2 text-start text-md-center'
								style={{ width: '4.5rem', height: '3rem' }}
							>
								{timeLeft.minutes}
							</Card.Body>
							<Card.Footer
								className='text-muted p-0'
								style={{ fontSize: '0.6rem' }}
							>
								Minutes
							</Card.Footer>
						</Card>
						<Card style={{}} className='mx-1'>
							<Card.Body
								className='fs-2 fw-bold py-1 ps-md-3 ps-2 text-start text-md-center'
								style={{ width: '4.5rem', height: '3rem' }}
							>
								{timeLeft.seconds}
							</Card.Body>
							<Card.Footer
								className='text-muted p-0'
								style={{ fontSize: '0.6rem' }}
							>
								Seconds
							</Card.Footer>
						</Card>
					</div>
					<div className='d-flex flex-row justify-content-between align-items-center px-sm-5'>
						<Card className='bg-dark border-0 text-white'>
							<Card.Body>
								<Card.Text style={{ fontSize: '0.8rem' }}>
									<FaCalendar className='me-2' />
									{event?.date.split('T')[0]}
								</Card.Text>
							</Card.Body>
						</Card>

						<Card className='bg-dark border-0 text-white pt-0'>
							<Card.Body className='pt-3'>
								<Card.Text>
									<ReactCountryFlag
										countryCode={event?.country}
										svg
										style={{ width: '1.5rem', height: '1.5rem' }}
										className='me-2'
									/>
									{event?.country}
								</Card.Text>
							</Card.Body>
						</Card>
					</div>
				</Card.Body>
			</Card>
		</div>
	);
};

export default CountdownTimer;
