import axios from 'axios';
import apiInstance from './api';

const API_URL = process.env.REACT_APP_API_URL;

export const getTeams = async (params) => {
	console.log('getTeams', params);
	return apiInstance.get(`/teams`, { params });
};

export const getTeam = async (teamId) => {
	return apiInstance.get(`/teams/${teamId}`);
};

// export const getTeamBySportAndSeries = async (params) => {
// 	return apiInstance.get(`/teams/sport-series`, params);
// };

export const createTeam = async (teamData) => {
	return apiInstance.post(`/teams`, teamData);
};

export const updateTeam = async (teamId, teamData) => {
	return apiInstance.put(`/teams/${teamId}`, teamData);
};

export const deleteTeam = async (teamId) => {
	return apiInstance.delete(`/teams/${teamId}`);
};
