// @ts-nocheck
import React, { useState, useEffect } from 'react';
import {
	Container,
	Row,
	Col,
	Dropdown,
	ToggleButtonGroup,
	ToggleButton,
	Card,
	Table,
	Button,
} from 'react-bootstrap';
import { getEvents } from '../../services/eventService';
import { getSeries, getSports } from '../../services/series';
import ReactCountryFlag from 'react-country-flag';
import { getResults } from '../../services/resultService';
import ResultsModal from './components/ResultModal';
import { FaCalendar } from 'react-icons/fa';
import RightAd from '../Ads/RightAd';
import LeftAd from '../Ads/LeftAd';
import MobileAd from '../Ads/MobileAd';
import TopAd from '../Ads/TopAd';
import HomeTopRow from '../HomePage/components/HomeTopRow';
import { Helmet } from 'react-helmet';

const SchedulePage = () => {
	const [events, setEvents] = useState([]);
	const [sports, setSports] = useState([]);
	const [series, setSeries] = useState([]);
	const [filteredEvents, setFilteredEvents] = useState([]);
	const [filter, setFilter] = useState({
		year: new Date().getFullYear().toString(),
		sport: '',
		series: '',
	});
	const [displayMode, setDisplayMode] = useState('yearly');
	const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
	const [selectedYear, setSelectedYear] = useState(null);
	const [selectedSport, setSelectedSport] = useState(null);
	const [selectedSeries, setSelectedSeries] = useState(null);
	const [showEventResults, setShowEventResults] = useState(false);
	const [eventResults, setEventResults] = useState([]);
	const [isMobile, setIsMobile] = useState(false);

	const [years, setYears] = useState([]);

	const isEventPast = (date) => {
		const today = new Date();
		const eventDate = new Date(date);
		return eventDate < today;
	};

	useEffect(() => {
		fetchEvents();
		fetchSports();
		fetchSeries();
	}, []);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		handleResize(); // Initial check
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const fetchEvents = async () => {
		try {
			const res = await getEvents();
			setEvents(res.data);

			// Get unique years from events
			const years = [
				...new Set(
					res.data.map((event) =>
						new Date(event.date).getFullYear().toString(),
					),
				),
			];
			setYears(years);
		} catch (error) {
			console.error('Error fetching events:', error);
		}
	};

	const fetchSports = async () => {
		try {
			const res = await getSports();
			setSports(res.data);
		} catch (error) {
			console.error('Error fetching sports:', error);
		}
	};

	const fetchSeries = async () => {
		try {
			const res = await getSeries();
			setSeries(res.data);
		} catch (error) {
			console.error('Error fetching series:', error);
		}
	};

	const fetchResults = async (eventId) => {
		// Fetch results for the event
		try {
			const res = await getResults(eventId);
			if (res) {
				setEventResults(res);
			}
		} catch (error) {
			alert('Error fetching results:', error);
		}
	};

	useEffect(() => {
		// Filter events whenever filter criteria or events list changes
		const filtered = events.filter((event) => {
			const eventDate = new Date(event.date);
			const eventYear = eventDate.getFullYear().toString();
			const eventMonth = eventDate.getMonth();
			const matchesYear = filter.year ? eventYear === filter.year : true;
			const matchesSport = filter.sport
				? event.sport._id === filter.sport._id
				: true;
			const matchesSeries = filter.series
				? event.series._id === filter.series._id
				: true;
			const matchesMonth =
				displayMode === 'monthly' ? eventMonth === selectedMonth : true;

			return matchesYear && matchesSport && matchesSeries && matchesMonth;
		});

		setFilteredEvents(filtered);
	}, [events, filter, selectedMonth, displayMode]);

	// Functions to handle filter changes...
	const handleYearChange = (year) => {
		setFilter({ ...filter, year });
		setSelectedYear(year);
	};

	const handleSportChange = (sport) => {
		setFilter({ ...filter, sport });
		setSelectedSport(sport);
	};

	const handleSeriesChange = (series) => {
		setFilter({ ...filter, series });
		setSelectedSeries(series);
	};

	const handleMonthChange = (monthIndex) => {
		setSelectedMonth(monthIndex);
		const monthEvents = events.filter(
			(event) => new Date(event.date).getMonth() === monthIndex,
		);
	};

	const handleSelectViewResults = (eventId) => {
		setShowEventResults(true);
		fetchResults(eventId);
	};

	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	return (
		<Container fluid className='w-100 mx-0 px-0'>
			<Helmet>
				<title>Schedule - The Race Companion</title>
				<meta
					name='description'
					content='2024 Schedule for Enduro, Slopestyle, Downhill and Freeride mountain biking. Follow along with Crankworx, World cups, Red Bull Hardline and Red Bull Rampage.  Find out when and where the next event is and who is competing.'
				/>
				<meta name='viewport' content='width=device-width, initial-scale=1' />
			</Helmet>
			<Row className='m-0 p-0'>{isMobile ? <MobileAd /> : <TopAd />}</Row>
			<HomeTopRow events={events} />
			<Row noGutters>
				<Col lg={2} className='d-none d-lg-block text-start'>
					<LeftAd />
				</Col>
				<Col xs={12} lg={8} className='bg-light my-4' style={{ zIndex: 1000 }}>
					<h1 className='ms-md-0 ms-2'>
						<FaCalendar className='mb-2 me-1' /> {filter.year} Schedule
					</h1>

					<Row className='mb-3 mt-4 d-flex ms-md-0 ms-0 w-100'>
						<Col className='text-center'>
							<ToggleButtonGroup
								type='radio'
								name='displayMode'
								value={displayMode}
								onChange={setDisplayMode}
								className='flex-grow-1 d-flex justify-content-center w-100'
							>
								<ToggleButton
									variant='success'
									value='yearly'
									id={'yearly'}
									style={{ fontSize: '0.8rem' }}
								>
									Full Year View
								</ToggleButton>
								<ToggleButton
									variant='success'
									value='monthly'
									id={'monthly'}
									style={{ fontSize: '0.8rem' }}
								>
									Monthly View
								</ToggleButton>
							</ToggleButtonGroup>
						</Col>
					</Row>
					{displayMode === 'monthly' && (
						<div
							className='mb-3 d-flex flex-row justify-content-start'
							style={{ overflow: 'auto' }}
						>
							{months.map((month, index) => (
								<Card
									className={`${
										selectedMonth === index
											? 'border-primary text-primary '
											: ''
									} me-2 mt-2 mb-3 text-center shadow`}
									onClick={() => handleMonthChange(index)}
									style={{ minWidth: '8rem' }}
									key={index}
								>
									<Card.Body className=''>
										<Card.Title>{month}</Card.Title>
										<Card.Text style={{ fontSize: '0.7rem' }}>
											{
												events.filter(
													(event) => new Date(event.date).getMonth() === index,
												).length
											}{' '}
											events
										</Card.Text>
									</Card.Body>
								</Card>
							))}
						</div>
					)}
					<Card className='pe-sm-0 pe-3'>
						<Card.Body className='' style={{ overflow: 'auto' }}>
							<Row className='mb-3'>
								<Col md={4} className='mb-md-0 mb-2'>
									<Dropdown>
										<Dropdown.Toggle
											className='w-100'
											variant='outline-secondary'
											id='year-dropdown'
											style={{ fontSize: '0.7rem' }}
										>
											{selectedYear ? selectedYear : 'Year'}
										</Dropdown.Toggle>
										<Dropdown.Menu>
											{years.map((year) => (
												<Dropdown.Item
													key={year}
													onClick={() => handleYearChange(year)}
												>
													{year}
												</Dropdown.Item>
											))}
										</Dropdown.Menu>
									</Dropdown>
								</Col>
								<Col md={4} className='mb-md-0 mb-2'>
									<Dropdown>
										<Dropdown.Toggle
											className='w-100'
											variant='outline-secondary'
											id='series-dropdown'
											style={{ fontSize: '0.7rem' }}
										>
											{selectedSeries ? selectedSeries.name : 'Series'}
										</Dropdown.Toggle>
										<Dropdown.Menu>
											{series.map((series) => (
												<Dropdown.Item
													key={series._id}
													onClick={() => handleSeriesChange(series)}
												>
													{series.name}
												</Dropdown.Item>
											))}
										</Dropdown.Menu>
									</Dropdown>
								</Col>
								<Col md={4} className='mb-md-0 mb-2'>
									<Dropdown>
										<Dropdown.Toggle
											className='w-100'
											variant='outline-secondary'
											id='sport-dropdown'
											style={{ fontSize: '0.7rem' }}
										>
											{selectedSport ? selectedSport.name : 'Discipline'}
										</Dropdown.Toggle>
										<Dropdown.Menu>
											{sports.map((sport) => (
												<Dropdown.Item
													key={sport._id}
													onClick={() => handleSportChange(sport)}
												>
													{sport.name}
												</Dropdown.Item>
											))}
										</Dropdown.Menu>
									</Dropdown>
								</Col>
							</Row>
							<Table striped bordered hover style={{ fontSize: '0.7rem' }}>
								<thead>
									<tr>
										<th>Event</th>
										<th>Series</th>
										<th>Discipline</th>
										<th>Date</th>
										<th>Location</th>
										<th>Organizer Page</th>
										<th>Results</th>
									</tr>
								</thead>
								<tbody>
									{filteredEvents.map((event) => (
										<tr key={event._id}>
											<td>{event.name}</td>
											<td>{event.series ? event.series.name : 'N/A'}</td>
											<td>{event.sport.name}</td>
											<td>{new Date(event.date).toLocaleDateString()}</td>
											<td>
												<ReactCountryFlag
													countryCode={event.country}
													svg
													style={{ height: '1.5rem', width: '1.5rem' }}
													className='me-2'
												/>
												{event.location}
											</td>
											<td>
												<Button
													variant='outline-dark'
													style={{ fontSize: '0.7rem' }}
													onClick={() => window.open(event.eventUrl, '_blank')}
												>
													Organizer Page
												</Button>
											</td>
											<td>
												<Button
													variant='dark'
													// disabled={!isEventPast(event.date)}
													onClick={() => {
														handleSelectViewResults(event._id);
														/* Navigate to results page or handle viewing results */
													}}
													style={{ fontSize: '0.7rem' }}
												>
													View Results
												</Button>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Card.Body>
					</Card>
					<ResultsModal
						showEventResults={showEventResults}
						eventResults={eventResults}
						closeModal={() => setShowEventResults(false)}
					/>
				</Col>
				<Col lg={2} className='d-none d-lg-block text-end'>
					<RightAd />
				</Col>
			</Row>
		</Container>
	);
};

export default SchedulePage;
