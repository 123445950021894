// src/components/NavbarComponent.js
import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, Button, Dropdown } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaCaretDown, FaUserCircle } from 'react-icons/fa';
import { getUser } from '../../services/userService';
import logo from '../../assets/TRC_NEON_LOGO.png';

const NavbarComponent = ({ isLoggedIn, handleLogout }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [user, setUser] = useState(null);
	const [role, setRole] = useState('');
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		handleResize(); // Initial check
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		fetchUser();
		const userRole = localStorage.getItem('role');
		if (userRole) setRole(userRole);
	}, [isLoggedIn]);

	const handleLoginClick = () => {
		localStorage.setItem('location', location.pathname);
		navigate('/login');
	};

	const fetchUser = async () => {
		try {
			const res = await getUser();
			setUser(res.data);
		} catch (error) {
			console.error('Error fetching user:', error);
		}
	};

	return (
		<Navbar
			style={{ backgroundColor: '#16686e' }}
			expand='lg'
			// data-bs-theme='dark'
			className='justify-content-between text-white'
		>
			<Container fluid className=''>
				<Navbar.Brand as={Link} to='/' className='ms-sm-4'>
					<img
						src={logo}
						alt='TRC Logo'
						style={{ height: 'auto', width: '7rem' }}
					></img>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls='basic-navbar-nav' />
				<Navbar.Collapse
					id='basic-navbar-nav'
					className='justify-content-sm-center justify-content-end'
				>
					<Nav className='text-white me-5'>
						<Nav.Link as={Link} to='/' className='text-white'>
							Home
						</Nav.Link>
						<Nav.Link as={Link} to='/rankings' className='text-white'>
							Rankings
						</Nav.Link>
						<Nav.Link as={Link} to='/schedule' className='text-white'>
							Schedule
						</Nav.Link>
						<Nav.Link as={Link} to='/fantasy' className='text-white'>
							Fantasy
						</Nav.Link>
						<Nav.Link as={Link} to='/watch' className='text-white'>
							Watch
						</Nav.Link>
						{role == 'admin' && (
							<Nav.Link as={Link} to='/admin' className='text-white'>
								Admin
							</Nav.Link>
						)}
						{isMobile && isLoggedIn && (
							<Dropdown className=''>
								<Dropdown.Toggle
									variant='success'
									id='dropdown-basic'
									className='text-white border-0'
								>
									<FaUserCircle size={30} className='mb-1 me-2' />
									{user ? user.username : 'User'}
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item onClick={() => navigate('/editprofile')}>
										Edit Profile
									</Dropdown.Item>
									<hr className='m-0 p-0'></hr>
									<Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						)}
						{isMobile && !isLoggedIn && (
							<Button
								onClick={() => handleLoginClick()}
								variant='primary'
								className='me-md-3 px-3'
							>
								Login
							</Button>
						)}
					</Nav>
				</Navbar.Collapse>
				{!isMobile && (
					<Nav>
						{isLoggedIn ? (
							<Dropdown className=''>
								<Dropdown.Toggle
									variant='success'
									id='dropdown-basic'
									className='text-white border-0'
								>
									<FaUserCircle size={30} className='mb-1 me-2' />
									{user ? user.username : 'User'}
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item onClick={() => navigate('/editprofile')}>
										Edit Profile
									</Dropdown.Item>
									<hr className='m-0 p-0'></hr>
									<Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						) : (
							<>
								<Button
									onClick={() => handleLoginClick()}
									variant='primary'
									className='me-md-3 px-3'
								>
									Login
								</Button>
							</>
						)}
					</Nav>
				)}
			</Container>
		</Navbar>
	);
};

export default NavbarComponent;
