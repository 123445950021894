import React, { useEffect, useMemo, useState } from "react";
import {
	Card,
	Table,
	Button,
	Row,
	Col,
	Dropdown,
	DropdownButton,
	Form,
	Modal,
	Tab,
	Tabs,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { TbMathFunction } from "react-icons/tb";
import { createTeam, updateTeam } from "../../../services/teamService";
import getCountryISO2 from "country-iso-3-to-2";
import ReactCountryFlag from "react-country-flag";
import {
	FaRegHandPointRight,
	FaGamepad,
	FaBriefcaseMedical,
	FaUserInjured,
} from "react-icons/fa6";
import { LiaTimesSolid } from "react-icons/lia";
import {
	calculateMultiplier,
	calculateRiderValue,
	formatCurrency,
} from "../../../utils/utils";
import Prizes from "../../Fantasy/Components/Prizes";
import positionsPoints from "../../../assets/Crankworx_downhill_points.json";
import uciPoints from "../../../assets/uci_points.json";
import uciTeamPoints from "../../../assets/uci_team_points.json";
import { LuDot } from "react-icons/lu";
import {
	IoAddCircle,
	IoAddCircleOutline,
	IoRemoveCircleOutline,
	IoSave,
	IoWarning,
} from "react-icons/io5";
import { HiUserGroup } from "react-icons/hi2";
import { FaSave } from "react-icons/fa";
import {
	MdFormatListNumbered,
	MdOutlineViewHeadline,
	MdSelectAll,
} from "react-icons/md";
import { IoMdPodium } from "react-icons/io";
import { GiMoneyStack } from "react-icons/gi";

const BudgetMode = ({
	numberOfMen,
	numberOfWomen,
	budget,
	eligibleRiders,
	team,
	setTeam,
	globalLeague,
	disable,
}) => {
	const [riders, setRiders] = useState([]);
	const [uciTeams, setUciTeams] = useState([]);
	const [selectedUciTeam, setSelectedUciTeam] = useState(
		team?.selectedTeam || "",
	);
	const [teamRiders, setTeamRiders] = useState([]);
	const [sortKey, setSortKey] = useState("last_name"); // Default sort by last name
	const [sortOrder, setSortOrder] = useState("asc"); // Sort order: 'asc' or 'desc'
	const [filterGender, setFilterGender] = useState(""); // Filter by gender
	const [filterCountry, setFilterCountry] = useState(""); // Filter by country
	const [viewScoring, setViewScoring] = useState(false);
	const [budgetRemaining, setBudgetRemaining] = useState(budget);
	const [showDisabledModal, setShowDisabledModal] = useState(false);
	const [showRidersOnTeam, setShowRidersOnTeam] = useState(false);

	// Get query params for sport and series from URL
	const location = useLocation();
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(location.search);
	const sport = searchParams.get("sport");
	const series = searchParams.get("series");
	const leagueId = searchParams.get("leagueId");

	const maleRiders = riders.filter((rider) => rider.rider.gender === "m");
	const femaleRiders = riders.filter((rider) => rider.rider.gender === "f");

	const uniqueCountries = useMemo(() => {
		const countrySet = new Set(
			eligibleRiders.map((rider) => rider.country.toUpperCase().trim()),
		);
		return Array.from(countrySet).sort();
	}, [eligibleRiders]);

	//If team changes, setSelectedUCITeam
	useEffect(() => {
		setSelectedUciTeam(team?.selectedTeam || "");
		if (team?.selectedTeam) {
			const teamRiders = eligibleRiders.filter(
				(rider) => rider.team === team.selectedTeam,
			);
			setTeamRiders(teamRiders);
		}
	}, [team]);

	useEffect(() => {
		//Get all teams from sortedAndFilteredRiders
		const teams = eligibleRiders.map((rider) => rider.team);
		const uniqueTeams = [...new Set(teams)];
		uniqueTeams.sort();
		setUciTeams(uniqueTeams);

		if (team && Array.isArray(team.riders) && team.riders.length > 0) {
			setRiders(team.riders);
		}
	}, [team, eligibleRiders]);

	//on team change, calculate budget remaining
	useEffect(() => {
		const totalValue = riders.reduce(
			(acc, rider) =>
				acc +
				calculateRiderValue(
					rider.rider.ratings.find((r) => r.sport === sport).rating,
				),
			0,
		);
		setBudgetRemaining(budget - totalValue);
	}, [riders]);

	const addRiderToTeam = (rider) => {
		if (
			!riders.some((teamRider) => teamRider.rider._id === rider._id) &&
			riders.length < numberOfMen + numberOfWomen
		) {
			setRiders([...riders, { rider: rider, rank: 0 }]);
		}
	};

	const removeRiderFromTeam = (riderId) => {
		setRiders(riders.filter((rider) => rider.rider._id !== riderId));
	};

	const validateTeam = () => {
		const men = riders.filter((rider) => rider.rider.gender === "m").length;
		const women = riders.filter((rider) => rider.rider.gender === "f").length;
		return men === numberOfMen && women === numberOfWomen;
	};

	//set uci team and set riders to all riders that are part of team
	const handleSelectUciTeam = (_team) => {
		setSelectedUciTeam(_team);
		const teamRiders = eligibleRiders.filter((rider) => rider.team === _team);
		setTeamRiders(teamRiders);
	};

	const saveTeam = async () => {
		if (!validateTeam()) {
			alert(
				"Please complete your team according to the game mode's requirements.",
			);
		}
		try {
			if (team) {
				const teamData = {
					...team,
					riders: riders,
					selectedTeam: selectedUciTeam,
				};

				const res = await updateTeam(team._id, teamData);
				if (res) {
					if (leagueId) {
						navigate(`/league/${leagueId}`);
					} else {
						navigate("/fantasy");
					}
				}
			} else {
				const teamData = {
					league: leagueId ? leagueId : null,
					riders: riders,
					selectedTeam: selectedUciTeam,
					name: team?.name ? team.name : "",
					sport: team?.sport ? team.sport : sport,
					series: team?.series ? team.series : series,
				};

				const res = await createTeam(teamData);
				if (res) {
					if (leagueId) {
						navigate(`/league/${leagueId}`);
					} else {
						navigate("/fantasy");
					}
				}
			}
		} catch (error) {
			alert("Error saving team:" + error);
		}
	};

	const isAddDisabled = (gender, rider) => {
		const isAlreadyInTeam = riders.some(
			(teamRider) => teamRider.rider._id === rider._id,
		);
		if (isAlreadyInTeam) return true;

		//check if adding rider will exceed budget
		const totalValue = riders.reduce(
			(acc, rider) =>
				acc +
				calculateRiderValue(
					rider.rider.ratings.find((r) => r.sport === sport).rating,
				), // Calculate total value of riders
			0,
		);

		//check that rider has a rating
		if (!rider.ratings.find((r) => r.sport === sport)) return true;

		const riderValue = calculateRiderValue(
			rider?.ratings.find((r) => r.sport === sport).rating,
		);

		if (totalValue + riderValue > budget) return true;

		return gender === "m"
			? riders.filter((r) => r.rider.gender === "m").length >= numberOfMen
			: riders.filter((r) => r.rider.gender === "f").length >= numberOfWomen;
	};

	const isSaveDisabled = () => {
		return (
			riders.length !== numberOfMen + numberOfWomen || selectedUciTeam == ""
		);
	};

	const aggregateData = (gender) => {
		const filteredData = uciPoints.filter((item) => item.gender === gender);
		const positionData = {};

		filteredData.forEach((item) => {
			if (!positionData[item.position]) {
				positionData[item.position] = { q: "-", s: "-", f: "-" }; // initialize with default values
			}
			if (item.round === "q") {
				positionData[item.position].q = item.points;
			} else if (item.round === "s") {
				positionData[item.position].s = item.points;
			} else if (item.round === "f") {
				positionData[item.position].f = item.points;
			}
		});

		return positionData;
	};

	const menData = aggregateData("m");
	const womenData = aggregateData("f");

	// Sort and filter riders before rendering
	const sortedAndFilteredRiders = eligibleRiders
		.filter(
			(rider) =>
				(filterGender ? rider.gender === filterGender : true) &&
				(filterCountry
					? rider.country.toLowerCase() === filterCountry.toLowerCase()
					: true),
		)
		.sort((a, b) => {
			let compareA =
				sortKey === "rating"
					? a.ratings.find((r) => r.sport.toString() === sport)?.rating || 0
					: a[sortKey];
			let compareB =
				sortKey === "rating"
					? b.ratings.find((r) => r.sport.toString() === sport)?.rating || 0
					: b[sortKey];

			if (sortOrder === "asc") {
				return compareA > compareB ? 1 : -1;
			} else {
				return compareA < compareB ? 1 : -1;
			}
		});

	return (
		<div>
			<Prizes leagues={[globalLeague]} />
			<Card className="mb-3 shadow">
				<Card.Header>
					{" "}
					<FaGamepad className="me-2 mb-1" />
					Budget Mode
				</Card.Header>
				<Card.Body style={{ fontSize: "0.8rem" }}>
					<Card.Text>
						<FaRegHandPointRight className="me-2" />
						Select {numberOfMen} Men, and {numberOfWomen} Women while staying
						within a budget of {formatCurrency(budget)}.
					</Card.Text>
					<Card.Text>
						<FaRegHandPointRight className="me-2" />
						Select one UCI World Cup Team.
					</Card.Text>
					<Card.Text>
						<FaRegHandPointRight className="me-2" />
						Your points are awarded by points scored by the athlete at the
						event, combined with the points that your team earns (See UCI Points
						below).
					</Card.Text>

					<div className="d-flex flex-row justify-content-md-end justify-content-center">
						<Button
							variant="dark"
							size="sm"
							onClick={() => setViewScoring(true)}
						>
							<IoMdPodium className="mb-1 me-2" />
							UCI Points
						</Button>
					</div>
				</Card.Body>
			</Card>
			<Card className="mb-3 shadow">
				<Card.Header className="bg-success text-white">My Team</Card.Header>
				<Card.Body>
					{/* Show budget left */}
					<Row className="align-items-center justify-content-center py-0 my-3 text-center fw-bold">
						<Col xs={6} md={6} className="text-center">
							<Card className="mb-1 shadow bg-primary text-white border-0">
								<Card.Body className="py-3">
									<span>
										<GiMoneyStack
											className="mb-1 me-2"
											style={{ width: "1.3rem", height: "auto" }}
										/>{" "}
										Budget Remaining:{" "}
									</span>
									<span className="text-dark">
										{" "}
										{formatCurrency(budgetRemaining)}
									</span>
								</Card.Body>
							</Card>
						</Col>
					</Row>
					<Card className="mb-1 py-0 shadow">
						<Card.Body className="py-1 my-0">
							<Row className="align-items-center py-0 my-0 text-sm-start text-center fw-bold">
								<Col md={4} className="text-start my-2 my-sm-0">
									Rider
								</Col>
								<Col xs={6} md={6} className="text-center">
									Value
								</Col>
								<Col
									xs={12}
									md={2}
									className="d-flex justify-content-center mt-2 mt-md-0"
								></Col>
							</Row>
						</Card.Body>
					</Card>
					<div className="ms-4 mb-1 fw-bold">Men</div>
					{maleRiders.map((rider) => (
						<Card
							key={rider.rider._id + "current"}
							className="mb-1 py-0 shadow"
						>
							<Card.Body className="py-1 my-0">
								<Row className="align-items-center py-0 my-0 text-sm-start text-center">
									<Col md={4} className="text-start my-2 my-sm-0">
										{getCountryISO2(
											rider.rider.country.toUpperCase().trim(),
										) ? (
											<ReactCountryFlag
												countryCode={getCountryISO2(
													rider.rider.country.toUpperCase().trim(),
												)}
												svg
												style={{ width: "3.5rem" }}
											/>
										) : (
											<span className="mx-3">{rider.rider.country}</span>
										)}
										<b>{rider.rider.last_name}</b> {rider.rider.first_name}{" "}
										<span style={{ fontSize: "0.6rem" }}>
											({rider.rider.gender})
										</span>
										{rider.rider.status && (
											<FaUserInjured className="mb-1 ms-2" />
										)}
									</Col>
									<Col xs={6} md={6} className="text-center">
										{rider.rider.ratings.map((rating) => {
											if (rating.sport.toString() === sport) {
												return (
													<span key={rating._id}>
														{formatCurrency(calculateRiderValue(rating.rating))}
													</span>
												);
											}
											return null;
										})}
									</Col>
									<Col
										xs={12}
										md={2}
										className="d-flex justify-content-center mt-2 mt-md-0"
									>
										<Button
											variant="outline-dark"
											size="sm"
											className=""
											onClick={() => removeRiderFromTeam(rider.rider._id)}
										>
											<IoRemoveCircleOutline className="me-2" />
											Remove
										</Button>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					))}
					<div className="ms-4 mt-3 mb-1 fw-bold">Women</div>
					{femaleRiders.map((rider) => (
						<Card
							key={rider.rider._id + "current"}
							className="mb-1 py-0 shadow"
						>
							<Card.Body className="py-1 my-0">
								<Row className="align-items-center py-0 my-0 text-sm-start text-center">
									<Col md={4} className="text-start my-2 my-sm-0">
										{getCountryISO2(
											rider.rider.country.toUpperCase().trim(),
										) ? (
											<ReactCountryFlag
												countryCode={getCountryISO2(
													rider.rider.country.toUpperCase().trim(),
												)}
												svg
												style={{ width: "3.5rem" }}
											/>
										) : (
											<span className="mx-3">{rider.rider.country}</span>
										)}
										<b>{rider.rider.last_name}</b> {rider.rider.first_name}{" "}
										<span style={{ fontSize: "0.6rem" }}>
											({rider.rider.gender})
										</span>
										{rider.rider.status && (
											<FaUserInjured className="mb-1 ms-2" />
										)}
									</Col>
									<Col xs={6} md={6} className="text-center">
										{rider.rider.ratings.map((rating) => {
											if (rating.sport.toString() === sport) {
												return (
													<span key={rating._id}>
														{formatCurrency(calculateRiderValue(rating.rating))}
													</span>
												);
											}
											return null;
										})}
									</Col>
									<Col
										xs={12}
										md={2}
										className="d-flex justify-content-center mt-2 mt-md-0"
									>
										<Button
											variant="outline-dark"
											size="sm"
											className=""
											onClick={() => removeRiderFromTeam(rider.rider._id)}
										>
											<IoRemoveCircleOutline className="me-2" />
											Remove
										</Button>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					))}

					{/* Show possible teams
					 */}
					<Card className="mb-1 mt-3 py-0 shadow">
						<Card.Header className="bg-light text-dark">
							<HiUserGroup className="me-2 mb-1" />
							Select a Team
						</Card.Header>
						<Card.Body className="my-0">
							<Row className="align-items-center py-0 my-0 text-center fw-bold justify-content-center">
								{/* //Dropdown displaying teams */}
								<Col md={3} className="text-center my-2 my-sm-0" />
								<Col md={6} className="text-center my-2 my-sm-0">
									<Form.Select
										size="sm"
										value={selectedUciTeam}
										onChange={(e) => handleSelectUciTeam(e.target.value)}
										className="border-primary bg-light text-dark fw-bold text-center"
										style={{ cursor: "pointer" }}
									>
										<option value="">Select a Team</option>
										{uciTeams.map((team) => (
											<option key={team} value={team}>
												{team}
											</option>
										))}
									</Form.Select>
								</Col>
								<Col md={3} className="text-center my-2 my-sm-0">
									<Button
										variant="success"
										size="sm"
										onClick={() => setShowRidersOnTeam(true)}
										className="w-100"
									>
										<MdOutlineViewHeadline className="mb-1 me-2" />
										View Riders
									</Button>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Card.Body>
				<Card.Footer>
					<Row>
						<Col className="w-100 text-end">
							<Button
								onClick={() => {
									if (disable) {
										setShowDisabledModal(true);
										return;
									}

									saveTeam();
								}}
								disabled={isSaveDisabled()}
								className="px-4 "
							>
								<FaSave className="me-2 mb-1" />
								Save Team
							</Button>
						</Col>
					</Row>
				</Card.Footer>
			</Card>

			<Card>
				<Card.Header>Eligible Riders</Card.Header>
				<Card.Body>
					<Row className="mb-3">
						<Col md={2}>
							<Form.Select
								size="sm"
								className="w-100"
								onChange={(e) => {
									const [key, order] = e.target.value.split(",");
									setSortKey(key);
									setSortOrder(order);
								}}
								value={`${sortKey},${sortOrder}`}
							>
								<option value="last_name,asc">Last Name Asc</option>
								<option value="last_name,desc">Last Name Desc</option>
								<option value="rating,asc">Value Asc</option>
								<option value="rating,desc">Value Desc</option>
							</Form.Select>
						</Col>
						<Col md={2} className="text-center justify-content-center">
							<Form.Select
								size="sm"
								className="w-100" // Changed from w-auto to w-100
								onChange={(e) => setFilterGender(e.target.value)}
								value={filterGender}
							>
								<option value="">Gender</option>
								<option value="m">Male</option>
								<option value="f">Female</option>
							</Form.Select>
						</Col>
						<Col md={2}>
							<Form.Select
								size="sm"
								value={filterCountry}
								onChange={(e) => setFilterCountry(e.target.value)}
								className="w-100" // Changed from w-auto to w-100
							>
								<option value="">Country</option>
								{uniqueCountries.map((country) => (
									<option key={country} value={country}>
										{country}
									</option>
								))}
							</Form.Select>
						</Col>
					</Row>
					<Card className="mb-1 py-0 shadow">
						<Card.Body className="py-1 my-0">
							<Row className="align-items-center py-0 my-0 text-sm-start text-center fw-bold">
								<Col md={4} className="text-start my-2 my-sm-0">
									Rider
								</Col>
								<Col xs={6} md={6} className="text-center">
									Value
								</Col>
								<Col
									xs={12}
									md={2}
									className="d-flex justify-content-center mt-2 mt-md-0"
								></Col>
							</Row>
						</Card.Body>
					</Card>
					{sortedAndFilteredRiders.map((rider) => (
						<Card key={rider._id} className="mb-1 py-0 shadow">
							<Card.Body className="py-1 my-0">
								<Row className="align-items-center py-0 my-0 text-sm-start text-center">
									<Col md={4} className="text-start my-2 my-sm-0">
										{getCountryISO2(rider.country.toUpperCase().trim()) ? (
											<ReactCountryFlag
												countryCode={getCountryISO2(
													rider.country.toUpperCase().trim(),
												)}
												svg
												style={{ width: "3.5rem" }}
											/>
										) : (
											rider.country
										)}
										<b>{rider.last_name}</b> {rider.first_name}{" "}
										<span style={{ fontSize: "0.6rem" }}>({rider.gender})</span>
									</Col>
									<Col xs={6} md={6} className="text-center">
										{rider.ratings.map((rating) => {
											if (rating.sport.toString() === sport) {
												return (
													<span key={rating._id}>
														{formatCurrency(calculateRiderValue(rating.rating))}
													</span>
												);
											}
											return null;
										})}
									</Col>
									<Col
										xs={12}
										md={2}
										className="d-flex justify-content-center mt-2 mt-md-0"
									>
										<Button
											variant={
												isAddDisabled(rider.gender, rider)
													? "secondary"
													: "dark"
											}
											size="sm"
											className=""
											onClick={() => addRiderToTeam(rider)}
											disabled={isAddDisabled(rider.gender, rider)}
										>
											<IoAddCircleOutline className="me-2" />
											Add Rider
										</Button>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					))}
				</Card.Body>
			</Card>
			<Modal
				show={viewScoring}
				onHide={() => setViewScoring(false)}
				size="lg"
				centered
			>
				<Modal.Header
					closeButton
					data-bs-theme="dark"
					className="bg-dark text-light"
				>
					<Modal.Title>
						<b>UCI Downhill</b> <LuDot className="mb-1 me-2" />
						Rider Points
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="px-md-5">
					<Tabs
						defaultActiveKey="men"
						id="uncontrolled-tab-example"
						className="mb-3"
					>
						<Tab eventKey="men" title="Men" style={{ overflow: "auto" }}>
							<Table striped bordered hover style={{ overflowX: "auto" }}>
								<thead>
									<tr>
										<th>
											<MdFormatListNumbered />
										</th>
										<th>Finals</th>
										<th>Semis</th>
										<th>Qualifying</th>
									</tr>
								</thead>
								<tbody>
									{Object.entries(menData).map(([position, points]) => (
										<tr key={position}>
											<td>{position}.</td>
											<td>{points.f}</td>
											<td>{points.s}</td>
											<td>{points.q}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Tab>
						<Tab eventKey="women" title="Women" style={{ overflow: "auto" }}>
							<Table striped bordered hover>
								<thead>
									<tr>
										<th>
											<MdFormatListNumbered />
										</th>
										<th>Finals</th>
										<th>Semis</th>
										<th>Qualifying</th>
									</tr>
								</thead>
								<tbody>
									{Object.entries(womenData).map(([position, points]) => (
										<tr key={position}>
											<td>{position}.</td>
											<td>{points.f}</td>
											<td>{points.s}</td>
											<td>{points.q}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Tab>
						<Tab eventKey={"team"} title="Team" style={{ overflow: "auto" }}>
							<div className="mb-3 fst-italic" style={{ fontSize: "0.8rem" }}>
								{" "}
								** Please note that team points have be created and calculated
								by The Race Companion and in no way reflect official UCI Team
								Points.{" "}
							</div>
							<Table striped bordered hover>
								<thead>
									<tr>
										<th>
											<MdFormatListNumbered />
										</th>
										<th>Points</th>
									</tr>
								</thead>
								<tbody>
									{uciTeamPoints.map((team) => (
										<tr key={team.Position}>
											<td>{team.Position}.</td>
											<td>{team.Points}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</Tab>
					</Tabs>
				</Modal.Body>
			</Modal>
			<Modal
				show={showDisabledModal}
				onHide={() => setShowDisabledModal(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<IoWarning className="me-2 mb-1" />
						Event Underway
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>An event is currently underway.</p>
					<p>
						Changes to your team will no longer be reflected in the current
						event.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="primary"
						onClick={() => {
							saveTeam();
						}}
					>
						Save Team
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showRidersOnTeam}
				onHide={() => setShowRidersOnTeam(false)}
				size="lg"
				centered
			>
				<Modal.Header closeButton className="bg-success text-light">
					<Modal.Title>
						{" "}
						<HiUserGroup className="me-2 mb-1" />
						{selectedUciTeam}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Table striped hover size="sm">
						<tbody>
							{teamRiders.map((rider) => (
								<tr key={rider._id}>
									<td>
										{getCountryISO2(rider.country.toUpperCase().trim()) ? (
											<ReactCountryFlag
												countryCode={getCountryISO2(
													rider.country.toUpperCase().trim(),
												)}
												svg
												style={{ width: "2rem" }}
											/>
										) : (
											rider.country
										)}
										<b className="ms-3">{rider.last_name}</b>,{" "}
										{rider.first_name}
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default BudgetMode;
