import React, { useEffect } from 'react';
import { getActiveAd } from '../../services/adService';

const MobileAd = () => {
	const [activeAd, setActiveAd] = React.useState(null);

	useEffect(() => {
		fetchAd();
	}, []);

	const fetchAd = async () => {
		try {
			const res = await getActiveAd();
			setActiveAd(res.data);
		} catch (error) {
			console.error('Error fetching ad:', error);
		}
	};

	return (
		<div className='p-0'>
			{activeAd && activeAd?.mobileAdUrl && (
				<img
					onClick={() => {
						window.open(activeAd.url, '_blank');
					}}
					src={activeAd?.mobileAdUrl}
					alt='Fantasy Banner'
					className='m-0 p-0'
					style={{ width: '100vw', maxHeight: '7rem', height: 'auto' }}
				/>
			)}
		</div>
	);
};

export default MobileAd;
