import React from "react";
import { Card, Col, Nav, Row } from "react-bootstrap";

const TopRowSport = ({ sports, selectedSport, handleSelectSport }) => {
	const [activeKey, setActiveKey] = React.useState(sports[0] || "");

	const handleSelect = (eventKey) => {
		// if the selected sport is the same as the active key, unselect it
		if (eventKey === activeKey) {
			setActiveKey("");
			handleSelectSport(null);
			return;
		}

		setActiveKey(eventKey);
		const _selectedSport = sports.find((sport) => sport.name === eventKey);

		handleSelectSport(_selectedSport);
	};

	return (
		<div className="series-row d-flex justify-content-center">
			<Row
				className="d-flex flex-row justify-content-center w-100 border-light align-items-center"
				style={{
					borderTop: "1px",
					borderRight: "0px",
					borderLeft: "0px",
					borderBottom: "0px",
					borderStyle: "solid",
				}}
			>
				<Col xl={6} lg={8} md={10} sm={12} className="text-center">
					<Card
						className="border-light bg-dark"
						style={{
							borderTop: "0px",
							borderRight: "0px",
							borderLeft: "0px",
							borderTopLeftRadius: "0px",
							borderTopRightRadius: "0px",
						}}
					>
						<Card.Header className="bg-dark rounded shadow-sm">
							<Nav
								variant="pills"
								activeKey={activeKey}
								onSelect={handleSelect}
								className="nav-justified series-nav"
							>
								{sports.map((option) => (
									<Nav.Item key={option.name} className="mx-2 mt-md-0 mt-2">
										<Nav.Link
											eventKey={option.name}
											className={activeKey === option.name ? "active-pill" : ""}
										>
											{option.name}
										</Nav.Link>
									</Nav.Item>
								))}
							</Nav>
						</Card.Header>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default TopRowSport;
