import React from 'react';
import {
	Button,
	Card,
	Col,
	Container,
	Form,
	FormControl,
	ListGroup,
	Row,
} from 'react-bootstrap';
import {
	createSeries,
	createSport,
	getSeries,
	getSports,
} from '../../services/series';
import { useEffect } from 'react';
import AdminOffCanvasSidebar from './AdminOffCanvasSidebar';
import { RxHamburgerMenu } from 'react-icons/rx';

const AdminSportsPage = () => {
	const [series, setSeries] = React.useState('');
	const [seriesDescription, setSeriesDescription] = React.useState('');
	const [sport, setSport] = React.useState('');
	const [sportDescription, setSportDescription] = React.useState('');
	const [seriesList, setSeriesList] = React.useState([]);
	const [sports, setSports] = React.useState([]);
	const [showSidebar, setShowSidebar] = React.useState(false);

	useEffect(() => {
		getAllSeries();
		getAllSports();
	}, []);

	const getAllSeries = async () => {
		try {
			const res = await getSeries();
			if (res) {
				setSeriesList(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getAllSports = async () => {
		try {
			const res = await getSports();
			if (res) {
				setSports(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const createNewSeries = async () => {
		try {
			const data = {
				name: series,
				description: seriesDescription,
			};
			const res = await createSeries(data);
			if (res) {
				//clear the state
				setSeries('');
				setSeriesDescription('');
				getAllSeries();
			}
		} catch (error) {
			console.log(error);
		}
	};

	const createNewSport = async () => {
		try {
			const data = {
				name: sport,
				description: sportDescription,
			};
			const res = await createSport(data);
			if (res) {
				//clear the state
				setSport('');
				setSportDescription('');
				getAllSports();
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Container className='bg-light pt-3'>
			{/* Your content here */}
			<Row>
				<Col>
					<Button variant='primary' onClick={() => setShowSidebar(true)}>
						<RxHamburgerMenu />
					</Button>
					<AdminOffCanvasSidebar
						show={showSidebar}
						handleClose={() => setShowSidebar(false)}
					/>
				</Col>
			</Row>

			<div className='d-flex flex-row alignt-items-center justify-content-center py-4'>
				<h1>Sports & Series </h1>
			</div>
			<Row>
				<Col>
					<Card>
						<Card.Body>
							<Card.Title>Current Sports</Card.Title>
							<ListGroup>
								{sports.map((sport, index) => (
									<ListGroup.Item key={index}>{sport.name}</ListGroup.Item>
								))}
							</ListGroup>
						</Card.Body>
					</Card>
				</Col>
				<Col>
					<Card>
						<Card.Body>
							<Card.Title>Current Series</Card.Title>
							<ListGroup>
								{seriesList.map((series, index) => (
									<ListGroup.Item key={index}>{series.name}</ListGroup.Item>
								))}
							</ListGroup>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<Card className='mt-4'>
				<Card.Body>
					<Card.Title>Create a New Sport</Card.Title>
					<Form className='mb-3'>
						<Form.Group controlId='formBasicEmail'>
							<Form.Label>Sport Name</Form.Label>
							<FormControl
								type='text'
								placeholder='Enter sport name'
								value={sport}
								onChange={(e) => setSport(e.target.value)}
							/>
						</Form.Group>
						<Form.Group controlId='formBasicEmail'>
							<Form.Label>Description</Form.Label>
							<FormControl
								as='textarea'
								placeholder='Enter description'
								value={sportDescription}
								onChange={(e) => setSportDescription(e.target.value)}
							/>
						</Form.Group>
					</Form>
					<Card.Footer
						className='d-flex flex-row align-items-end justify-content-end'
						style={{ backgroundColor: 'white' }}
					>
						<Button variant='primary' className='' onClick={createNewSport}>
							Create Sport
						</Button>
					</Card.Footer>
				</Card.Body>
			</Card>
			{/* // Create a new Series */}
			<Card className='mt-4'>
				<Card.Body>
					<Card.Title>Create a New Series</Card.Title>
					<Form className='mb-3'>
						<Form.Group controlId='formBasicEmail'>
							<Form.Label>Series Name</Form.Label>
							<FormControl
								type='text'
								placeholder='Enter series name'
								value={series}
								onChange={(e) => setSeries(e.target.value)}
							/>
						</Form.Group>
						<Form.Group controlId='formBasicEmail'>
							<Form.Label>Description</Form.Label>
							<FormControl
								as='textarea'
								placeholder='Enter description'
								value={seriesDescription}
								onChange={(e) => setSeriesDescription(e.target.value)}
							/>
						</Form.Group>
					</Form>
					<Card.Footer
						className='d-flex flex-row align-items-end justify-content-end'
						style={{ backgroundColor: 'white' }}
					>
						<Button variant='primary' className='' onClick={createNewSeries}>
							Create Series
						</Button>
					</Card.Footer>
				</Card.Body>
			</Card>
		</Container>
	);
};

export default AdminSportsPage;
