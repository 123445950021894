import React from 'react';
import { Container, Card, ListGroup, Col, Row } from 'react-bootstrap';

const ContactUs = () => {
	return (
		<Container className='mt-5 d-flex flex-row justify-content-center'>
			<Card className='text-center shadow' style={{ width: 'fit-content' }}>
				<Card.Header as='h5' className='bg-success text-white'>
					Contact Us
				</Card.Header>
				<Card.Body style={{ fontSize: '0.8rem' }}>
					<Card.Title>Want to get in touch?</Card.Title>
					<Card.Text>
						Reach out to us for any issues or inquiries, and we'll get back to
						you as soon as possible.
					</Card.Text>
					<Row>
						<Col sm={6} className=''>
							<Card style={{ width: 'fit-content', minHeight: '10rem' }}>
								<Card.Header as='h5'>Site Inquiries</Card.Header>
								<Card.Body
									className='text-start'
									style={{ width: 'fit-content' }}
								>
									<Card.Title className='fs-6 fw-bold'>
										Kurtis Gassewitz{' '}
									</Card.Title>
									<div>
										Email: kurtisgassewitz@gmail.com
										<a href='mailto:kurtisgassewitz@gmail.com'></a>
									</div>
								</Card.Body>
							</Card>
						</Col>
						<Col sm={6}>
							<Card style={{ width: 'fit-content', minHeight: '10rem' }}>
								<Card.Header as='h5'>Marketing Inquiries</Card.Header>
								<Card.Body
									className='text-start'
									style={{ width: 'fit-content' }}
								>
									<Card.Title className='fs-6 fw-bold'>Davi Birks</Card.Title>
									<div>Email: Davi@TRCMediaHouse.com</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Container>
	);
};

export default ContactUs;
