import apiInstance from "./api";

export const getLeaderboard = async (leagueId) => {
	try {
		const res = await apiInstance.get(`/leaderboards/${leagueId}`);
		return res;
	} catch (error) {
		throw error;
	}
};

export const updateLeaderboard = async () => {
	try {
		const res = await apiInstance.put("/leaderboards");
		return res;
	} catch (error) {
		throw error;
	}
};

export const updateLeaderbordById = async (id) => {
	try {
		const res = await apiInstance.put(`/leaderboards/${id}`);
		return res;
	} catch (error) {
		throw error;
	}
};

export const getRoundWinners = async (leagueId) => {
	try {
		const res = await apiInstance.get(`/leaderboards/round/${leagueId}`);
		return res;
	} catch (error) {
		throw error;
	}
};
