import React, { useState } from "react";
import { getLeagues } from "../../../services/leagueService";
import { Row, Col, Card, Button, Modal } from "react-bootstrap";
import { LuDot } from "react-icons/lu";
import { FaTrophy } from "react-icons/fa6";

const Prizes = ({ leagues }) => {
	const [showSeriesPrizeModal, setShowSeriesPrizeModal] = useState(false);
	const [showRoundPrizeModal, setShowRoundPrizeModal] = useState(false);
	const [selectedLeague, setSelectedLeague] = useState(null);

	const globalLeagues = leagues.filter(
		(league) => league?.name.toLowerCase() === "global league",
	);

	return (
		<div
			className="d-flex flex-row  mt-3 mb-4 w-100"
			style={{ maxHeight: "25rem", overflow: "auto", width: "" }}
		>
			<div
				className="flex-grow-1 min-width-zero"
				style={{ minWidth: "0px" }}
			></div>
			{globalLeagues.map((league) => (
				<React.Fragment key={league?._id}>
					{league?.image && (
						<div className="mb-4 mx-2" style={{ minWidth: "14rem" }}>
							<Card style={{ height: "20rem", width: "fit-content" }}>
								<div className="d-flex flex-row justify-content-center">
									<Card.Img
										variant="top"
										src={league?.image}
										className=""
										style={{ maxHeight: "13rem", maxWidth: "16rem" }}
									/>
								</div>
								<Card.Body>
									<div className="d-flex flex-row justify-content-center">
										<Button
											className="text-center"
											onClick={() => {
												setShowSeriesPrizeModal(true);
												setSelectedLeague(league);
											}}
										>
											<FaTrophy className="mb-1 me-2" /> Overall Prize
										</Button>
									</div>
									{/* <Card.Text>{league?.overallPrizeDescription}</Card.Text> */}
								</Card.Body>
								<Card.Footer className="text-muted text-center">
									{league?.series.name}
									<LuDot />
									{league?.sport.name}
								</Card.Footer>
							</Card>
						</div>
					)}
					{league?.roundImage && (
						<div className="mb-4 mx-3" style={{ minWidth: "14rem" }}>
							<Card style={{ height: "20rem", width: "fit-content" }}>
								<Card.Img
									variant="top"
									src={league?.roundImage}
									className=""
									style={{ maxHeight: "13rem", maxWidth: "16rem" }}
								/>
								<Card.Body>
									<div className="d-flex flex-row justify-content-center">
										<Button
											className="text-center"
											onClick={() => {
												setShowRoundPrizeModal(true);
												setSelectedLeague(league);
											}}
										>
											<FaTrophy className="mb-1 me-2" /> Round Prize
										</Button>
									</div>
								</Card.Body>
								<Card.Footer className="text-muted text-center">
									{league?.series.name}
									<LuDot />
									{league?.sport.name}
								</Card.Footer>
							</Card>
						</div>
					)}
				</React.Fragment>
			))}
			<Modal
				show={showSeriesPrizeModal}
				onHide={() => setShowSeriesPrizeModal(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<FaTrophy className="mb-1 me-2" /> Overall Prize
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex flex-row justify-content-center">
						<img
							src={selectedLeague?.image}
							alt={selectedLeague?.name}
							style={{ height: "13rem" }}
							onClick={() => {
								if (selectedLeague?.overallPrizeUrl)
									window.open(`${selectedLeague?.overallPrizeUrl}`, "_blank");
							}}
						/>
					</div>
					<hr></hr>
					<p className="text-center fw-bold">
						{selectedLeague?.overallPrizeDescription}
					</p>
					<p style={{ fontSize: "0.7rem" }}>
						*Awarded to the user with the highest score in the{" "}
						{selectedLeague?.series.name}: {selectedLeague?.sport.name} Global
						League at the end of the regular season. Eligibility for the prize
						is subject to the competition's terms and conditions.
					</p>
				</Modal.Body>
			</Modal>
			<Modal
				show={showRoundPrizeModal}
				onHide={() => setShowRoundPrizeModal(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<FaTrophy className="mb-1 me-2" /> Round Prize
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex flex-row justify-content-center">
						<img
							src={selectedLeague?.roundImage}
							alt={selectedLeague?.name}
							style={{ height: "13rem" }}
							onClick={() => {
								if (selectedLeague?.roundPrizeUrl)
									window.open(`${selectedLeague?.roundPrizeUrl}`, "_blank");
							}}
						/>
					</div>
					<hr></hr>
					<p className="text-center fw-bold">
						{selectedLeague?.roundPrizeDescription
							? selectedLeague?.roundPrizeDescription
							: "Prize Description Coming soon..."}
					</p>
					<p style={{ fontSize: "0.7rem" }}>
						*Awarded to the user who scores the highest points in the upcoming
						round of the {selectedLeague?.series.name}:{" "}
						{selectedLeague?.sport.name} Global League. Eligibility for the
						prize is subject to the competition's terms and conditions.
					</p>
				</Modal.Body>
			</Modal>
			<div
				className="flex-grow-1 min-width-zero"
				style={{ minWidth: "0px" }}
			></div>{" "}
		</div>
	);
};

export default Prizes;
