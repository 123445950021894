import React, { useEffect } from 'react';
import { getActiveAd } from '../../services/adService';

const LeftAd = () => {
	const [activeAd, setActiveAd] = React.useState(null);

	useEffect(() => {
		fetchAd();
	}, []);

	const fetchAd = async () => {
		try {
			const res = await getActiveAd();
			setActiveAd(res.data);
		} catch (error) {
			console.error('Error fetching ad:', error);
		}
	};

	return (
		<div className='tower-ad-left'>
			{activeAd && activeAd?.leftTowerAdUrl && (
				<img
					onClick={() => {
						window.open(activeAd.url, '_blank');
					}}
					src={activeAd?.leftTowerAdUrl}
					alt='Left Tower Ad'
					style={{ maxWidth: '13rem' }}
				/>
			)}
		</div>
	);
};

export default LeftAd;
