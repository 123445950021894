import React, { useState, useEffect } from "react";
import {
	Card,
	Button,
	Form,
	FormControl,
	Modal,
	Row,
	Col,
	Container,
	CardBody,
	ListGroup,
} from "react-bootstrap";
import AdminOffCanvasSidebar from "./AdminOffCanvasSidebar";
import { RxHamburgerMenu } from "react-icons/rx";
import {
	RemoveDuplicateLeagues,
	RemoveDuplicateTeams,
	RemoveDuplicatesOwner,
	createGameMode,
	createGameModeAssignment,
	deleteAllRiders,
	getGameModeAssignments,
	getGameModes,
	uploadRiders,
} from "../../services/adminService";
import { getSeries, getSports } from "../../services/series";
import {
	updateLeaderboard,
	updateLeaderbordById,
} from "../../services/leaderboardService";
import { setTeamResult } from "../../services/teamResultService";
import { makeRidersIneligible } from "../../services/riderService";

const AdminPage = () => {
	const [showSidebar, setShowSidebar] = useState(false);
	const [gameModes, setGameModes] = useState([]);
	const [sports, setSports] = useState([]);
	const [series, setSeries] = useState([]);
	const [sportsDropdown, setSportsDropdown] = useState([]);
	const [seriesDropdown, setSeriesDropdown] = useState([]);
	const [gamesDropdown, setGamesDropdown] = useState([]);
	const [gameModeAssignments, setGameModeAssignments] = useState([]);
	const [analytics, setAnalytics] = useState([]);

	const [formData, setFormData] = useState({
		name: "",
		description: "",
		numberOfMen: 0,
		numberOfWomen: 0,
		value: 0,
	});

	const [assignmentFormData, setAssignmentFormData] = useState({
		sport: "",
		series: "",
		gameMode: "",
	});

	useEffect(() => {
		fetchGameModes();
		fetchSports();
		fetchSeries();
		fetchGameModeAssignments();
	}, []);

	const fetchSports = async () => {
		try {
			const res = await getSports();
			setSports(res.data);
			setSportsDropdown(res.data);
		} catch (error) {
			console.error("Error fetching sports:", error);
		}
	};

	const fetchSeries = async () => {
		try {
			const res = await getSeries();
			setSeries(res.data);
			setSeriesDropdown(res.data);
		} catch (error) {
			console.error("Error fetching series:", error);
		}
	};

	const fetchGameModes = async () => {
		try {
			const res = await getGameModes();
			setGameModes(res.data);
			setGamesDropdown(res.data);
		} catch (error) {
			console.error("Error fetching game modes:", error);
		}
	};

	const fetchGameModeAssignments = async () => {
		try {
			const res = await getGameModeAssignments();
			setGameModeAssignments(res.data);
		} catch (error) {
			console.error("Error fetching game mode assignments:", error);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const res = await createGameMode(formData);
			console.log("Game mode created:", res.data);
		} catch (error) {
			console.error("Error creating game mode:", error);
		}
	};

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleUpdateLeaderboards = async () => {
		try {
			const res = await updateLeaderboard();
			console.log("Leaderboards updated:", res.data);
		} catch (error) {
			alert("Error updating leaderboards:" + error);
		}
	};

	const handleUpdateTeamResults = async () => {
		try {
			const res = await setTeamResult();
			console.log("Team results updated:", res.data);
		} catch (error) {
			alert("Error updating team results:" + error);
		}
	};

	const handleUpdateGetCorkedLeague = async () => {
		try {
			const res = await updateLeaderbordById("6647d4f6127004bc76e30035");
			console.log("Get Corked League updated:", res.data);
		} catch (error) {
			alert("Error updating Get Corked League:" + error);
		}
	};

	//Removes duplicate leagues from teams
	const handleRemoveDuplicateLeagues = async () => {
		try {
			const res = await RemoveDuplicateLeagues();
			console.log("Duplicate Leagues removed:", res.data);
		} catch (error) {
			alert("Error removing duplicate leagues:" + error);
		}
	};

	//Removes duplciate Teams from leagues
	const handleRemoveDuplicateTeams = async () => {
		try {
			const res = await RemoveDuplicateTeams();
			console.log("Duplicate Teams removed:", res.data);
		} catch (error) {
			alert("Error removing duplicate teams:" + error);
		}
	};

	const handleRemoveDuplicateOwner = async () => {
		try {
			const res = await RemoveDuplicatesOwner();
			console.log("Duplicate Owners removed:", res.data);
		} catch (error) {
			alert("Error removing duplicate owners:" + error);
		}
	};

	const handleGameModeAssignmentSubmit = (e) => {
		e.preventDefault();

		try {
			const res = createGameModeAssignment(assignmentFormData);
			if (res) {
				fetchGameModeAssignments();
			}
		} catch (error) {
			console.error("Error creating game mode assignment:", error);
		}
	};

	const handleUploadRiders = async () => {
		try {
			const res = await uploadRiders();
			console.log("Riders uploaded:", res.data);
		} catch (error) {
			console.error("Error uploading riders:", error);
		}
	};

	const handleInactiveRiders = async () => {
		try {
			const res = await makeRidersIneligible();
			console.log("Riders made ineligible:", res.data);
		} catch (error) {
			console.error("Error making riders ineligible:", error);
		}
	};

	const handleDeleteAllRiders = async () => {
		try {
			const res = await deleteAllRiders();
			console.log("All riders deleted:", res.data);
		} catch (error) {
			console.error("Error deleting all riders:", error);
		}
	};

	const handleAssignmentChange = (e) => {
		const { name, value } = e.target;

		setAssignmentFormData({
			...assignmentFormData,
			[name]: value,
		});

		console.log(assignmentFormData);
	};

	return (
		<Container className="bg-light p-4">
			<Row>
				<Col>
					<Button variant="primary" onClick={() => setShowSidebar(true)}>
						<RxHamburgerMenu />
					</Button>
					<AdminOffCanvasSidebar
						show={showSidebar}
						handleClose={() => setShowSidebar(false)}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Card className="mt-4 border-0 shadow">
						<Card.Body>
							<Card.Title>Admin Page</Card.Title>
							<Card.Text>
								Welcome to the admin page. Here you can create, edit, and delete
								leagues, sports, and series.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<div className="d-flex flex-row justify-content-center my-3">
				<Button variant="primary" onClick={handleUploadRiders} className="me-2">
					Upload Riders
				</Button>
				<Button variant="outline-primary" onClick={handleInactiveRiders} className="me-2">
					Make All Crankworx Rideres Ineligible
				</Button>
				<Button
					variant="outline-danger"
					className="ml-3"
					onClick={handleDeleteAllRiders}
					disabled
				>
					Delete All Riders
				</Button>
			</div>

			<Card className="mt-4 border-0 shadow">
				<Card.Body>
					<Card.Title>Update Leaderboards</Card.Title>
					<Button variant="success" onClick={handleUpdateLeaderboards}>
						Update Leaderboards
					</Button>
					<Button
						variant="outline-success"
						className="ms-2"
						onClick={handleUpdateTeamResults}
					>
						{" "}
						Update Team Results
					</Button>
					<Button
						variant="outline-success"
						className="ms-2"
						onClick={handleUpdateGetCorkedLeague}
					>
						UPdate Get Corked League.
					</Button>

					<Button
						className="ms-2"
						variant="outline-success"
						onClick={handleRemoveDuplicateLeagues}
					>
						{" "}
						Remove Duplicate Leagues{" "}
					</Button>
					<Button
						className="ms-2"
						variant="outline-success"
						onClick={handleRemoveDuplicateTeams}
					>
						{" "}
						Remove Duplicate Teams{" "}
					</Button>
					<Button
						className="ms-2"
						variant="outline-success"
						onClick={handleRemoveDuplicateOwner}
					>
						{" "}
						Remove Duplicate Owners{" "}
					</Button>
				</Card.Body>
			</Card>
			{/* list all game modes here */}
			<Row>
				<Col>
					<Card className="mt-4 border-0 shadow">
						<Card.Body>
							<Card.Title>Game Modes</Card.Title>
							<ListGroup>
								{gameModes.map((gameMode) => (
									<ListGroup.Item key={gameMode._id}>
										<b>{gameMode.name}</b> - {gameMode.description}
									</ListGroup.Item>
								))}
							</ListGroup>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<Row>
				<Col>
					<Card className="mt-4 border-0 shadow">
						<Card.Body>
							<Form onSubmit={handleSubmit}>
								<Form.Group controlId="formName">
									<Form.Label>Name</Form.Label>
									<Form.Control
										type="text"
										placeholder="Enter name"
										name="name"
										value={formData.name}
										onChange={handleChange}
									/>
								</Form.Group>

								<Form.Group controlId="formDescription">
									<Form.Label>Description</Form.Label>
									<Form.Control
										as="textarea"
										rows={3}
										name="description"
										placeholder="Enter description"
										value={formData.description}
										onChange={handleChange}
									/>
								</Form.Group>

								<Form.Group controlId="formNumMen">
									<Form.Label>Number of Men</Form.Label>
									<Form.Control
										type="number"
										name="numberOfMen"
										placeholder="Enter number of men"
										value={formData.numberOfMen}
										onChange={handleChange}
									/>
								</Form.Group>

								<Form.Group controlId="formNumWomen">
									<Form.Label>Number of Women</Form.Label>
									<Form.Control
										type="number"
										name="numberOfWomen"
										placeholder="Enter number of women"
										value={formData.numberOfWomen}
										onChange={handleChange}
									/>
								</Form.Group>

								<Form.Group controlId="formValue">
									<Form.Label>Value</Form.Label>
									<Form.Control
										type="number"
										name="value"
										placeholder="Enter value"
										value={formData.value}
										onChange={handleChange}
									/>
								</Form.Group>

								<Button variant="primary" type="submit" className="mt-4">
									Create Game Mode
								</Button>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			{/* Display all game mode assignemnts:  */}
			<Row>
				<Col>
					<Card className="mt-4 border-0 shadow">
						<Card.Body>
							<Card.Title>Game Mode Assignments</Card.Title>
							<ListGroup>
								{gameModeAssignments.map((assignment) => (
									<ListGroup.Item key={assignment._id}>
										{assignment.sport.name} - {assignment.series.name} -{" "}
										{assignment.gameMode.name}
									</ListGroup.Item>
								))}
							</ListGroup>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			{/* Create game mode assignments. Want to list all sports, all series, and all game modes in dropdowns. Then a button create */}
			<Row>
				<Col>
					<Card className="mt-4 border-0 shadow">
						<Card.Body>
							<Card.Title>Create Game Mode Assignments</Card.Title>
							<Form onSubmit={handleGameModeAssignmentSubmit}>
								<Form.Group controlId="formSports">
									<Form.Label>Sports</Form.Label>
									<Form.Control
										as="select"
										value={assignmentFormData.sport}
										name="sport"
										onChange={handleAssignmentChange}
									>
										<option value="">Select a sport</option>
										{sportsDropdown.map((sport) => (
											<option key={sport._id} value={sport._id}>
												{sport.name}
											</option>
										))}
									</Form.Control>
								</Form.Group>

								<Form.Group controlId="formSeries">
									<Form.Label>Series</Form.Label>
									<Form.Control
										as="select"
										value={assignmentFormData.series}
										name="series"
										onChange={handleAssignmentChange}
									>
										<option value="">Select a series</option>
										{seriesDropdown.map((series) => (
											<option key={series._id} value={series._id}>
												{series.name}
											</option>
										))}
									</Form.Control>
								</Form.Group>

								<Form.Group controlId="formGames">
									<Form.Label>Games</Form.Label>
									<Form.Control
										as="select"
										value={assignmentFormData.gameMode}
										name="gameMode"
										onChange={handleAssignmentChange}
									>
										<option value="">Select a game</option>
										{gamesDropdown.map((game) => (
											<option key={game._id} value={game._id}>
												{game.name}
											</option>
										))}
									</Form.Control>
								</Form.Group>

								<Button variant="primary" type="submit" className="mt-4">
									Create Game Mode Assignment
								</Button>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<Modal></Modal>
		</Container>
	);
};

export default AdminPage;
