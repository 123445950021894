import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { FaGamepad, FaRegHandPointRight, FaTimes } from "react-icons/fa";
import {
	getGameModeAssignments,
	getGameModes,
} from "../../../services/adminService";
import { LuDot } from "react-icons/lu";
import { TbMathFunction } from "react-icons/tb";
import { LiaTimesSolid } from "react-icons/lia";

const Rules = () => {
	const [gameModes, setGameModes] = useState([]);
	const [gameModeAssignments, setGameModeAssignments] = useState([]);
	const [pickAndRankMode, setPickAndRankMode] = useState({});
	const [multiplierMode, setMultiplierMode] = useState({});
	const [budgetMode, setBudgetMode] = useState({});

	useEffect(() => {
		fetchGameModes();
		fetchGameModeAssignments();
	}, []);

	useEffect(() => {
		if (gameModes.length > 0) {
			const pickAndRankMode = gameModes.find(
				(mode) =>
					mode.name.toLowerCase() === "pick and rank" ||
					mode.name.toLowerCase() === "pick and rank hardline",
			);
			const multiplierMode = gameModes.find(
				(mode) => mode.name.toLowerCase() === "multiplier mode",
			);
			const budgetMode = gameModes.find(
				(mode) => mode.name.toLowerCase() === "budget mode",
			);
		}
	}, [gameModes]);

	const fetchGameModes = async () => {
		try {
			const res = await getGameModes();
			setGameModes(res.data);
		} catch (error) {
			console.error("Error fetching game modes:", error);
		}
	};

	const fetchGameModeAssignments = async () => {
		try {
			const res = await getGameModeAssignments();
			setGameModeAssignments(res.data);
		} catch (error) {
			console.error("Error fetching game mode assignments:", error);
		}
	};

	return (
		<div>
			<Card className="mb-3 shadow">
				<Card.Header>
					<FaGamepad className="me-2 mb-1" />
					Multiplier Mode
				</Card.Header>
				<Card.Body style={{ fontSize: "0.8rem" }}>
					<Card.Text>
						<FaRegHandPointRight className="me-2" />
						Build your dream team by selecting #___ Men, and #___ Women.
					</Card.Text>
					<Card.Text>
						<FaRegHandPointRight className="me-2" />
						Your points are awarded by points scored by the athlete at the event
						multiplied by the athletes assigned multiplier value.
					</Card.Text>
					<Card.Text className="fst-italic">
						<FaRegHandPointRight className="me-2 " />
						Rider's Points
						<LiaTimesSolid className="mx-1" /> Multiplier (
						<TbMathFunction className="" />) = Your Score!)
					</Card.Text>
				</Card.Body>
				<Card.Footer style={{ fontSize: "0.8rem" }}>
					{gameModeAssignments.map((assignment) => (
						<div key={assignment._id}>
							{assignment.gameMode.name.toLowerCase() === "multiplier mode" && (
								<div>
									{assignment.series.name} <LuDot /> {assignment.sport.name}
								</div>
							)}
						</div>
					))}
				</Card.Footer>
			</Card>

			<Card className="mb-3 shadow">
				<Card.Header>
					<FaGamepad className="me-2 mb-1" />
					Pick and Rank Mode
				</Card.Header>
				<Card.Body style={{ fontSize: "0.8rem" }}>
					<Card.Text>
						<FaRegHandPointRight className="me-2" />
						You must select #__ riders for your team. (Varies by series)
					</Card.Text>
					<Card.Text>
						<FaRegHandPointRight className="me-2" />
						You must rank your riders based on the position they will finish
						(1st, 2nd, 3rd, etc.)
					</Card.Text>
					<Card.Text>
						<FaRegHandPointRight className="me-2" />
						+100 points for each selected rider that finishes in the top #___
						for men and top #___ for women. (Varies by series)
					</Card.Text>
					<Card.Text>
						<FaRegHandPointRight className="me-2" />
						+100 points for every podium spot guessed correctly.
					</Card.Text>
				</Card.Body>
				<Card.Footer style={{ fontSize: "0.8rem" }}>
					{gameModeAssignments.map((assignment) => (
						<div key={assignment._id}>
							{(assignment.gameMode.name.toLowerCase() === "pick and rank" ||
								assignment.gameMode.name.toLowerCase() ===
									"pick and rank hardline") && (
								<div>
									{assignment.series.name} <LuDot /> {assignment.sport.name}
								</div>
							)}
						</div>
					))}
				</Card.Footer>
			</Card>

			<Card className="mb-3 shadow">
				<Card.Header>
					<FaGamepad className="me-2 mb-1" />
					Budget Mode
				</Card.Header>
				<Card.Body style={{ fontSize: "0.8rem" }}>
					<Card.Text>
						<FaRegHandPointRight className="me-2" />
						Choose a combination of #___ Men and #___ Women within the assigned
						budget of $____.
					</Card.Text>
					<Card.Text>
						<FaRegHandPointRight className="me-2" />
						Build your dream team: Maximise potential while staying within
						budget constraints. You’re a team manager now playing for prizes and
						bragging rights so, take it seriously!
					</Card.Text>
					<Card.Text>
						<FaRegHandPointRight className="me-2" />
						Rack up points based on your rider's performance during the event.
						The less the rider costs, the more points you’ll earn!
					</Card.Text>
				</Card.Body>
			</Card>
		</div>
	);
};

export default Rules;
