import axios from 'axios';
import apiInstance from './api';

const API_URL = process.env.REACT_APP_API_URL;

export const getUser = async () => {
	return apiInstance.get(`/users/user`);
};

export const updateUser = async (id, userData) => {
	return apiInstance.put(`/users/${id}`, userData);
};

export const resetPassword = async (id) => {
	return apiInstance.post(`/users/resetPassword`, { userId: id });
};

export const deleteUser = async (id) => {
	return apiInstance.delete(`/users/${id}`);
};

export const getUsers = async () => {
	return apiInstance.get(`/users`);
};

export const getUserByEmail = async (email) => {
	return apiInstance.get(`/users/email/${email}`);
};
