import apiInstance from "./api";

export const getGameModes = async () => {
	return apiInstance.get("/gameModes");
};

export const createGameMode = async (gameModeData) => {
	return apiInstance.post("/gameModes", gameModeData);
};

export const updateGameMode = async (gameModeData) => {
	return apiInstance.put(`/gameModes/${gameModeData._id}`, gameModeData);
};

export const deleteGameMode = async (gameModeId) => {
	return apiInstance.delete(`/gameModes/${gameModeId}`);
};

export const getGameModeAssignments = async () => {
	return apiInstance.get("/gameModes/assignments");
};

export const getGameModeAssignment = async (params) => {
	return apiInstance.get(`/gameModes/assignments`, { params });
};

export const createGameModeAssignment = async (assignmentData) => {
	return apiInstance.post("/gameModes/assignments", assignmentData);
};

export const updateGameModeAssignment = async (assignmentData) => {
	return apiInstance.put(
		`/gameModes/assignments/${assignmentData._id}`,
		assignmentData,
	);
};

export const deleteGameModeAssignment = async (assignmentId) => {
	return apiInstance.delete(`/gameModes/assignments/${assignmentId}`);
};

export const uploadRiders = async () => {
	return apiInstance.post("/uploadRiders");
};

export const deleteAllRiders = async () => {
	return apiInstance.delete("/uploadRiders");
};


export const RemoveDuplicateTeams = async () => {
	return apiInstance.put("/teams/removeDuplicates/Teams");
};

export const RemoveDuplicateLeagues = async () => {
	return apiInstance.put("/teams/removeDuplicates/Leagues");
};

export const RemoveDuplicatesOwner = async () => {
	return apiInstance.put("/teams/removeDuplicates/Owner");
};
