import axios from "axios";
import apiInstance from "./api";

const API_URL = process.env.REACT_APP_API_URL;

export const getEvents = async (params) => {
	return apiInstance.get(`/events`, { params });
};

export const getEvent = async (eventId) => {
	return apiInstance.get(`/events/${eventId}`);
};

export const createEvent = async (eventData) => {
	return apiInstance.post(`/events`, eventData);
};

export const updateEvent = async (eventId, eventData) => {
	return apiInstance.put(`/events/${eventId}`, eventData);
};

export const deleteEvent = async (eventId) => {
	return apiInstance.delete(`/events/${eventId}`);
};

export const uploadImage = async (eventId, image) => {
	const formData = new FormData();
	formData.append("eventImage", image);
	return apiInstance.put(`/events/${eventId}/upload/image`, formData);
};

export const uploadTrackImage = async (eventId, image) => {
	const formData = new FormData();
	formData.append("trackImage", image);
	return apiInstance.put(`/events/${eventId}/upload/track`, formData);
};
