import React from 'react';
// If Bootstrap is not already imported in your project, include it
// import 'bootstrap/dist/css/bootstrap.min.css';

const TermsComponent = () => {
	return (
		<div className='container' style={{ padding: '20px' }}>
			<div style={{ fontSize: '0.7rem' }}>
				<p>
					<span style={{ fontWeight: 'bold' }}>IMPORTANT</span>
					<br />
					Please carefully read these Terms of Use for The Race Companion, as
					they affect your legal rights and obligations for use.
				</p>

				<p>
					<span style={{ fontWeight: 'bold' }}>Agreement</span>
					<br />
					The Race Companion, part of TRC Media House, operates the Website that
					links to these Terms of Use. We offer you access to our Website and
					related apps, along with the ability to participate in our contests,
					content, products, services, and promotions (collectively the
					"Services") that we may provide from our Website or related apps,
					subject to these Terms of Use (the "Terms of Use", or “Terms”), our
					privacy policy (the "Privacy Policy") and the Official Rules and
					Regulations for the applicable contests and promotions (the "Rules" or
					"Rules and Scoring," and together with the Terms of Use and the
					Privacy Policy, the "Agreements"). By using The Race Companion and its
					services, you agree to these Terms of Use. By accessing this website
					we assume you accept these terms and conditions. Do not continue to
					use The Race Companion if you do not agree to take all of the terms
					and conditions stated on this page.
				</p>

				{/* Following the structure above, continue adding sections of your Terms and Conditions */}

				<p>
					<span style={{ fontWeight: 'bold' }}>Eligibility</span>
					<br />
					You must be at least eighteen (18) years of age or older to create an
					account, participate in contests, and win prizes offered by The Race
					Companion. The Race Companion is not affiliated with or sponsored by
					the Union Cycliste Internationale (UCI).
				</p>

				<p>
					<span style={{ fontWeight: 'bold' }}>Prizes</span>
					<br />
					In addition to the items outlined in the "Eligibility" section of this
					agreement, the following terms are to be considered for prizes.
				</p>

				{/* Inserting a short placeholder for brevity */}
				<p>
					<p>
						<span style={{ fontWeight: 'bold' }}>International Winners</span>
						<br />
						The Race Companion will do its utmost to ship prizes Internationally
						as efficiently as possible within 14 days of the events completion.
						This does not apply to the overall grand prize due to production
						time. The Race Companion will liaise with the winner to arrange
						shipping and delivery of the prize.
					</p>

					<p>
						<span style={{ fontWeight: 'bold' }}>
							Conditions of Participation
						</span>
						<br />
						<span style={{ fontWeight: 'bold' }}>Registration</span>: By
						registering as a user of The Race Companion and creating your
						account, you agree to provide accurate and complete information
						about yourself as prompted by the service. By providing inaccurate
						information, The Race Companion reserves the right to deny you the
						ability to participate in its services.
					</p>

					<p>
						<span style={{ fontWeight: 'bold' }}>Account and Security</span>
						<br />
						You are responsible for creating and remembering a secure password
						for your account. Furthermore, you are responsible for the uses of
						your username and password. Max 2 accounts. Merging of accounts is
						up to the discretion of site administrators and must not be used to
						create an unfair advantage in the game. Merging accounts where event
						results will be overridden will not be allowed (as to not use
						multiple accounts and combine the better scores from each).
					</p>

					<p>
						<span style={{ fontWeight: 'bold' }}>Disqualification</span>
						<br />
						The Race Companion reserves the right to cancel contests at any time
						without restrictions. The Race Companion may disqualify you at any
						time if it is found, in good faith, that you are to be in violation
						of these Terms of Use or participating in ways to give yourself an
						unfair or fraudulent advantage. To summarise, don’t be a dick.
						Examples of actions that could result in disqualification from
						contests and/or termination of your account include, but are not
						limited to: accumulating points through unauthorized methods such as
						bots and/or scripts, false personal information (including payment
						information), tampering with administration services of The Race
						Companion or any of the services required to operate The Race
						Companion service, spam. If The Race Companion services are not
						operating normally due to unauthorized intervention, The Race
						Companion reserves the right to disqualify any and all individuals
						implicated in the cause of abnormal operations, and in some cases
						may result in legal action.
					</p>

					<p>
						<span style={{ fontWeight: 'bold' }}>Cookies</span>
						<br />
						We employ the use of cookies. By accessing The Race Companion, you
						agreed to use cookies in agreement with the TRC Media House's
						Privacy Policy. Most interactive websites use cookies to let us
						retrieve the user's details for each visit. Cookies are used by our
						website to enable the functionality of certain areas to make it
						easier for people visiting our website. Some of our
						affiliate/advertising partners may also use cookies.
					</p>
				</p>

				<p>
					<span style={{ fontWeight: 'bold' }}>Payment</span>
					<br />
					By providing payment information to enter paid contests, you agree to
					provide a valid mailing address, date of birth, and any other
					information we may require in order to verify your eligibility.
					Currently, this is not a service offered by The Race Companion.
				</p>

				<p>
					<span style={{ fontWeight: 'bold' }}>A Game of Skill & Strategy</span>
					<br />
					The Race Companion is a game of skill and strategy, not luck. Points
					are accumulated according to the rules set by The Race Companion and
					its relevant services. The Race Companion is not to be considered
					gambling as it does not rely on luck for success.
				</p>

				<p>
					<span style={{ fontWeight: 'bold' }}>Conduct</span>
					<br />
					As a condition of use, you agree not to use The Race Companion for any
					purpose that is unlawful or is not in accordance with these Terms of
					Use. Examples of improper conduct and use of The Race Companion
					services include, but are not limited to: abuse, harassment,
					impersonation, intimidation or threatening of other users, submission
					or transportation of material that could infringe upon normal
					operation of The Race Companion services, submission or transportation
					of pornographic, offensive, defamatory or obscene material, submission
					of affiliate programs, selling your account for any reason. Violation
					of these rules, or any action that could be considered harmful to The
					Race Companion and its services or users, may result in the
					termination of your account and eligibility for any
				</p>
				<p>
					<span style={{ fontWeight: 'bold' }}>Refunds</span>
					<br />
					The Race Companion has a no refund policy. All submitted payments are
					final.
				</p>

				<p>
					<span style={{ fontWeight: 'bold' }}>Licence</span>
					<br />
					Unless otherwise stated, TRC Media House and/or its licensors own the
					intellectual property rights for all material on The Race Companion.
					All intellectual property rights are reserved. You may access this
					from The Race Companion for your own personal use subjected to
					restrictions set in these terms and conditions. You must not:
					Republish material from The Race Companion; Sell, rent or sub-license
					material from The Race Companion; Reproduce, duplicate or copy
					material from The Race Companion; Redistribute content from The Race
					Companion. This Agreement shall begin on the date hereof.
				</p>

				<p>
					Parts of this website offer an opportunity for users to post and
					exchange opinions and information in certain areas of the website. TRC
					Media House does not filter, edit, publish, or review Comments prior
					to their presence on the website. Comments do not reflect the views
					and opinions of TRC Media House, its agents, and/or affiliates.
					Comments reflect the views and opinions of the person who post their
					views and opinions. To the extent permitted by applicable laws, TRC
					Media House shall not be liable for the Comments or for any liability,
					damages, or expenses caused and/or suffered as a result of any use of
					and/or posting of and/or appearance of the Comments on this website.
				</p>

				<p>
					TRC Media House reserves the right to monitor all Comments and to
					remove any Comments which can be considered inappropriate, offensive,
					or causes breach of these Terms and Conditions. You warrant and
					represent that: You are entitled to post the Comments on our website
					and have all necessary licenses and consents to do so; The Comments do
					not invade any intellectual property right, including without
					limitation copyright, patent, or trademark of any third party; The
					Comments do not contain any defamatory, libellous, offensive,
					indecent, or otherwise unlawful material which is an invasion of
					privacy; The Comments will not be used to solicit or promote business
					or custom or present commercial activities or unlawful activity. You
					hereby grant TRC Media House a non-exclusive licence to use,
					reproduce, edit, and authorise others to use, reproduce, and edit any
					of your Comments in any and all forms, formats, or media.
				</p>

				<p>
					<span style={{ fontWeight: 'bold' }}>
						Hyperlinking to our Content
					</span>
					<br />
					The following organizations may link to our Website without prior
					written approval: Government agencies; Search engines; News
					organizations; Online directory distributors may link to our Website
					in the same manner as they hyperlink to the Websites of other listed
					businesses; and Systemwide Accredited Businesses except soliciting
					non-profit organizations, charity shopping malls, and charity
					fundraising groups which may not hyperlink to our Web site.
				</p>

				<p>
					These organizations may link to our home page, to publications, or to
					other Website information so long as the link: (a) is not in any way
					deceptive; (b) does not falsely imply sponsorship, endorsement, or
					approval of the linking party and its products and/or services; and
					(c) fits within the context of the linking party’s site.
				</p>

				<p>
					We may consider and approve other link requests from the following
					types of organizations: commonly-known consumer and/or business
					information sources; dot.com community sites; associations or other
					groups representing charities; online directory distributors; internet
					portals; accounting, law, and consulting firms; and educational
					institutions and trade associations. We will approve link requests
					from these organizations if we decide that: (a) the link would not
					make us look unfavorably to ourselves or to our accredited businesses;
					(b) the organization does not have any negative records with us; (c)
					the benefit to us from the visibility of the hyperlink compensates the
					absence of TRC Media House; and (d) the link is in the context of
					general resource information.
				</p>

				<p>
					These organizations may link to our home page so long as the link: (a)
					is not in any way deceptive; (b) does not falsely imply sponsorship,
					endorsement, or approval of the linking party and its products or
					services; and (c) fits within the context of the linking party's site.
					If you are one of the organizations listed in paragraph 2 above and
					are interested in linking to our website, you must inform us by
					sending an e-mail to TRC Media House. Please include your name, your
					organization name, contact information as well as the URL of your
					site, a list of any URLs from which you intend to link to our Website,
					and a list of the URLs on our site to which you would like to link.
					Wait 2-3 weeks for a response.
				</p>

				<p>
					Approved organizations may hyperlink to our Website as follows: By use
					of our corporate name; or By use of the uniform resource locator being
					linked to; or By use of any other description of our Website being
					linked to that makes sense within the context and format of content on
					the linking party’s site. No use of TRC Media House’s logo or other
					artwork will be allowed for linking absent a trademark license
					agreement.
				</p>
			</div>
		</div>
	);
};

export default TermsComponent;
