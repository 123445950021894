import React from 'react';
import { Offcanvas, ListGroup } from 'react-bootstrap';
import { useHistory, useNavigate } from 'react-router-dom';
import {
	FaTachometerAlt,
	FaAd,
	FaCalendarAlt,
	FaUserFriends,
	FaBiking,
	FaPoll,
	FaImage,
	FaCamera,
	FaFutbol,
	FaUsers,
	FaVideo,
	FaLockOpen,
} from 'react-icons/fa';

const AdminOffCanvasSidebar = ({ show, handleClose }) => {
	const navigate = useNavigate();

	return (
		<Offcanvas show={show} onHide={handleClose}>
			<Offcanvas.Header closeButton>
				<Offcanvas.Title>Admin Menu</Offcanvas.Title>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<ListGroup variant='flush'>
					<ListGroup.Item action onClick={() => navigate('/admin')}>
						<FaTachometerAlt /> Dashboard
					</ListGroup.Item>
					<ListGroup.Item action onClick={() => navigate('/admin/admanager')}>
						<FaAd /> Ad Manager
					</ListGroup.Item>
					<ListGroup.Item action onClick={() => navigate('/admin/disable')}>
						<FaLockOpen /> Disable Manager
					</ListGroup.Item>
					<ListGroup.Item action onClick={() => navigate('/admin/events')}>
						<FaCalendarAlt /> Event Manager
					</ListGroup.Item>
					<ListGroup.Item action onClick={() => navigate('/admin/leagues')}>
						<FaUserFriends /> League Manager
					</ListGroup.Item>
					<ListGroup.Item action onClick={() => navigate('/admin/riders')}>
						<FaBiking /> Rider Manager
					</ListGroup.Item>
					<ListGroup.Item action onClick={() => navigate('/admin/results')}>
						<FaPoll /> Result Manager
					</ListGroup.Item>
					<ListGroup.Item action onClick={() => navigate('/admin/snapshots')}>
						<FaImage /> Snapshot Manager
					</ListGroup.Item>
					<ListGroup.Item action onClick={() => navigate('/admin/sports')}>
						<FaFutbol /> Sport Manager
					</ListGroup.Item>
					<ListGroup.Item action onClick={() => navigate('/admin/teams')}>
						<FaUsers /> Team Manager
					</ListGroup.Item>
					<ListGroup.Item action onClick={() => navigate('/admin/users')}>
						<FaUserFriends /> User Manager
					</ListGroup.Item>
					<ListGroup.Item action onClick={() => navigate('/admin/watch')}>
						<FaCamera /> Watch Manager
					</ListGroup.Item>
					<ListGroup.Item action onClick={() => navigate('/admin/videos')}>
						<FaVideo /> Video Manager
					</ListGroup.Item>
				</ListGroup>
			</Offcanvas.Body>
		</Offcanvas>
	);
};

export default AdminOffCanvasSidebar;
