import React, { useEffect, useState } from "react";
import {
	Container,
	Row,
	Col,
	Form,
	Button,
	Alert,
	Modal,
	ProgressBar,
} from "react-bootstrap";
import { GoogleLogin } from "@react-oauth/google"; // Ensure you have the necessary setup for Google OAuth
import { Link, useNavigate } from "react-router-dom";
import { getUserByEmail } from "../../services/userService";
import { jwtDecode } from "jwt-decode";
import fantasy_logo from "../../assets/TRC_fantasy_white.png";
import { signUp } from "../../services/mailChimpService";
import { Helmet } from "react-helmet";
import { FaGoogle, FaInstagram } from "react-icons/fa";
import { CgBrowser } from "react-icons/cg";
import { LuDot } from "react-icons/lu";
import { BsThreeDots } from "react-icons/bs";

const LoginPage = ({ handleSignIn, handleSignUp, handleGoogleSignIn }) => {
	const navigate = useNavigate();

	const [isSignIn, setIsSignIn] = useState(true); // Toggle between Sign In and Sign Up
	const [username, setUsername] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [agreeToPrivacyPolicy, setAgreeToPrivacyPolicy] = useState(false);
	const [confirmAge, setConfirmAge] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [googleCredential, setGoogleCredential] = useState(null);
	const [showTermsModal, setShowTermsModal] = useState(false);
	const [acceptEmails, setAcceptEmails] = useState(false);
	const [showInstagramModal, setShowInstagramModal] = useState(false);
	const [isInstagramBrowser, setIsInstagramBrowser] = useState(false);
	const [showInvalidCredentials, setShowInvalidCredentials] = useState(false);
	const [step, setStep] = useState(1);
	const [formData, setFormData] = useState({
		email: "",
		password: "",
		confirmPassword: "",
		username: "",
	});

	useEffect(() => {
		const isInstagram = isFacebookOrInstagramBrowser();
		setIsInstagramBrowser(isInstagram);
		if (isInstagram) {
			setShowInstagramModal(true);
		}
	}, []);

	const isFacebookOrInstagramBrowser = () => {
		const userAgent = navigator.userAgent || navigator.vendor;
		return (
			/FBAN/.test(userAgent) ||
			/FBAV/.test(userAgent) ||
			/Instagram/.test(userAgent)
		);
	};

	const updateField = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const nextStep = () => {
		if (step === 1 && email.split("@")[1] === "gmail.com") {
			alert("Please use Google Sign In for Gmail accounts.");
			return;
		}
		if (step === 1 && !email) {
			alert("Please enter your email.");
			return;
		}
		if (
			(step === 2 && !password) ||
			(step === 2 && password !== confirmPassword)
		) {
			alert("Passwords do not matc");
			return;
		}
		if (step === 3 && !username) {
			alert("Please enter your username.");
			return;
		}
		const next = step >= 4 ? 4 : step + 1;
		setStep(next);
	};

	const prevStep = () => {
		const prev = step <= 1 ? 1 : step - 1;
		setStep(prev);
	};

	const handleGoogleSignInWithRedirect = async (credentialResponse) => {
		try {
			const userData = extractUserDataFromResponse(credentialResponse);
			const userExists = await checkIfUserExists(userData.email);

			if (acceptEmails) {
				const response = await signUp({ email: userData.email });
			}

			if (!userExists && !validateCheckboxes()) {
				setShowTermsModal(true);
				setGoogleCredential(credentialResponse);
			} else {
				await finalizeGoogleSignIn(credentialResponse);
			}
		} catch (error) {
			console.error("Error signing in with Google:", error);
		}
	};

	const checkIfUserExists = async (email) => {
		try {
			const res = await getUserByEmail(email);
			if (res.status === 200) {
				if (res.data) {
					return true;
				} else {
					return false;
				}
			}
		} catch (error) {
			console.error("Error checking if user exists:", error);
			return false;
		}

		return false;
	};

	const extractUserDataFromResponse = (credentialResponse) => {
		const decodedToken = jwtDecode(credentialResponse.credential);
		const userData = {
			username: decodedToken.name, // The JWT standard claims might be different; adjust accordingly
			email: decodedToken.email,
			profilePic: decodedToken.picture, // This might vary based on the token's structure
		};
		return userData;
	};

	const finalizeGoogleSignIn = async (credential) => {
		try {
			await handleGoogleSignIn(credential);
			const location = localStorage.getItem("location");
			if (location && location !== "/login") {
				localStorage.removeItem("location");
				navigate(location);
			} else {
				navigate("/");
			}
		} catch (error) {
			console.error("Error signing in with Google:", error);
			alert("An error occurred while signing in with Google." + error);
		}
	};

	const isReservedEmailDomain = (email) => {
		const domain = email.split("@")[1];
		return domain === "gmail.com"; // Add other domains as necessary
	};

	const validateCheckboxes = () => {
		if (!agreeToPrivacyPolicy || !confirmAge) {
			setShowAlert(true);
			return false;
		}
		return true;
	};

	const validateFields = () => {
		if (!email || !password) {
			alert("Please fill in all fields.");
			return false;
		}
		return true;
	};

	const validateCreateFields = () => {
		if (!username || !email || !password || !confirmPassword) {
			alert("Please fill in all fields.");
			return false;
		}
		return true;
	};

	const handleSignInWithRedirect = async (e) => {
		e.preventDefault();
		setShowInvalidCredentials(false);
		try {
			if (isReservedEmailDomain(email)) {
				alert("Please use Google Sign In for Gmail accounts.");
				return;
			}

			if (!validateFields()) {
				return;
			}

			const res = await handleSignIn(email, password);
			if (!res) {
				setShowInvalidCredentials(true);
				return;
			}
			const location = localStorage.getItem("location");
			if (location && location !== "/login") {
				localStorage.removeItem("location");
				navigate(location);
			} else {
				navigate("/");
			}
		} catch (error) {
			console.error("Error signing in:", error);
			alert("An error occurred while signing in." + error);
		}
	};

	const handleSignUpWithRedirect = async (e) => {
		e.preventDefault();
		try {
			if (!validateCheckboxes()) {
				return;
			}

			const res = await handleSignUp(username, email, password);
			if (!res) {
				alert(
					"An error occurred while signing up. Please ensure Username and email are unique. ",
				);
				return;
			}

			if (acceptEmails) {
				const response = await signUp({ email: email });
			}
			const location = localStorage.getItem("location");
			if (location && location !== "/login") {
				localStorage.removeItem("location");
				navigate(location);
			} else {
				navigate("/");
			}
		} catch (error) {
			console.error("Error signing up:", error);
			alert("An error occurred while signing up." + error);
		}
	};

	// Toggle function
	const toggleForm = () => setIsSignIn(!isSignIn);

	return (
		<Container className="pt-4">
			<Helmet>
				<title>Login - The Race Companion</title>
				<meta
					name="description"
					content="Sign up for the Race Companion. Follow along with Crankworx, UCI Downhill & Enduro, Red Bull Hardline and Red Bull Rampage."
				/>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Helmet>
			<Row className="justify-content-md-center pb-5">
				<Col md={5}>
					<div className="d-flex justify-content-center mb-4">
						<img
							src={fantasy_logo}
							alt="TheRaceCompanion"
							style={{ width: "20rem", height: "auto" }}
						/>
					</div>
					<h1 className="text-center">
						{isSignIn ? "Sign In" : "Create Account"}
					</h1>
					<Form
						onSubmit={
							isSignIn ? handleSignInWithRedirect : handleSignUpWithRedirect
						}
					>
						{isSignIn ? (
							<>
								<Form.Group controlId="email" className="mb-3">
									<Form.Label>Email address</Form.Label>
									<Form.Control
										type="email"
										placeholder="Enter email"
										onChange={(e) => setEmail(e.target.value)}
									/>
								</Form.Group>
								<Form.Group controlId="password" className="mb-3">
									<Form.Label>Password</Form.Label>
									<Form.Control
										type="password"
										placeholder="Password"
										onChange={(e) => setPassword(e.target.value)}
									/>
								</Form.Group>
							</>
						) : (
							<>
								<Row className="d-flex justify-content-center">
									<Col sm={10} className="">
										<ProgressBar now={(step - 1) * 33.33} />
										{step === 1 && (
											<Form.Group>
												<Form.Label>Email</Form.Label>
												<Form.Control
													type="email"
													name="email"
													value={email}
													onChange={(e) => setEmail(e.target.value)}
												/>
											</Form.Group>
										)}
										{step === 2 && (
											<>
												<Form.Group>
													<Form.Label>Password</Form.Label>
													<Form.Control
														type="password"
														name="password"
														value={password}
														onChange={(e) => setPassword(e.target.value)}
													/>
												</Form.Group>
												<Form.Group>
													<Form.Label>Confirm Password</Form.Label>
													<Form.Control
														type="password"
														name="confirmPassword"
														value={confirmPassword}
														onChange={(e) => setConfirmPassword(e.target.value)}
													/>
												</Form.Group>
											</>
										)}
										{step === 3 && (
											<Form.Group>
												<Form.Label>Username</Form.Label>
												<Form.Control
													type="text"
													name="username"
													value={username}
													onChange={(e) => setUsername(e.target.value)}
												/>
											</Form.Group>
										)}
										{step === 4 && (
											<div className="mt-2">
												<Form.Group controlId="privacyPolicy" className="mb-3">
													<Form.Check
														type="checkbox"
														label={
															<span>
																I agree to{" "}
																<a
																	href="/privacy-policy"
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	TheRaceCompanion privacy policy
																</a>{" "}
																and{" "}
																<a
																	href="/termsandconditions"
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	terms and conditions
																</a>
															</span>
														}
														checked={agreeToPrivacyPolicy}
														onChange={(e) =>
															setAgreeToPrivacyPolicy(e.target.checked)
														}
													/>
												</Form.Group>
												<Form.Group controlId="confirmAge" className="mb-3">
													<Form.Check
														type="checkbox"
														label="I understand to compete and win a prize in TRC fantasy, I must be at least 18 years of age."
														checked={confirmAge}
														onChange={(e) => setConfirmAge(e.target.checked)}
													/>
												</Form.Group>
												<Form.Group controlId="acceptEmails" className="mb-3">
													<Form.Check
														type="checkbox"
														label="I would like to receive emails from TheRaceCompanion for team update notifications and other news."
														checked={acceptEmails}
														onChange={(e) => setAcceptEmails(e.target.checked)}
													/>
												</Form.Group>
												{showAlert && (
													<Alert
														variant="danger"
														onClose={() => setShowAlert(false)}
														dismissible
													>
														You must agree to the privacy policy and confirm you
														are at least 18 years of age to proceed.
													</Alert>
												)}
											</div>
										)}
										<div className="d-flex justify-content-between mt-3">
											{step > 1 && (
												<Button
													variant="secondary"
													className="w-100 mx-1"
													onClick={prevStep}
												>
													Back
												</Button>
											)}
											{step < 4 && (
												<Button
													variant="primary"
													className="w-100 mx-1"
													onClick={nextStep}
												>
													Next
												</Button>
											)}
											{step === 4 && (
												<Button
													variant="success"
													className="w-100"
													disabled={!agreeToPrivacyPolicy || !confirmAge}
													onClick={handleSignUpWithRedirect}
												>
													Finish
												</Button>
											)}
										</div>
									</Col>
								</Row>
							</>
						)}
						{isSignIn && (
							<Button variant="primary" type="submit" className="mt-3 w-100">
								Sign In
							</Button>
						)}
						{showInvalidCredentials && (
							<Alert
								variant="danger"
								onClose={() => setShowAlert(false)}
								dismissible
							>
								Invalid Credentials. Pleasure ensure your email and password are
								correct. If you do not have an account, please select Create
								Account.
							</Alert>
						)}
					</Form>
					<div className="d-flex flex-row justify-content-center my-4">
						{!isInstagramBrowser ? (
							<GoogleLogin
								onSuccess={handleGoogleSignInWithRedirect}
								onError={() => {
									console.log("Login Failed");
								}}
								size="large"
								useOneTap={true}
								// auto_select={true}
							/>
						) : (
							<Button
								variant="outline-dark"
								className="ms-2"
								onClick={() => {
									setShowInstagramModal(true);
								}}
							>
								<FaGoogle className="mb-1 me-2" />
								Sign in with Google
							</Button>
						)}
					</div>
					<div className="text-center mt-3">
						{isSignIn ? "Don't have an account? " : "Already have an account? "}
						<Button variant="link" onClick={toggleForm}>
							{isSignIn ? "Create Account" : "Sign In"}
						</Button>
					</div>
					<div className="text-center">
						<Button variant="link" onClick={() => navigate("/forgotpassword")}>
							Forgot your password?
						</Button>
					</div>
				</Col>
			</Row>
			<Modal show={showTermsModal} onHide={() => setShowTermsModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Welcome to TheRaceCompanion</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group controlId="formBasicCheckbox1" className="mb-3">
							<Form.Check
								type="checkbox"
								label={
									<span>
										I agree to TheRaceCompanion's{" "}
										<Link to="/privacy-policy">privacy policy</Link> and{" "}
										<Link to="/termsandconditions">terms and conditions</Link>
									</span>
								}
								checked={agreeToPrivacyPolicy}
								onChange={(e) => setAgreeToPrivacyPolicy(e.target.checked)}
							/>
						</Form.Group>
						<Form.Group controlId="formBasicCheckbox2" className="mb-3">
							<Form.Check
								type="checkbox"
								label="I understand to compete and win a prize in fantasy, I must be at least 18 years of age."
								checked={confirmAge}
								onChange={(e) => setConfirmAge(e.target.checked)}
							/>
						</Form.Group>
						<Form.Group controlId="formBasicCheckbox3">
							<Form.Check
								type="checkbox"
								label="I would like to receive emails from TheRaceCompanion for team update notifications and other news."
								checked={acceptEmails}
								onChange={(e) => setAcceptEmails(e.target.checked)}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="primary"
						onClick={() => finalizeGoogleSignIn(googleCredential)}
					>
						Agree and Continue
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showInstagramModal}
				onHide={() => setShowInstagramModal(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<FaInstagram className="mb-1 me-2" />
						Social Browser Notice
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						<b>
							Google Login is restricted in the Facebook or Instagram browser.{" "}
						</b>{" "}
					</p>
					<p>
						<LuDot /> Select the three dots at the top of the page (
						<BsThreeDots />)
					</p>
					<p>
						<LuDot /> Open in external browser
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="dark"
						className="w-100"
						onClick={() => {
							setShowInstagramModal(false);
						}}
					>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</Container>
	);
};

export default LoginPage;
