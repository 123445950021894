import axios from "axios";
import apiInstance from "./api";

const API_URL = process.env.REACT_APP_API_URL;

export const getSeries = async () => {
	return apiInstance.get(`/series`);
}

export const getSports = async () => {
	return apiInstance.get(`/sports`);
}

export const createSeries = async (seriesData) => {
	return apiInstance.post(`/series`, seriesData);
}

export const createSport = async (sportData) => {
	return apiInstance.post(`/sports`, sportData);
}