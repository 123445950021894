import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	Button,
	Accordion,
	Card,
	Container,
	Col,
	Row,
	Modal,
	Nav,
	Tab,
	Form,
} from "react-bootstrap";
import {
	getLeague,
	joinLeague,
	leaveLeague,
	updateLeague,
} from "../../services/leagueService";
import Leaderboard from "./Components/Leaderboard";
import Team from "./Components/MyTeam";
import LeagueComments from "./Components/LeagueComments";
import { getTeam, getTeams } from "../../services/teamService";
import MyTeam from "./Components/MyTeam";
import { getEvents } from "../../services/eventService";
import HomeTopRow from "../HomePage/components/HomeTopRow";
import {
	getLeaderboard,
	updateLeaderboard,
	updateLeaderbordById,
} from "../../services/leaderboardService";
import CountdownTimer from "../Fantasy/Components/CountdownTimer";
import Prizes from "../Fantasy/Components/Prizes";
import Rules from "../Fantasy/Components/Rules";
import LeagueHome from "./Components/LeagueHome";
import { getUser } from "../../services/userService";
import { LuDot } from "react-icons/lu";
import { MdWarning } from "react-icons/md";
import { FaEdit, FaHome, FaTrophy } from "react-icons/fa";
import MobileAd from "../Ads/MobileAd";
import TopAd from "../Ads/TopAd";
import LeftAd from "../Ads/LeftAd";
import RightAd from "../Ads/RightAd";
import { Helmet } from "react-helmet";
import hardline_logo from "../../assets/Hardline_Logo.png";
import { IoGlobeOutline } from "react-icons/io5";
import { IoMdGlobe } from "react-icons/io";

const LeaguePage = () => {
	const { leagueId } = useParams();
	const navigate = useNavigate();
	const [league, setLeague] = useState(null);
	const [team, setTeam] = useState(null);
	const [userInLeague, setUserInLeague] = useState(false);
	const [showSignInModal, setShowSignInModal] = useState(false);
	const [showCreateTeam, setShowCreateTeam] = useState(false);
	const [leaveLeagueModal, setLeaveLeagueModal] = useState(false);
	const [leaderboard, setLeaderboard] = useState([]);
	const [events, setEvents] = useState([]);
	const [leagues, setLeagues] = useState([]);
	const [user, setUser] = useState(null);
	const [showEditLeagueModal, setShowEditLeagueModal] = useState(false);
	const [editLeagueData, setEditLeagueData] = useState({
		name: league?.name ? league.name : "",
		description: league?.description ? league.description : "",
		privacy: league?.privacy ? league.privacy : "public",
		prize: league?.prize ? league.prize : "",
		losing_prize: league?.losing_prize ? league.losing_prize : "",
	});
	const [isMobile, setIsMobile] = useState(false);

	const isHardline = league?.series?.name === "Red Bull Hardline";

	// Filter events occurring in the future
	const futureEvents = events.filter(
		(event) =>
			new Date(event.date) >
			new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
	);

	// Sort the future events by date in ascending order
	// @ts-ignore
	futureEvents.sort((a, b) => new Date(a.date) - new Date(b.date));

	// Get the nearest future event
	const nearestFutureEvent = futureEvents[0];

	useEffect(() => {
		fetchTeam();
		fetchLeagueData();
		fetchLeaderboard();
		fetchUser();
	}, [leagueId]);

	useEffect(() => {
		if (!league) return;
		fetchLeaderboard();
	}, [league]);

	useEffect(() => {
		if (!league) return;
		setEditLeagueData({
			name: league?.name ? league.name : "",
			description: league?.description ? league.description : "",
			privacy: league?.privacy ? league.privacy : "public",
			prize: league?.prize ? league.prize : "",
			losing_prize: league?.losing_prize ? league.losing_prize : "",
		});
	}, [league]);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		handleResize(); // Initial check
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const fetchLeagueData = async () => {
		try {
			const res = await getLeague(leagueId);
			setLeague(res.data);
			setLeagues([res.data]);
			fetchEvents(res.data);
		} catch (error) {
			console.error("Error fetching league data:", error);
		}
	};

	const fetchUser = async () => {
		try {
			const res = await getUser();
			if (res.data) {
				setUser(res.data);
			}
		} catch (error) {
			console.error("Error fetching user:", error);
		}
	};

	const fetchEvents = async (leagueInput) => {
		try {
			const res = await getEvents({
				sport: leagueInput?.sport,
				series: leagueInput?.series,
			});
			setEvents(res.data);
		} catch (error) {
			console.error("Error fetching events:", error);
		}
	};

	const fetchTeam = async () => {
		// Fetch the user's team in the league
		try {
			const params = { leagueId: leagueId };

			const res = await getTeams(params);
			if (res.data && Array.isArray(res.data) && res.data.length > 0) {
				setUserInLeague(true);
				setTeam(res.data[0]);
			}
		} catch (error) {
			setUserInLeague(false);
		}
	};

	const fetchLeaderboard = async () => {
		// Fetch the leaderboard for the league
		try {
			const res = await getLeaderboard(leagueId);
			if (res.data) {
				setLeaderboard(res.data);
				// if (res.data.teams.length != league.teams.length) {
				// 	const response = await updateLeaderbordById(leagueId);
				// 	const leaderRes = await getLeaderboard(leagueId);
				// 	setLeaderboard(leaderRes.data);
				// }
			}
		} catch (error) {
			console.error("Error fetching leaderboard:", error);
		}
	};

	const handleJoinLeague = async () => {
		try {
			const res = await joinLeague(leagueId);
			setUserInLeague(true);
			if (res.status === 200) {
				alert("You have successfully joined the league.");
			}
			const response = await updateLeaderbordById(leagueId);
			if (response.status === 200) {
				fetchLeaderboard();
			}
			fetchTeam();
		} catch (error) {
			console.error("Error joining league:", error);
			if (error.response.status === 400 || error.response.status === 401) {
				setShowSignInModal(true);
			}

			if (error.response.status === 406) {
				setShowCreateTeam(true);
			}
		}
	};

	const handleLeaveLeague = async () => {
		try {
			const res = await leaveLeague(leagueId, team?._id);
			if (res.status === 200) {
				alert("You have successfully left the league.");
				setUserInLeague(false);
			}

			const response = await updateLeaderbordById(leagueId);
			if (response.status === 200) {
				fetchLeaderboard();
			}
		} catch (error) {
			console.error("Error leaving league:", error);
		}
	};

	const handleEditLeague = async () => {
		try {
			const res = await updateLeague(leagueId, editLeagueData);
			if (res.status === 200) {
				alert("League updated successfully");
				setShowEditLeagueModal(false);
				fetchLeagueData();
			}
		} catch (error) {
			console.error("Error editing league:", error);
		}
	};

	return (
		<Container fluid className="w-100 mx-0 px-0" style={{ overflow: "hidden" }}>
			<Helmet>
				<title>League - The Race Companion</title>
				<meta
					name="description"
					content="The Race Companion League Page. Compete against friends with teams built around Enduro, Slopestyle, Freeride or Downhill Mountain Biking. Follow along with Crankworx, World cups, Red Bull Hardline and Red Bull Rampage."
				/>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Helmet>
			<Row className="m-0 p-0">{isMobile ? <MobileAd /> : <TopAd />}</Row>
			<HomeTopRow events={events} />
			<Row noGutters>
				<Col lg={2} className="d-none d-lg-block text-start">
					<LeftAd />
				</Col>
				<Col xs={12} lg={8} className="bg-light  pt-4" style={{ zIndex: 1000 }}>
					<div>
						{/* {league?.image && league.image !== 'default.jpg' && (
						<div className='d-flex justify-content-center'>
							<Card className='border-0 shadow'>
								<Card.Header className='fw-bold ms-4 border-0 bg-white'>
									Series Prize
								</Card.Header>
								<picture>
									<source
										media='(max-width: 768px)' // Adjust this value based on your design requirements
										srcSet={league?.imageMobile}
									/>
									<Card.Img
										src={league?.image}
										alt='League Image'
										style={{ maxWidth: '100%', height: 'auto' }}
									/>
								</picture>
							</Card>
						</div>
					)} */}
						{isHardline ? (
							<div className="d-flex justify-content-center">
								<img
									style={{ width: "15rem", height: "auto" }}
									src={hardline_logo}
									alt=""
								/>
							</div>
						) : (
							<div className="my-2 mb-4 d-flex flex-row justify-content-center align-items-center w-100">
								<Card
									className="text-center shadow border-0 mx-2 mx-md-0"
									style={{ maxWidth: "fit-content" }}
								>
									<Card.Body
										className="p-4 px-md-5 fs-4 fw-bold bg-success text-white rounded"
										style={{ width: "fit-content" }}
									>
										{league?.series.name} <LuDot /> {league?.sport.name}
									</Card.Body>
								</Card>
							</div>
						)}
						<Row className="text-center mt-4">
							<Col>
								<h1>
									{league?.name == "Global League" && (
										<IoMdGlobe className="mb-1 me-2" />
									)}
									{league?.name}
								</h1>
							</Col>
						</Row>
						<Row
							className={`text-center text-muted ${
								isHardline ? "mb-0" : "mb-4"
							}`}
						>
							<Col>
								<p>{league?.description}</p>
							</Col>
						</Row>
						{isHardline && league?.name == "Global League" && (
							<Row className="d-flex justify-content-center text-muted mb-4">
								<Col md={8} className="text-center">
									<p className="fw-bold">
										The world’s toughest and most progressive downhill mountain
										bike race, Red Bull Hardline returns to Wales on 1st – 2nd
										June 2024, marking its 10th anniversary in the iconic Dyfi
										Valley.
									</p>
									<p className="fst-italic">
										Watch on{" "}
										<a
											href="https://www.redbull.com/gb-en/events/red-bull-hardline-uk"
											target="_blank"
											rel="noopener noreferrer"
										>
											Red Bull TV
										</a>{" "}
										at 2:30pm on Sunday 2nd June.
									</p>
								</Col>
							</Row>
						)}
						<Tab.Container defaultActiveKey="countdown">
							<div className="mb-3">
								<Card className="border-0 bg-light">
									<Card.Header className="rounded bg-success border shadow-sm">
										<Nav variant="pills" className="nav-justified">
											<Nav.Item>
												<Nav.Link eventKey="countdown">
													<FaHome className="mb-1 me-2" /> Home
												</Nav.Link>
											</Nav.Item>
											<Nav.Item>
												<Nav.Link eventKey="prizes">
													{" "}
													<FaTrophy className="mb-1 me-2" /> Prizes
												</Nav.Link>
											</Nav.Item>
										</Nav>
									</Card.Header>
									<Card.Body>
										<Tab.Content>
											<Tab.Pane eventKey="countdown">
												{league && (
													<LeagueHome
														userInLeague={userInLeague}
														handleJoinLeague={handleJoinLeague}
														setLeaveLeagueModal={() =>
															setLeaveLeagueModal(true)
														}
														league={league}
														leaderboard={leaderboard}
														user={user}
														setEditLeagueModal={() =>
															setShowEditLeagueModal(true)
														}
														team={team}
													/>
												)}
												{/* <CountdownTimer event={nearestFutureEvent} /> */}
											</Tab.Pane>
											<Tab.Pane eventKey="prizes">
												{leagues &&
													Array.isArray(leagues) &&
													leagues.length > 0 &&
													(leagues[0].roundImage ? (
														<Prizes leagues={leagues} />
													) : (
														<>
															{leagues[0].prize && (
																<Card className="my-2">
																	<Card.Body>
																		<p>{leagues[0].prize}</p>
																	</Card.Body>
																</Card>
															)}
															{leagues[0].losing_prize && (
																<Card className="my-2">
																	<Card.Body>
																		<p>{leagues[0].losing_prize}</p>
																	</Card.Body>
																</Card>
															)}
														</>
													))}
											</Tab.Pane>
											<Tab.Pane eventKey="rules">
												<Rules />
											</Tab.Pane>
										</Tab.Content>
									</Card.Body>
								</Card>
							</div>
						</Tab.Container>

						<div className="mx-2 mx-md-0">
							{/* User Team */}
							{userInLeague && (
								<MyTeam
									team={team}
									sport={league?.sport}
									series={league?.series}
									leagueId={league?._id}
								/>
							)}
							<hr></hr>
							{/* Comments */}
							<LeagueComments leagueId={leagueId} />
						</div>
					</div>

					<Modal
						show={showSignInModal}
						onHide={() => setShowSignInModal(false)}
					>
						<Modal.Header closeButton>
							<Modal.Title>Sign In Required</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<p>You must be signed in to join a league.</p>
						</Modal.Body>
						<Modal.Footer>
							<Button
								variant="primary"
								onClick={() => setShowSignInModal(false)}
							>
								Close
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={showCreateTeam} onHide={() => setShowCreateTeam(false)}>
						<Modal.Header closeButton>
							<Modal.Title>Create Team</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<p>
								You must create have a team in{" "}
								<b>
									{league?.series.name}: {league?.sport.name}
								</b>
							</p>
							<div className="d-flex justify-content-center">
								<Button
									variant="primary"
									className="px-4 py-2"
									onClick={() => {
										updateLeaderbordById(league?._id);
										navigate(
											`/EditTeam?leagueId=${leagueId}&sport=${league?.sport?._id}&series=${league?.series._id}`,
										);
									}}
								>
									Create Team
								</Button>
							</div>
						</Modal.Body>
					</Modal>
					<Modal
						show={leaveLeagueModal}
						onHide={() => setLeaveLeagueModal(false)}
					>
						<Modal.Header closeButton>
							<Modal.Title>
								<MdWarning className="mb-1 me-2" /> Leave League
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<p>Are you sure you want to leave the league?</p>
							<p>
								Leaving a league is permanent, and of your scores will be
								erased.{" "}
							</p>
						</Modal.Body>
						<Modal.Footer>
							<Button
								variant="outline-secondary"
								onClick={() => setLeaveLeagueModal(false)}
							>
								Cancel
							</Button>
							<Button
								variant="dark"
								onClick={() => {
									handleLeaveLeague();
									setLeaveLeagueModal(false);
								}}
							>
								Leave League
							</Button>
						</Modal.Footer>
					</Modal>
					<Modal
						show={showEditLeagueModal}
						onHide={() => setShowEditLeagueModal(false)}
					>
						<Modal.Header closeButton>
							<Modal.Title>
								{" "}
								<FaEdit className="mb-1 me-2" /> Edit League
							</Modal.Title>
						</Modal.Header>

						<Modal.Body>
							<Form.Group>
								<Form.Label>League Name</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter league name"
									value={editLeagueData.name}
									onChange={(e) =>
										setEditLeagueData({
											...editLeagueData,
											name: e.target.value,
										})
									}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>League Description</Form.Label>
								<Form.Control
									as="textarea"
									rows={3}
									placeholder="Enter league description"
									value={editLeagueData.description}
									onChange={(e) =>
										setEditLeagueData({
											...editLeagueData,
											description: e.target.value,
										})
									}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>Privacy</Form.Label>
								<Form.Select
									value={editLeagueData.privacy}
									onChange={(e) =>
										setEditLeagueData({
											...editLeagueData,
											privacy: e.target.value,
										})
									}
								>
									<option value="public">Public</option>
									<option value="private">Private</option>
								</Form.Select>
							</Form.Group>
							<Form.Group>
								<Form.Label>Prize</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter prize"
									value={editLeagueData.prize}
									onChange={(e) =>
										setEditLeagueData({
											...editLeagueData,
											prize: e.target.value,
										})
									}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Label>Losing Prize</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter losing prize"
									value={editLeagueData.losing_prize}
									onChange={(e) =>
										setEditLeagueData({
											...editLeagueData,
											losing_prize: e.target.value,
										})
									}
								/>
							</Form.Group>
						</Modal.Body>
						<Modal.Footer>
							<Button
								variant="outline-secondary"
								onClick={() => setShowEditLeagueModal(false)}
							>
								Cancel
							</Button>
							<Button
								variant="dark"
								onClick={() => {
									handleEditLeague();
								}}
							>
								Save
							</Button>
						</Modal.Footer>
					</Modal>
				</Col>
				<Col lg={2} className="d-none d-lg-block text-end">
					<RightAd />
				</Col>
			</Row>
		</Container>
	);
};

export default LeaguePage;
