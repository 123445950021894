import React, { useState, useRef, useEffect } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const VideoScrollbar = ({ videos }) => {
	const scrollContainerRef = useRef(null);
	const [showModal, setShowModal] = useState(false);
	const [currentVideo, setCurrentVideo] = useState(null);
	const [isMobile, setIsMobile] = useState(false);

	const scroll = (scrollOffset) => {
		scrollContainerRef.current.scrollLeft += scrollOffset;
	};

	const handleVideoClick = (video) => {
		setCurrentVideo(video);
		setShowModal(true);
	};

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		handleResize();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<div style={{ position: 'relative', width: '100%' }}>
			<button
				onClick={() => scroll(-400)}
				style={{
					position: 'absolute',
					left: 0,
					top: '50%',
					transform: 'translateY(-50%)',
					zIndex: 2,
					background: 'none',
					border: 'none',
				}}
			>
				{!isMobile && (
					<Button
						variant='dark'
						className='py-2 border-1 border-light px-2'
						style={{ borderRadius: '5rem' }}
					>
						<FiChevronLeft size={30} className='' />
					</Button>
				)}
			</button>
			<div
				ref={scrollContainerRef}
				style={{
					overflowX: isMobile ? 'auto' : 'hidden',
					whiteSpace: 'nowrap',
					// marginLeft: '35px',
					// marginRight: '35px',
				}}
				className='ms-sm-3 me-sm-4 ms-0 me-0'
			>
				{videos.map((video) => (
					<div
						key={video._id}
						style={{
							display: 'inline-block',
							width: '300px',
							margin: '0 10px',
							cursor: 'pointer',
						}}
						onClick={() => handleVideoClick(video)}
					>
						<Card className='bg-dark text-white'>
							{/* Use a thumbnail image instead of an iframe */}
							<Card.Img
								variant='top'
								src={video.thumbnailUrl}
								alt={video.title}
								style={{ height: '180px', objectFit: 'cover' }}
							/>
							<Card.Footer
								className='bg-secondary text-white fw-bold fs-6'
								style={{
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									textWrap: 'pretty',
									height: '3.8rem',
								}}
							>
								{video.title}
							</Card.Footer>
						</Card>
					</div>
				))}
			</div>
			<button
				onClick={() => scroll(400)}
				style={{
					position: 'absolute',
					right: 0,
					top: '50%',
					transform: 'translateY(-50%)',
					zIndex: 2,
					background: 'none',
					border: 'none',
				}}
			>
				{!isMobile && (
					<Button
						variant='dark'
						className='py-2 border-1 border-light px-2'
						style={{ borderRadius: '5rem' }}
					>
						<FiChevronRight size={30} className='' />
					</Button>
				)}
			</button>

			{/* Video Modal */}
			<Modal
				show={showModal}
				onHide={() => setShowModal(false)}
				size='lg'
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>{currentVideo?.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<iframe
						src={`${currentVideo?.videoUrl}?autoplay=1`}
						title={currentVideo?.title}
						style={{ width: '100%', height: '450px' }}
						frameBorder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen
					></iframe>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default VideoScrollbar;
