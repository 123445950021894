import React, { useEffect, useRef, useState } from "react";
import {
	Row,
	Col,
	Button,
	Card,
	Accordion,
	ListGroup,
	Form,
	Modal,
} from "react-bootstrap";
import Leaderboard from "./Leaderboard";
import {
	FaArrowRight,
	FaArrowLeft,
	FaEdit,
	FaUserCheck,
	FaUserPlus,
	FaUserAltSlash,
} from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { MdLeaderboard } from "react-icons/md";
import { getLeagueAdmin } from "../../../services/leagueService";
import { MdAdminPanelSettings } from "react-icons/md";
import PointsBreakdown from "./PointsBreakdown";
import { GrHistory } from "react-icons/gr";
import { FaAngleRight } from "react-icons/fa6";
import { getRoundWinners } from "../../../services/leaderboardService";
import { GiPodiumWinner } from "react-icons/gi";
import { IoMdArrowDropright } from "react-icons/io";

const LeagueHome = ({
	userInLeague,
	handleJoinLeague,
	setLeaveLeagueModal,
	league,
	leaderboard,
	user,
	setEditLeagueModal,
	team,
}) => {
	const [inviteFriendsModal, setInviteFriendsModal] = useState(false);
	const [leagueAdmins, setLeagueAdmins] = useState([]);
	const [showPointsBreakdown, setShowPointsBreakdown] = useState(false);
	const [roundWinner, setRoundWinner] = useState([]);
	const leaderboardTeam =
		leaderboard &&
		Array.isArray(leaderboard.teams) &&
		leaderboard.teams.length > 0
			? leaderboard.teams.find((team) => team.userName === user?.username)
			: null;

	const linkRef = useRef(null);
	const navigate = useNavigate();

	useEffect(() => {
		fetchLeagueAdmins();
		fetchRoundWinner();
	}, []);

	const fetchLeagueAdmins = async () => {
		try {
			const res = await getLeagueAdmin(league._id);
			if (res) {
				setLeagueAdmins(res.data);
			}
		} catch (error) {
			console.error("Error fetching league admins:", error);
		}
	};

	const fetchRoundWinner = async () => {
		try {
			const res = await getRoundWinners(league._id);
			if (res) {
				setRoundWinner(res.data);
			}
		} catch (error) {
			console.error("Error fetching round winner:", error);
		}
	};

	const handleCopyLink = () => {
		navigator.clipboard
			.writeText(linkRef.current.value)
			.then(() => {
				alert("Link copied to clipboard!");
			})
			.catch((err) => {
				console.error("Failed to copy: ", err);
			});
	};

	return (
		<div>
			<Row
				className={`text-center ${
					userInLeague ? "align-items-end" : "align-items-center"
				} mb-3`}
			>
				<Col className="w-100">
					<Card
						style={{ maxWidth: "30rem", minHeight: "16rem" }}
						className="shadow"
					>
						<Card.Body className="p-4">
							<Accordion className="mb-3">
								<Accordion.Item eventKey="leaderboard" className="">
									<Accordion.Header className="d-flex justify-content-between w-100 shadow border-0 rounded-3 ">
										<div className="d-flex justify-content-between w-100">
											<div className="text-success fw-bold fs-5">
												<MdLeaderboard className="me-2 mb-1" />
												Leaderboard (
												{league?.teams ? league?.teams.length : "..."})
											</div>
										</div>
									</Accordion.Header>
									<Accordion.Body style={{ overflow: "auto" }}>
										{leaderboard && <Leaderboard leaderboard={leaderboard} />}
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
							<Row>
								<Col>
									{leaderboard && leaderboard.teams && (
										<ListGroup className="text-start shadow">
											<ListGroup.Item>
												League Leader: 🏆{" "}
												<b>{leaderboard?.teams[0]?.userName}</b>
											</ListGroup.Item>
											<ListGroup.Item
												onClick={() => setShowPointsBreakdown(true)}
												style={{ cursor: "pointer" }}
												variant="success"
											>
												<div className="d-flex flex-row justify-content-between">
													<div className="">
														My Rank:
														<b>
															{user &&
																leaderboard &&
																leaderboard.teams.findIndex(
																	(team) => team?.userName === user?.username,
																) + 1}
														</b>
													</div>
													<FaAngleRight className="mt-1" />
												</div>
											</ListGroup.Item>
											<ListGroup.Item
												onClick={() => setShowPointsBreakdown(true)}
												style={{ cursor: "pointer" }}
												variant="success"
											>
												<div className="d-flex flex-row justify-content-between">
													<div className="">
														Team Points:
														<b>
															{user &&
																leaderboard &&
																leaderboard.teams.find(
																	(team) => team?.userName === user?.username,
																)?.totalPoints}
														</b>
													</div>
													<FaAngleRight className="mt-1" />
												</div>
											</ListGroup.Item>
											{Array.isArray(roundWinner) &&
												roundWinner.length > 0 &&
												roundWinner.map((winner) => (
													<ListGroup.Item key={winner._id}>
														<div className="d-flex flex-row justify-content-between">
															<div className="">
																<GiPodiumWinner className="mb-1 me-2" />
																{winner?.eventId.location}:{" "}
																<b>{winner?.userName}</b>{" "}
																<IoMdArrowDropright className="mb-1" />{" "}
																{winner?.totalPoints} points
															</div>
														</div>
													</ListGroup.Item>
												))}
											<ListGroup.Item></ListGroup.Item>
										</ListGroup>
									)}
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
				<Col className="">
					<Card
						className="text-center mt-md-0 mt-3 shadow"
						style={{ maxWidth: "30rem", minHeight: "16rem" }}
					>
						<Card.Header className="text-start" style={{ fontSize: "0.9rem" }}>
							<div className="d-flex flex-row justify-content-between">
								<div className="pt-1 text-muted">{league?.privacy}</div>
								<div className="d-flex flex-grow flex-row justify-content-end">
									<div
										className="mt-2 me-2 text-muted"
										style={{ fontSize: "0.7rem" }}
									>
										<MdAdminPanelSettings
											className="mb-1 "
											style={{ width: "1rem", height: "auto" }}
										/>{" "}
										{leagueAdmins[0]?.username}
									</div>
									{user &&
										league?.admins.find((admin) => admin == user?._id) && (
											<Button
												variant="outline-secondary"
												size="sm"
												onClick={setEditLeagueModal}
											>
												Edit
											</Button>
										)}
								</div>
							</div>
						</Card.Header>
						<Card.Body>
							{!userInLeague ? (
								<div className="d-flex w-100 flex-row justify-content-center align-items-center pt-5">
									<Button className="py-4 px-5 fs-5" onClick={handleJoinLeague}>
										<FaArrowRight className="me-3 mb-1" />
										JOIN LEAGUE
										<FaArrowLeft className="ms-3 mb-1" />
									</Button>
								</div>
							) : (
								<div>
									<Row className="mb-2">
										<Col>
											<Button
												className="py-2 px-5"
												variant="primary"
												onClick={() =>
													navigate(
														`/editteam?sport=${league.sport?._id}&series=${league.series?._id}&leagueId=${league._id}`,
													)
												}
											>
												<FaEdit className="mb-1 me-2" />
												Edit My Team
											</Button>
										</Col>
									</Row>
									<Row className="mb-2">
										<Col>
											<Button
												className="py-2 px-5"
												variant="outline-dark"
												onClick={() => setInviteFriendsModal(true)}
											>
												<FaUserPlus className="mb-1 me-2" />
												Invite Friends
											</Button>
										</Col>
									</Row>
									<Row className="mb-4">
										<Col>
											<Button
												className="py-2 px-5"
												variant="outline-dark"
												onClick={() => setShowPointsBreakdown(true)}
											>
												<GrHistory className="mb-1 me-2" /> Team History
											</Button>
										</Col>
									</Row>
								</div>
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Modal
				show={inviteFriendsModal}
				onHide={() => setInviteFriendsModal(false)}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{" "}
						<FaUsers className="mb-1 me-2" /> Invite Friends
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Send this link to your friends to join league:</p>
					<Form.Group>
						<Form.Control
							type="text"
							readOnly
							ref={linkRef}
							value={`${window.location.origin}/league/${league._id}`}
						/>
					</Form.Group>
					<Row className="mt-3">
						<Col>
							<Button className="w-100" onClick={handleCopyLink}>
								Copy Link
							</Button>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>
			<PointsBreakdown
				leaderboardTeam={leaderboardTeam}
				showModal={showPointsBreakdown}
				handleClose={() => setShowPointsBreakdown(false)}
			/>
		</div>
	);
};

export default LeagueHome;
