import apiInstance from "./api";

export const setTeamResult = async () => {
	try {
		const res = await apiInstance.post("/teamResults");
		return res;
	} catch (error) {
		throw error;
	}
};

export const getTeamResults = async (team) => {
	try {
		const res = await apiInstance.get(`/teamResults/${team}`);
		return res;
	} catch (error) {
		throw error;
	}
};
