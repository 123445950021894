import React from "react";
import { Card, Col, Nav, Row } from "react-bootstrap";
import { FaFilter } from "react-icons/fa6";
import { MdFiberNew, MdNewReleases } from "react-icons/md";

const TopRowSeries = ({ series, selectedSeries, handleSelectSeries }) => {
	const [activeKey, setActiveKey] = React.useState(series[0] || "");

	const handleSelect = (eventKey) => {
		// if the selected series is the same as the active key, unselect it
		if (eventKey === activeKey) {
			setActiveKey("");
			handleSelectSeries(null);
			return;
		}

		setActiveKey(eventKey);
		const _selectedSeries = series.find((series) => series.name === eventKey);

		handleSelectSeries(_selectedSeries);
	};

	return (
		<div className="series-row px-sm-5 pb-sm-0 pb-2 bg-dark d-flex justify-content-center">
			<Row
				className="d-flex flex-row justify-content-center w-100 border-light"
				style={{
					borderTop: "0px",
					borderRight: "0px",
					borderLeft: "0px",
					borderBottom: "1px",
					borderStyle: "solid",
				}}
			>
				<Col xl={6} lg={8} md={10} sm={12}>
					<Card className="border-0 bg-dark rounded-0 ">
						<Card.Header className="rounded bg-dark border-0">
							<Nav
								variant="pills"
								activeKey={activeKey}
								onSelect={handleSelect}
								className="nav-justified series-nav"
							>
								{series.map((option) => (
									<Nav.Item key={option.name} className="mx-2 mt-md-0 mt-2">
										<Nav.Link
											eventKey={option.name}
											className={activeKey === option.name ? "active-pill" : ""}
										>
											{option.name == "Red Bull Hardline" && (
												<MdNewReleases
													className="mb-1 me-1 text-primary"
													// style={{ color: "yellow" }}
												/>
											)}
											<b>{option.name}</b>
										</Nav.Link>
									</Nav.Item>
								))}
							</Nav>
						</Card.Header>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default TopRowSeries;
