import React, { useState, useEffect } from "react";
import {
	Container,
	Row,
	Col,
	Card,
	Form,
	Button,
	Table,
} from "react-bootstrap";
import axios from "axios"; // assuming you are using axios for HTTP requests
import { getEvents } from "../../services/eventService";
import { getSeries, getSports } from "../../services/series";
import { getRiders } from "../../services/riderService";
import { createResult } from "../../services/resultService";

const AdminResultsPage = () => {
	const [events, setEvents] = useState([]);
	const [series, setSeries] = useState([]);
	const [sports, setSports] = useState([]);
	const [riders, setRiders] = useState([]);
	const [resultsData, setResultsData] = useState([]);
	const [selectedSeries, setSelectedSeries] = useState(null);
	const [selectedSport, setSelectedSport] = useState(null);
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [filteredRiders, setFilteredRiders] = useState([]);
	const [selectedPhase, setSelectedPhase] = useState("qualifying_data");
	const [sort, setSort] = useState("asc");
	const [genderFilter, setGenderFilter] = useState("m");

	useEffect(() => {
		fetchEvents();
		fetchSeries();
		fetchSports();
		fetchRiders();
	}, []);

	useEffect(() => {
		filterRidersAndUpdateResults();
	}, [selectedSeries, selectedSport, genderFilter]);

	const fetchEvents = async () => {
		try {
			const res = await getEvents();
			setEvents(res.data);
		} catch (error) {
			console.error("Error fetching events:", error);
		}
	};

	const fetchSeries = async () => {
		try {
			const res = await getSeries();
			setSeries(res.data);
		} catch (error) {
			console.error("Error fetching series:", error);
		}
	};

	const fetchSports = async () => {
		try {
			const res = await getSports();
			setSports(res.data);
		} catch (error) {
			console.error("Error fetching sports:", error);
		}
	};

	const fetchRiders = async () => {
		try {
			const res = await getRiders();
			if (!res.data) return;
			setRiders(res.data);
			setFilteredRiders(res.data);
			initializeResults(res.data);
		} catch (error) {
			console.error("Error fetching riders:", error);
		}
	};

	const sortRiders = (riders, sortType) => {
		try {
			if (sortType === "asc") {
				return riders.sort((a, b) => a.last_name.localeCompare(b.last_name));
			} else {
				return riders.sort((a, b) => {
					// Check if selectedPhase exists and if a and b have data for the selected phase
					if (selectedPhase) {
						//We actually need to find the rider in the resultsData array and then get the points for the phase, and then sort

						// console.log("HERE", a, b[selectedPhase]?.points);
						const riderA = resultsData.find((res) => res.rider === a._id);
						const riderB = resultsData.find((res) => res.rider === b._id);

						const pointsA = riderA[selectedPhase]?.points || 0;
						const pointsB = riderB[selectedPhase]?.points || 0;
						// const pointsA = a[selectedPhase]?.points || 0;
						// const pointsB = b[selectedPhase]?.points || 0;

						return pointsB - pointsA;
					}
					// If selectedPhase or data for the selected phase is missing, return 0
					return 0;
				});
			}
		} catch (error) {
			console.error("Error sorting riders:", error);
		}
	};

	const filterRidersAndUpdateResults = (e) => {
		if (!riders || riders.length === 0) return;

		if (!selectedSeries && !selectedSport && !genderFilter) {
			// No filters selected, show all riders
			setFilteredRiders(riders);
			return;
		}

		const filtered = riders.filter((rider) => {
			// Check if the rider matches the selected series, sport, and gender
			return (
				(!selectedSeries || rider.series.includes(selectedSeries)) &&
				(!selectedSport || rider.sports.includes(selectedSport)) &&
				(!genderFilter || rider.gender === genderFilter)
			);
		});

		const sorted = sortRiders(filtered, e);
		// console.log(sorted);
		setFilteredRiders(sorted);
	};

	const initializeResults = (filteredRiders) => {
		const initialResults = filteredRiders.map((rider) => ({
			rider: rider._id,
			qualifying_data: {
				split1: "",
				split2: "",
				time: "",
				score: 0,
				points: 0,
				rank: 0,
			},
			semis_data: {
				split1: "",
				split2: "",
				time: "",
				score: 0,
				points: 0,
				rank: 0,
			},
			finals_data: {
				split1: "",
				split2: "",
				time: "",
				score: 0,
				points: 0,
				rank: 0,
			},
		}));
		setResultsData(initialResults);
	};

	const handleInputChange = (riderId, phase, field, value) => {
		setResultsData((currentResults) =>
			currentResults.map((result) =>
				result.rider === riderId
					? {
							...result,
							[phase]: {
								...result[phase],
								[field]: value,
							},
					  }
					: result,
			),
		);
	};
	const handleSaveResults = async () => {
		try {
			if (!selectedEvent || !selectedSeries || !selectedSport) {
				alert("Please select an event, series, and sport");
				return;
			}

			// Filter riders who don't have any points, time, or rank
			const data = filteredRiders
				.map((rider) => {
					const result = resultsData.find((res) => res.rider === rider._id);
					if (result) {
						const totalPoints =
							(result.qualifying_data?.points || 0) +
							(result.semis_data?.points || 0) +
							(result.finals_data?.points || 0);

						return {
							event: selectedEvent,
							event_date: events.find((event) => event._id === selectedEvent)
								.date,
							series: selectedSeries,
							sport: selectedSport,
							rider: rider._id,
							qualifying_data: result.qualifying_data || {},
							semis_data: result.semis_data || {},
							finals_data: result.finals_data || {},
							total_points: totalPoints,
						};
					} else {
						return null;
					}
				})
				.filter(
					(entry) =>
						entry &&
						(entry.qualifying_data.points > 0 ||
							entry.qualifying_data.time ||
							entry.qualifying_data.rank > 0 ||
							entry.semis_data.points > 0 ||
							entry.semis_data.time ||
							entry.semis_data.rank > 0 ||
							entry.finals_data.points > 0 ||
							entry.finals_data.time ||
							entry.finals_data.rank > 0),
				);

			// console.log(data);
			const res = await createResult(data);
			if (res) {
				alert("Results saved successfully");
				//clear all fields
				fetchRiders();
				setSelectedEvent(null);
				setSelectedSeries(null);
				setSelectedSport(null);
				setResultsData([]);
			}
		} catch (error) {
			console.error("Error saving results:", error);
		}
	};

	const handleAddRank = () => {
		// I want to only add a rank to filtered riders based on the phase. Ie, the same gender, series, and sport

		// console.log("ADDING RANK", filteredRiders);
		// we want to find riders in filtered riders, and update the rank based on points in the selected phase.

		// console.log("ADDING RANK", filteredRiders);
		// Filtered riders has already been set. So first find the riders in the resultsData array
		// that match the filtered riders
		// const ridersToUpdate = resultsData.filter((result) =>
		// 	filteredRiders.find((rider) => rider._id === result.rider),
		// );

		const sortedRiders = sortRiders(filteredRiders, "points");

		//Then update the rank based on the sorted riders
		setResultsData((currentResults) =>
			currentResults.map((result) => {
				const sortedRider = sortedRiders.find(
					(rider) => rider._id === result.rider,
				);

				if (sortedRider && result[selectedPhase].points > 0) {
					return {
						...result,
						[selectedPhase]: {
							...result[selectedPhase],
							rank: sortedRiders.indexOf(sortedRider) + 1,
						},
					};
				}
				return result;
			}),
		);

		// setResultsData((currentResults) =>
		// 	currentResults.map((result) => {
		// 		if (result[selectedPhase].rank === 0) {
		// 			return {
		// 				...result,
		// 				[selectedPhase]: {
		// 					...result[selectedPhase],
		// 					rank: currentResults.indexOf(result) + 1,
		// 				},
		// 			};
		// 		}
		// 		return result;
		// 	}),
		// );
	};

	return (
		<Container>
			<h1>Admin Results Page</h1>
			<Row className="mb-3">
				<Col>
					<Form>
						<Form.Group>
							<Form.Label>Select Series</Form.Label>
							<Form.Control
								as="select"
								onChange={(e) => setSelectedSeries(e.target.value)}
							>
								<option value="">Select Series</option>
								{series.map((series) => (
									<option key={series._id} value={series._id}>
										{series.name}
									</option>
								))}
							</Form.Control>
						</Form.Group>
					</Form>
				</Col>
				<Col>
					<Form>
						<Form.Group>
							<Form.Label>Select Sport</Form.Label>
							<Form.Control
								as="select"
								onChange={(e) => setSelectedSport(e.target.value)}
							>
								<option value="">Select Sport</option>
								{sports.map((sport) => (
									<option key={sport._id} value={sport._id}>
										{sport.name}
									</option>
								))}
							</Form.Control>
						</Form.Group>
					</Form>
				</Col>
			</Row>
			<Row>
				<Col>
					<Card className="mb-3">
						<Card.Body>
							<div className="d-flex flex-row justify-content-end">
								<Button onClick={handleSaveResults}>Save Results</Button>
							</div>
							<Form>
								<Form.Group className="mb-3">
									<Form.Label>Select Event</Form.Label>
									<Form.Control
										as="select"
										onChange={(e) => setSelectedEvent(e.target.value)}
									>
										<option value="">Select Event</option>
										{events.map((event) => (
											<option key={event._id} value={event._id}>
												{event.name}
											</option>
										))}
									</Form.Control>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Sort</Form.Label>
									<Form.Select
										aria-label="Sort select"
										value={sort}
										className="mb-3"
										onChange={(e) => {
											setSort(e.target.value);
											filterRidersAndUpdateResults(e.target.value);
										}}
									>
										<option value="asc">A-Z</option>
										<option value="desc">Points</option>
									</Form.Select>
								</Form.Group>
								<Form.Group className="mb-3">
									<Form.Label>Gender</Form.Label>
									<Form.Select
										aria-label="Gende Select"
										value={genderFilter}
										className="mb-3"
										onChange={(e) => {
											setGenderFilter(e.target.value);
										}}
									>
										<option value="m">Men</option>
										<option value="f">Women</option>
									</Form.Select>
								</Form.Group>
								<Form.Select
									aria-label="Phase select"
									value={selectedPhase}
									className="mb-3"
									onChange={(e) => setSelectedPhase(e.target.value)}
								>
									<option value="qualifying_data">Qualifying</option>
									<option value="semis_data">Semis</option>
									<option value="finals_data">Finals</option>
								</Form.Select>
								<div className="text-end w-100">
									<Button
										className="mb-3"
										variant="success"
										onClick={() => {
											handleAddRank();
										}}
									>
										Add Rank to {selectedPhase}
									</Button>
								</div>
								<Table striped bordered hover>
									<thead>
										<tr>
											<th>Name</th>
											<th>Split 1</th>
											<th>Split 2</th>
											<th>Time</th>
											<th>Score</th>
											<th>Points</th>
											<th>Rank</th>
										</tr>
									</thead>
									<tbody>
										{Array.isArray(filteredRiders) &&
											filteredRiders.length > 0 &&
											filteredRiders.map((rider) => (
												<tr key={rider._id}>
													<td>
														{rider.last_name}, {rider.first_name}
													</td>
													{/* Split 1 Input */}
													<td>
														<Form.Control
															type="text"
															value={
																resultsData.find(
																	(res) => res.rider === rider._id,
																)?.[selectedPhase].split1 || ""
															}
															onChange={(e) =>
																handleInputChange(
																	rider._id,
																	selectedPhase,
																	"split1",
																	e.target.value,
																)
															}
														/>
													</td>
													{/* Split 2 Input */}
													<td>
														<Form.Control
															type="text"
															value={
																resultsData.find(
																	(res) => res.rider === rider._id,
																)?.[selectedPhase].split2 || ""
															}
															onChange={(e) =>
																handleInputChange(
																	rider._id,
																	selectedPhase,
																	"split2",
																	e.target.value,
																)
															}
														/>
													</td>
													{/* Time Input */}
													<td>
														<Form.Control
															type="text"
															value={
																resultsData.find(
																	(res) => res.rider === rider._id,
																)?.[selectedPhase].time || ""
															}
															onChange={(e) =>
																handleInputChange(
																	rider._id,
																	selectedPhase,
																	"time",
																	e.target.value,
																)
															}
														/>
													</td>
													{/* Score Input */}
													<td>
														<Form.Control
															type="number"
															value={
																resultsData.find(
																	(res) => res.rider === rider._id,
																)?.[selectedPhase].score || 0
															}
															onChange={(e) =>
																handleInputChange(
																	rider._id,
																	selectedPhase,
																	"score",
																	Number(e.target.value),
																)
															}
														/>
													</td>
													{/* Points Input */}
													<td>
														<Form.Control
															type="number"
															value={
																resultsData.find(
																	(res) => res.rider === rider._id,
																)?.[selectedPhase].points || 0
															}
															onChange={(e) =>
																handleInputChange(
																	rider._id,
																	selectedPhase,
																	"points",
																	Number(e.target.value),
																)
															}
														/>
													</td>
													{/* Rank Input */}
													<td>
														<Form.Control
															type="number"
															value={
																resultsData.find(
																	(res) => res.rider === rider._id,
																)?.[selectedPhase].rank || 0
															}
															onChange={(e) =>
																handleInputChange(
																	rider._id,
																	selectedPhase,
																	"rank",
																	Number(e.target.value),
																)
															}
														/>
													</td>
												</tr>
											))}
									</tbody>
								</Table>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default AdminResultsPage;
