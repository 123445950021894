import axios from 'axios';
import apiInstance from './api';

//Get all riders
export const getRiders = async (params = {}) => {
	return apiInstance.get('/riders', { params });
};

//Get riders based on sport
export const getRidersBySportAndSeries = async (sportId) => {
	return apiInstance.get(`/riders/sport-series`);
};

export const makeRidersIneligible = async () => {
	return apiInstance.put("/riders/crankworx/ineligible");
}

//Create a new rider
export const createRider = async (data) => {
	return apiInstance.post('/riders', data);
};

//Update a rider
export const updateRider = async (id, data) => {
	return apiInstance.put(`/riders/${id}`, data);
};

//Delete a rider
export const deleteRider = async (id) => {
	return apiInstance.delete(`/riders/${id}`);
};

//toggle rider status 
export const toggleRiderStatus = async (id) => {
	return apiInstance.put(`/riders/${id}/toggleStatus`);
};