import apiInstance from './api';

export const disableService = async (sport, series, disable) => {
	return apiInstance.post(`/disable`, { sport, series, disable });
};

//get all disabled sports and series
export const getDisabled = async () => {
	return apiInstance.get(`/disable`);
};

//get disabled sport and series
export const getDisabledSportSeries = async (sportId, seriesId) => {
	return apiInstance.get(`/disable/${sportId}/${seriesId}`);
};
