import React, { useState } from 'react';
import {
	Modal,
	Tabs,
	Tab,
	Table,
	Col,
	Row,
	ToggleButton,
	ToggleButtonGroup,
} from 'react-bootstrap'; // Assuming you're using React Bootstrap for UI components
import ReactCountryFlag from 'react-country-flag';
import getCountryISO2 from 'country-iso-3-to-2';
import { MdEvent } from 'react-icons/md';

const EventResultsModal = ({ showEventResults, eventResults, closeModal }) => {
	const [selectedGender, setSelectedGender] = useState('m');

	// Helper function to sort results by points
	const sortResults = (results) => {
		return results.sort((a, b) => b.points - a.points);
	};

	// Check if there's data for each stage to decide tab visibility
	const hasQualifyingData = eventResults.some(
		(result) => result.qualifying_data.points > 0,
	);
	const hasSemisData = eventResults.some(
		(result) => result.semis_data.points > 0,
	);
	const hasFinalsData = eventResults.some(
		(result) => result.finals_data.points > 0,
	);

	// Determine if the overall tab should be displayed
	const showOverallTab =
		[hasQualifyingData, hasSemisData, hasFinalsData].filter(Boolean).length > 1;

	// Render a table for given stage results
	const renderResultsTable = (stage) => {
		let sortedResults = [];
		if (stage !== 'overall') {
			sortedResults = sortResults(
				eventResults
					.filter((result) => result.rider.gender === selectedGender)
					.map((result) => ({
						...result.rider,
						...result[`${stage}_data`],
					})),
			);
		} else {
			// Calculate and sort by total points for the overall tab
			sortedResults = sortResults(
				eventResults.map((result) => ({
					...result.rider,
					points:
						(result.qualifying_data?.points || 0) +
						(result.semis_data?.points || 0) +
						(result.finals_data?.points || 0),
					// Rank calculation could be added here if needed
				})),
			);
		}

		return (
			<Table
				striped
				bordered
				hover
				className='my-3'
				style={{ fontSize: '0.7rem' }}
			>
				<thead>
					<tr>
						<th>Rider Name</th>
						<th>Points</th>
						{/* <th>Rank</th> */}
					</tr>
				</thead>
				<tbody>
					{sortedResults.map((result, index) => (
						<tr key={index}>
							<td>
								<Row>
									<Col xs={1}>{index + 1}.</Col>
									<Col xs={2}>
										<ReactCountryFlag
											countryCode={getCountryISO2(
												result.country.toUpperCase().trim(),
											)}
											svg
											style={{ width: '3.5rem' }}
										/>
									</Col>
									<Col xs={8} className='text-end text-sm-start'>
										<b>{result.last_name}</b> {`${result.first_name} `}
									</Col>
								</Row>
								{/* <ReactCountryFlag
									countryCode={getCountryISO2(
										result.country.toUpperCase().trim(),
									)}
									svg
									style={{ width: '3.5rem' }}
								/>
								<b>{result.last_name}</b> {`${result.first_name} `} */}
							</td>
							<td>{result.points}</td>
							{/* <td>{result.rank || '-'}</td> */}
						</tr>
					))}
				</tbody>
			</Table>
		);
	};

	return (
		<Modal
			show={showEventResults}
			size='lg'
			onHide={() => {
				closeModal();
			}}
			className='border-0'
		>
			<Modal.Header closeButton className='bg-success text-white border-0'>
				<Modal.Title>
					<MdEvent className='mb-1 me-1' /> Event Results
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ToggleButtonGroup
					type='radio'
					name='leagueToggle'
					value={selectedGender}
					className='mb-3 w-100'
				>
					<ToggleButton
						type='radio'
						id='m'
						variant='dark'
						name='gender'
						value='m'
						checked={selectedGender === 'm'}
						onChange={(e) => setSelectedGender(e.currentTarget.value)}
					>
						Elite Male
					</ToggleButton>
					<ToggleButton
						id='f'
						type='radio'
						variant='dark'
						name='gender'
						value='f'
						checked={selectedGender === 'f'}
						onChange={(e) => setSelectedGender(e.currentTarget.value)}
					>
						Elite Female
					</ToggleButton>
				</ToggleButtonGroup>
				<Tabs
					className='bg-secondary py-2 px-2 rounded'
					defaultActiveKey={
						hasQualifyingData ? 'qualifying' : hasSemisData ? 'semis' : 'finals'
					}
					variant='pills'
					id='result-tabs'
				>
					{hasQualifyingData && (
						<Tab eventKey='qualifying' title='Qualifying'>
							{renderResultsTable('qualifying')}
						</Tab>
					)}
					{hasSemisData && (
						<Tab eventKey='semis' title='Semis'>
							{renderResultsTable('semis')}
						</Tab>
					)}
					{hasFinalsData && (
						<Tab eventKey='finals' title='Finals'>
							{renderResultsTable('finals')}
						</Tab>
					)}
					{showOverallTab && (
						<Tab eventKey='overall' title='Overall'>
							{renderResultsTable('overall')}
						</Tab>
					)}
				</Tabs>
			</Modal.Body>
		</Modal>
	);
};

export default EventResultsModal;
