import React from "react";
import { Card, Button, ListGroup, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { deleteTeam } from "../../../services/teamService";
import getCountryISO2 from "country-iso-3-to-2";
import ReactCountryFlag from "react-country-flag";
import { IoEnter } from "react-icons/io5";

const Teams = ({ teams, setCreateTeam, setCreateLeague }) => {
	const navigate = useNavigate();
	const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);

	const handleDeleteTeam = async (team) => {
		try {
			const res = await deleteTeam(team._id);

			if (res) {
				// Refresh the page
				window.location.reload();
			}
		} catch (error) {
			console.error("Error deleting team:", error);
		}
	};

	const hasTeams = teams && teams.length > 0;
	return (
		<div className="px-sm-5">
			{teams.map((team) => (
				<Card key={team._id} className="mb-4 border-0 shadow">
					<Card.Header className="bg-success border-0 text-white ps-md-5">
						<Row>
							<Col sm={4} className="text-md-start text-center mb-md-0 mb-2">
								<b>{team.series.name}: </b>
								{team.sport.name}
							</Col>
							<Col md={8} className="text-md-end text-center">
								<Button
									variant="dark"
									size={"sm"}
									className="me-3 px-5 fw-bold w-auto"
									onClick={() =>
										navigate(
											`/editteam?teamId=${team._id}&sport=${team.sport._id}&series=${team.series._id}`,
										)
									}
								>
									Edit Team
								</Button>
							</Col>
						</Row>
					</Card.Header>
					<Card.Body className="p-4">
						<Card.Subtitle className="mb-2 text-muted">Leagues:</Card.Subtitle>
						<ListGroup>
							{team.leagues.map((league) => (
								<ListGroup.Item
									key={league._id}
									onClick={() => navigate(`/league/${league._id}`)}
									action
								>
									<div className="d-flex flex-row justify-content-between">
										<div
											className="d-flex flex-row justify-content-start align-items-center"
											style={{
												overflow: "hidden",
												textWrap: "nowrap",
												textOverflow: "ellipsis",
											}}
										>
											<IoEnter
												className="mb-1 me-2"
												style={{ minWidth: "1rem" }}
											/>
											{league.name}
										</div>
										<Button
											variant="success"
											className=""
											style={{ fontSize: "0.7rem" }}
										>
											<IoEnter className=" me-2" />
											League
										</Button>
									</div>
								</ListGroup.Item>
							))}
						</ListGroup>
						<Card.Subtitle className="mt-3 mb-2 text-muted">
							My Team:
						</Card.Subtitle>
						<ListGroup>
							{team?.riders?.map((rider) => (
								<ListGroup.Item key={rider?.rider?._id}>
									<Row>
										<Col md={6} className="text-sm-start my-0 my-sm-0">
											{getCountryISO2(
												rider?.rider?.country.toUpperCase().trim(),
											) ? (
												<ReactCountryFlag
													countryCode={getCountryISO2(
														rider?.rider?.country.toUpperCase().trim(),
													)}
													svg
													style={{ width: "3.5rem" }}
												/>
											) : (
												rider?.rider?.country
											)}
											<b>{rider?.rider?.last_name}</b>{" "}
											{rider?.rider?.first_name}
										</Col>
										<Col
											md={6}
											className="text-md-end text-center my-2 my-sm-0"
										>
											{rider?.rider?.status && rider?.rider?.status}
										</Col>
									</Row>
								</ListGroup.Item>
							))}
						</ListGroup>
					</Card.Body>
				</Card>
			))}
		</div>
	);
};

export default Teams;
