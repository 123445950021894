import React, { useEffect, useState } from "react";
import {
	Card,
	Form,
	Button,
	FormGroup,
	FormControl,
	FormLabel,
	Container,
	Row,
} from "react-bootstrap";
import { getUser, updateUser } from "../../services/userService";

const EditUserProfile = ({ isGmailAccount }) => {
	const [username, setUsername] = useState("");
	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");
	const [user, setUser] = useState(null);

	const handleUsernameChange = (e) => setUsername(e.target.value);
	const handleOldPasswordChange = (e) => setOldPassword(e.target.value);
	const handleNewPasswordChange = (e) => setNewPassword(e.target.value);

	useEffect(() => {
		fetchUser();
	}, []);

	const fetchUser = async () => {
		try {
			const res = await getUser();
			setUser(res.data);
			setUsername(res.data.username);
		} catch (error) {
			console.error("Error fetching user:", error);
		}
	};

	const handleConfirmNewPasswordChange = (e) =>
		setConfirmNewPassword(e.target.value);

	const handleUpdatePassword = async () => {
		if (newPassword !== confirmNewPassword) {
			alert("Passwords do not match");
			return;
		}

		const userData = {
			password: newPassword,
		};

		try {
			const res = await updateUser(user._id, userData);
			if (res) {
				console.log("Password updated successfully");
				fetchUser();
			}
		} catch (error) {
			console.error("Error updating password:", error);
		}
	};

	const handleSaveUsername = async () => {
		//update user object
		const userData = {
			username: username,
		};

		try {
			const res = await updateUser(user._id, userData);
			if (res) {
				fetchUser();
				window.location.reload();
			}
		} catch (error) {
			alert("Error updating username:" + error);
		}
	};

	return (
		<Container className="bg-light py-4" style={{ minHeight: "100vh" }}>
			<h1 className="text-center">Edit Profile</h1>
			<Card className="mx-md-4 my-4">
				<Card.Body>
					<Form>
						{/* Username Field */}
						<FormGroup className="mb-3">
							<FormLabel>Username</FormLabel>
							<FormControl
								type="text"
								placeholder="Enter new username"
								value={username}
								onChange={handleUsernameChange}
							/>
						</FormGroup>
						<div className="d-flex flex-row justify-content-md-end justify-content-center">
							<Button variant="primary w-auto" onClick={handleSaveUsername}>
								Save Changes
							</Button>
						</div>
					</Form>
				</Card.Body>
			</Card>

			{!isGmailAccount && (
				<Card className="mx-md-4 my-4">
					<Card.Body>
						<Form>
							<>
								{/* Old Password Field */}
								<FormGroup className="mb-3">
									<FormLabel>Old Password</FormLabel>
									<FormControl
										type="password"
										placeholder="Enter old password"
										value={oldPassword}
										onChange={handleOldPasswordChange}
									/>
								</FormGroup>

								{/* New Password Field */}
								<FormGroup className="mb-3">
									<FormLabel>New Password</FormLabel>
									<FormControl
										type="password"
										placeholder="Enter new password"
										value={newPassword}
										onChange={handleNewPasswordChange}
									/>
								</FormGroup>

								{/* Confirm New Password Field */}
								<FormGroup className="mb-3">
									<FormLabel>Confirm New Password</FormLabel>
									<FormControl
										type="password"
										placeholder="Confirm new password"
										value={confirmNewPassword}
										onChange={handleConfirmNewPasswordChange}
									/>
								</FormGroup>
							</>

							{/* Submit Button */}
							<div className="d-flex flex-row justify-content-md-end justify-content-center">
								<Button variant="primary" onClick={handleUpdatePassword}>
									Update Password
								</Button>
							</div>
						</Form>
					</Card.Body>
				</Card>
			)}
		</Container>
	);
};

export default EditUserProfile;
