import React, { useEffect } from 'react';
import { Container, Row, Col, Button, Form, Card } from 'react-bootstrap';
import { RxHamburgerMenu } from 'react-icons/rx';
import AdminOffCanvasSidebar from './AdminOffCanvasSidebar';
import { createRider, getRiders, toggleRiderStatus } from '../../services/riderService';
import { getSeries, getSports } from '../../services/series';
import CreateRiderForm from './components/riderComponents/CreateRiderForm';
import RiderList from './components/riderComponents/RiderList';

const AdminRidersPage = () => {
	const [showSidebar, setShowSidebar] = React.useState(false);
	const [riders, setRiders] = React.useState([]);
	const [selectedRider, setSelectedRider] = React.useState(null);
	const [sports, setSports] = React.useState([]);
	const [series, setSeries] = React.useState([]);
	const [formData, setFormData] = React.useState({
		first_name: '',
		last_name: '',
		country: '',
		href: '',
		DOB: '',
		team: '',
		team_short: '',
		gender: '',
		ratings: [],
		selectedSports: [],
		selectedSeries: [],
	});

	useEffect(() => {
		fetchRiders();
		fetchSports();
		fetchSeries();
	}, []);

	const fetchRiders = async () => {
		// Fetch all riders
		try {
			const res = await getRiders();

			if (res) {
				setRiders(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const fetchSports = async () => {
		try {
			const res = await getSports();
			if (res) {
				setSports(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const fetchSeries = async () => {
		try {
			const res = await getSeries();
			if (res) {
				setSeries(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const riderData = {
			...formData,
			sports: formData.selectedSports,
			series: formData.selectedSeries,
		};

		try {
			const res = await createRider(riderData);
			if (res.status === 201) {
				fetchRiders();
			}
		} catch (error) {
			alert('Error creating rider:' + error);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleToggleRider = async (rider) => {
		// Toggle the status of the rider
		try {
			const res = await toggleRiderStatus(rider._id);
			if (res.status === 200) {
				console.log('Status toggled successfully');

				fetchRiders();
				//Update the rider list
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleSportAndSeriesChange = (e) => {
		const { name, value, options } = e.target;

		const selectedValues = Array.from(options)
			.filter((option) => option.selected)
			.map((option) => option.value);

		setFormData({
			...formData,
			[name]: selectedValues,
		});

		console.log(formData);
	};

	const handleRatingChange = (e) => {
		const { name, value } = e.target;
		const sportId = name.split('-')[1];
		setFormData({
			...formData,
			ratings: [
				...formData.ratings.filter((rating) => rating.sport !== sportId),
				{
					sport: sportId,
					rating: value,
				},
			],
		});
	};

	return (
		<Container className='p-4 bg-light'>
			<Row>
				<Col>
					<Button variant='primary' onClick={() => setShowSidebar(true)}>
						<RxHamburgerMenu />
					</Button>
					<AdminOffCanvasSidebar
						show={showSidebar}
						handleClose={() => setShowSidebar(false)}
					/>
				</Col>
			</Row>
			<RiderList
				riders={riders}
				setRider={setSelectedRider}
				toggleRider={handleToggleRider}
				sports={sports}
				series={series}
			/>
			<CreateRiderForm
				riderData={selectedRider}
				setSelectedRider={setSelectedRider}
				fetchRiders={fetchRiders}
			/>
		</Container>
	);
};

export default AdminRidersPage;
