import apiInstance from './api';

export const getAds = async () => {
	return apiInstance.get('/ads');
};

export const getActiveAd = async () => {
	return apiInstance.get('/ads/active');
};

export const createAd = async (adData, name) => {
	return apiInstance.post(`/ads/${name}`, adData);
};

export const updateAd = async (adId, adData) => {
	return apiInstance.put(`/ads/${adId}`, adData);
};
