import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Dropdown } from "react-bootstrap";
import { createLeague } from "../../../services/leagueService";
import { FaUsers } from "react-icons/fa6";
import {
	getGameModeAssignments,
	getGameModes,
} from "../../../services/adminService";
import { GoLock } from "react-icons/go";

const CreateLeagueModal = ({
	show,
	handleClose,
	events,
	sports,
	seriesList,
	handleCreateLeague,
}) => {
	const [leagueData, setLeagueData] = useState({
		name: "",
		description: "",
		sport: "",
		series: "",
		privacy: "public",
		prize: "",
		losing_prize: "",
	});

	const [filteredSports, setFilteredSports] = useState([]);
	const [gameModeAssignments, setGameModeAssignments] = useState([]);

	useEffect(() => {
		fetchGameAssignments();
	}, []);

	useEffect(() => {
		if (leagueData.series) {
			// Filter sports based on events for the current series
			const sportsForSeries = sports.filter((sport) =>
				events.some(
					(event) =>
						event.series._id === leagueData.series &&
						event.sport._id === sport._id,
				),
			);

			const sportsForAssignments = sportsForSeries.filter((sport) =>
				gameModeAssignments.some(
					(assignment) =>
						assignment.sport._id === sport._id &&
						assignment.series._id === leagueData.series,
				),
			);
			setFilteredSports(sportsForAssignments);
		} else {
			setFilteredSports([]);
		}
	}, [leagueData.series, sports, events]);

	const fetchGameAssignments = async () => {
		try {
			const res = await getGameModeAssignments();
			setGameModeAssignments(res.data);
		} catch (error) {
			console.error("Error fetching game modes:", error);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === "series") {
			// Preserve the selected series when changing the sport
			setLeagueData({ ...leagueData, [name]: value });
		} else {
			setLeagueData({
				...leagueData,
				[name]: value,
				series: leagueData.series,
			}); // Preserve the selected series
		}
	};

	const handleSportChange = (sportId) => {
		setLeagueData({ ...leagueData, sport: sportId });
	};

	const handleSeriesChange = (seriesId) => {
		setLeagueData({ ...leagueData, series: seriesId });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			handleCreateLeague(leagueData);
		} catch (error) {
			console.error("Error creating league:", error);
		}
	};

	return (
		<Modal show={show} size={"lg"} onHide={handleClose}>
			<Modal.Header closeButton className="w-100 bg-success text-white">
				<Modal.Title>
					{" "}
					<FaUsers className="mb-1 me-2" />
					Create New League
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="px-5 ">
				<Form onSubmit={handleSubmit}>
					<Form.Group className="mb-3" controlId="name">
						<Form.Label>Name</Form.Label>
						<Form.Control
							type="text"
							placeholder="Enter league name"
							name="name"
							value={leagueData.name}
							onChange={handleChange}
							required
						/>
					</Form.Group>

					<Form.Group className="mb-3" controlId="description">
						<Form.Label>Description</Form.Label>
						<Form.Control
							as="textarea"
							rows={3}
							placeholder="Enter description"
							name="description"
							value={leagueData.description}
							onChange={handleChange}
						/>
					</Form.Group>

					<Form.Group className="mb-3" controlId="series">
						<Form.Label>Series</Form.Label>
						<Dropdown>
							<Dropdown.Toggle variant="outline-dark" className="w-100">
								{leagueData.series
									? seriesList.find(
											(series) => series._id === leagueData.series,
									  )?.name
									: "Select Series"}
							</Dropdown.Toggle>
							<Dropdown.Menu>
								{seriesList.map((series) => (
									<Dropdown.Item
										key={series._id}
										onClick={() => handleSeriesChange(series._id)}
									>
										{series.name}
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
					</Form.Group>

					<Form.Group className="mb-3" controlId="sport">
						<Form.Label>Discipline</Form.Label>
						<Dropdown>
							<Dropdown.Toggle variant="outline-dark" className="w-100">
								{leagueData.sport
									? filteredSports.find(
											(sport) => sport._id === leagueData.sport,
									  )?.name
									: "Select Discipline"}
							</Dropdown.Toggle>
							<Dropdown.Menu>
								{filteredSports.map((sport) => (
									<Dropdown.Item
										key={sport._id}
										onClick={() => handleSportChange(sport._id)}
									>
										{sport.name}
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
						{filteredSports.length == 0 && leagueData.series && (
							<p className="text-center text-muted mt-1">
								<GoLock className="mb-1 me-2" />
								Coming soon...
							</p>
						)}
					</Form.Group>

					<Form.Group className="mb-3" controlId="privacy">
						<Form.Label>Privacy</Form.Label>
						<Form.Select
							name="privacy"
							value={leagueData.privacy}
							onChange={handleChange}
						>
							<option value="public">Public</option>
							<option value="private">Private</option>
						</Form.Select>
						{leagueData.privacy === "private" && (
							<Form.Text className="text-muted">
								Private leagues will have their own private URL.
							</Form.Text>
						)}
					</Form.Group>

					<Form.Group className="mb-3" controlId="prize">
						<Form.Label>Prize</Form.Label>
						<Form.Control
							type="text"
							placeholder="Enter prize"
							name="prize"
							value={leagueData.prize}
							onChange={handleChange}
						/>
					</Form.Group>

					<Form.Group className="mb-3" controlId="losing_prize">
						<Form.Label>Losing Prize</Form.Label>
						<Form.Control
							type="text"
							placeholder="Enter losing prize"
							name="losing_prize"
							value={leagueData.losing_prize}
							onChange={handleChange}
						/>
					</Form.Group>
					<div className="d-flex flex-row justify-content-center">
						<Button
							variant="primary"
							className="py-3 px-4 w-auto"
							type="submit"
						>
							Create League
						</Button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default CreateLeagueModal;
