import React from 'react';
import { Container, Card, ListGroup } from 'react-bootstrap';

const PrivacyPolicyPage = () => {
	return (
		<Container>
			<Card className='my-4'>
				<Card.Header as='h5'>Privacy Policy</Card.Header>
				<Card.Body style={{ fontSize: '0.7rem' }}>
					<Card.Title>www.theracecompanion.com</Card.Title>
					<Card.Text>
						This website is owned and operated by Kurtis Gassewitz, Davi Birks,
						and Olly Wilkins. Kurtis Gassewitz is the data controller and can be
						contacted at:
						<a href='mailto:kurtisgassewitz@gmail.com'>
							kurtisgassewitz@gmail.com
						</a>
					</Card.Text>
					<Card.Subtitle className='mt-3 fw-bold'>Purpose</Card.Subtitle>
					<ListGroup variant='flush'>
						<ListGroup.Item>The personal data we will collect</ListGroup.Item>
						<ListGroup.Item>Use of collected personal data</ListGroup.Item>
						<ListGroup.Item>
							Who has access to the data collected
						</ListGroup.Item>
						<ListGroup.Item>The rights of the site users</ListGroup.Item>
						<ListGroup.Item>The site's cookie policy</ListGroup.Item>
					</ListGroup>
					<Card.Text className='mt-3'>
						The privacy policy applies in addition to the terms and conditions
						of our Site.
					</Card.Text>
					<Card.Subtitle className='mt-3  fw-bold'>GDPR</Card.Subtitle>
					<Card.Text>
						For users in the European Union, we adhere to the Regulation (EU)
						2016/679 of the European Parliament and of the Council of 27 April
						2016, known as the General Data Protection Regulation (GDPR). For
						users in the UK, we adhere to the GDPR as enshrined in the Data
						Protection Act 2018.
					</Card.Text>
					<Card.Text>
						We have not appointed a Data Protection Officer as we do not fall
						within the categories of controllers and processors required to
						appoint a Data Protection Officer under Article 37 of GDPR.
					</Card.Text>
					<Card.Subtitle className='mt-3  fw-bold'>Consent</Card.Subtitle>
					<Card.Text>
						By using our site, users agree that they consent to the conditions
						set out in this Privacy Policy.
					</Card.Text>
					<Card.Subtitle className='mt-3  fw-bold'>
						Legal Basis for Processing
					</Card.Subtitle>
					<Card.Text>
						We collect and process personal data about users in the EU only when
						we have a legal basis for doing so under Article 6 of the GDPR.
					</Card.Text>
					<Card.Text>
						We rely on the following legal basis to collect and process the
						personal data of users in the EU:
					</Card.Text>
					<ListGroup variant='flush'>
						<ListGroup.Item>
							Processing of user personal data is necessary for us to take, at
							the request of a user, steps before entering into a contract or
							for the performance of a contract to which a user is a party.
						</ListGroup.Item>
					</ListGroup>
					<Card.Text className='mt-3'>
						If a user does not provide the necessary personal data to perform a
						contract the consequences are as follows: The user may not be able
						to authorize and authenticate, and will not be able to participate
						or be eligible for a prize.
					</Card.Text>

					<Card.Subtitle className='mt-3  fw-bold'>
						Personal Data We Collect
					</Card.Subtitle>
					<Card.Text>
						When you visit and use our site, we may automatically collect and
						store the following information:
					</Card.Text>
					<ListGroup variant='flush'>
						<ListGroup.Item>IP Address</ListGroup.Item>
						<ListGroup.Item>Location</ListGroup.Item>
						<ListGroup.Item>Clicked links</ListGroup.Item>
						<ListGroup.Item>Content Viewed</ListGroup.Item>
					</ListGroup>

					<Card.Subtitle className='mt-3  fw-bold'>
						How We Use Personal Data
					</Card.Subtitle>
					<Card.Text>
						The data we collect automatically is used for the following
						purposes:
					</Card.Text>
					<ListGroup variant='flush'>
						<ListGroup.Item>Statistics</ListGroup.Item>
						<ListGroup.Item>Contact for winning a prize</ListGroup.Item>
					</ListGroup>

					<Card.Subtitle className='mt-3  fw-bold'>Children</Card.Subtitle>
					<Card.Text>
						The minimum age to compete for global prizes is 18 years of age. We
						do not knowingly collect or use personal data from children under 16
						years of age.
					</Card.Text>

					<Card.Subtitle className='mt-3  fw-bold'>Cookie Policy</Card.Subtitle>
					<Card.Text>
						A cookie is a small file, stored on a user’s hard drive by a
						website. Its purpose is to be used for user authentication. This
						cookie does not collect personal data as it is a first-party cookie.
						We do not use third-party cookies that track any browsing habits.
					</Card.Text>
					<Card.Text>
						If you choose to disable cookies in your browser, the site may not
						work as expected.
					</Card.Text>

					{/* Add any additional sections as needed */}
				</Card.Body>
			</Card>
		</Container>
	);
};

export default PrivacyPolicyPage;
