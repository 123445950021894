import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal, Card, Spinner } from "react-bootstrap";
import { getEvents } from "../../services/eventService";
import HomeTopRow from "./components/HomeTopRow";
import { getVideosByType } from "../../services/videoService";
import VideoScrollbar from "./components/VideoScrollBar";
import "./HomePage.css";
import EventCard from "../Fantasy/Components/EventCard";
import MobileAd from "../Ads/MobileAd";
import TopAd from "../Ads/TopAd";
import RightAd from "../Ads/RightAd";
import LeftAd from "../Ads/LeftAd";
import { Helmet } from "react-helmet";
import TopRowSeries from "./components/TopRowSeries";
import { getSeries } from "../../services/series";
import TopRowSport from "./components/TopRowSport";
import { CSSTransition } from "react-transition-group";

const HomePage = () => {
	// State to manage modal visibility
	const [showModal, setShowModal] = React.useState(false);
	const [selectedVideoUrl, setSelectedVideoUrl] = React.useState("");
	const [events, setEvents] = React.useState([]);
	const [featureVideos, setFeatureVideos] = React.useState([]);
	const [podcastVideos, setPodcastVideos] = React.useState([]);
	const [scrollbarVideos, setScrollbarVideos] = React.useState([]);
	const [isMobile, setIsMobile] = React.useState(false);
	const [series, setSeries] = React.useState([]);
	const [selectedSeries, setSelectedSeries] = React.useState(null);
	const [event, setEvent] = useState(null);
	const [sports, setSports] = useState([]);
	const [selectedSport, setSelectedSport] = useState(null);

	const filteredEvents = events.filter((event) => {
		const matchesSeries = selectedSeries
			? event.series._id === selectedSeries._id
			: true;
		const matchesSport = selectedSport
			? event.sport._id === selectedSport._id
			: true;
		return matchesSeries && matchesSport;
	});
	const futureEvents = filteredEvents.filter(
		(event) =>
			new Date(event.date) > new Date(Date.now() - 2 * 24 * 60 * 60 * 1000),
	);
	// @ts-ignore
	futureEvents.sort((a, b) => new Date(a.date) - new Date(b.date));
	const nearestFutureEvent = futureEvents[0];

	const eventSports = filteredEvents.reduce((acc, event) => {
		if (!acc.some((sport) => sport.name === event.sport.name)) {
			acc.push(event.sport);
		}
		return acc;
	}, []);

	useEffect(() => {
		fetchEvents();
		fetchFeatureVideos();
		fetchPodcastVideos();
		fetchScrollbarVideos();
	}, []);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		handleResize(); // Initial check
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const fetchFeatureVideos = async () => {
		try {
			const res = await getVideosByType("featured");
			if (res) {
				setFeatureVideos(res);
			}
		} catch (error) {
			console.error("Error fetching feature videos:", error);
		}
	};

	const fetchPodcastVideos = async () => {
		try {
			const res = await getVideosByType("podcast");
			if (res) {
				setPodcastVideos(res);
			}
		} catch (error) {
			console.error("Error fetching podcast videos:", error);
		}
	};

	const fetchScrollbarVideos = async () => {
		try {
			const res = await getVideosByType("scrollbar");
			if (res) {
				setScrollbarVideos(res);
			}
		} catch (error) {
			console.error("Error fetching scrollbar videos:", error);
		}
	};

	const handleSelectedSeries = async (series) => {
		setSelectedSeries(series);
		setSelectedSport(null);
	};

	const handleSelectSport = (sport) => {
		setSelectedSport(sport);
	};

	useEffect(() => {
		setEvent(nearestFutureEvent);

		//if event sports is greater than 1, then set sports to event sports.
		if (eventSports.length > 1) {
			setSports(eventSports);
		} else {
			setSports([]);
		}
	}, [selectedSeries]);

	useEffect(() => {
		setEvent(nearestFutureEvent);
	}, [selectedSport]);

	const fetchEvents = async () => {
		try {
			const res = await getEvents();
			if (res) {
				// filter events to only show future events
				const _futureEvents = res.data.filter(
					(event) =>
						new Date(event.date) >
						new Date(Date.now() - 2 * 24 * 60 * 60 * 1000),
				);

				setEvents(_futureEvents);
				setEvent(nearestFutureEvent);

				const response = await getSeries();
				if (!response) {
					return;
				}

				//Want to filter series to events that have this series.
				const eventSeries = response.data.filter((series) => {
					return _futureEvents.some((event) => event.series._id === series._id);
				}, []);

				setSeries(eventSeries);

				const eventSports = _futureEvents.reduce((acc, event) => {
					if (!acc.some((sport) => sport.name === event.sport.name)) {
						acc.push(event.sport);
					}
					return acc;
				}, []);

				if (eventSports.length > 1) {
					setSports(eventSports);
				}
			}
		} catch (error) {
			console.error("Error fetching events:", error);
		}
	};

	const openModalWithVideo = (videoUrl) => {
		setSelectedVideoUrl(videoUrl);
		setShowModal(true);
	};

	return (
		<Container fluid className="w-100 mx-0 px-0" style={{ overflow: "hidden" }}>
			<Helmet>
				<title>Homepage - The Race Companion</title>
				<meta
					name="description"
					content="The Race Companion Hompage. Fantasy and news for Enduro, Downhill and Slopestyle mountain biking.  Follow along with Crankworx, UCI Downhill & Enduro, Red Bull Hardline and Red Bull Rampage."
				/>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Helmet>
			<Row className="m-0 p-0">{isMobile ? <MobileAd /> : <TopAd />}</Row>
			{/* Row of Events */}
			{Array.isArray(series) && series?.length > 0 && (
				<TopRowSeries
					series={series}
					selectedSeries={selectedSeries}
					handleSelectSeries={handleSelectedSeries}
				/>
			)}
			<HomeTopRow
				events={filteredEvents}
				setSelectedEvent={setEvent}
				selectedEvent={event}
			/>
			<Row noGutters>
				<Col lg={2} className="d-none d-lg-block text-start">
					<LeftAd />
				</Col>
				<Col xs={12} lg={8} className="bg-light" style={{ zIndex: 1000 }}>
					{/* {sports && sports?.length > 0 && ( */}
					<CSSTransition
						in={sports && sports.length > 0}
						timeout={500}
						classNames="transition"
						unmountOnExit
					>
						<div>
							<TopRowSport
								sports={sports}
								selectedSport={selectedSport}
								handleSelectSport={handleSelectSport}
							/>
						</div>
					</CSSTransition>
					{/* )} */}
					{event && event?._id && <EventCard event={event} />}
					{/* Featured Video */}
					<div
						className="featured-video-wrapper mb-5"
						style={{
							position: "relative",
							paddingBottom: "56.25%",
							height: 0,
							overflow: "hidden",
						}}
					>
						{featureVideos[0]?.videoUrl ? (
							<iframe
								style={{
									position: "absolute",
									top: 0,
									left: 0,
									width: "100%",
									height: "100%",
								}}
								src={`${featureVideos[0]?.videoUrl}/?autoplay=1&mute=1&controls=1&enablejsapi=1&modestbranding=1&color=white`}
								frameBorder="0"
								// allow='accelerometer; autoplay; mute; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen'
								title="Feature Video"
							></iframe>
						) : (
							<Spinner animation="border" variant="primary" />
						)}
					</div>
				</Col>
				<Col lg={2} className="d-none d-lg-block text-end">
					<RightAd />
				</Col>
			</Row>
			{/* Whitespace */}
			<div style={{ height: "20px" }} />
			<Container fluid className="bg-dark">
				{/* Thumbnails Row */}
				<Container className="py-5">
					<div className="line-with-title-container mb-5">
						<hr style={{ color: "white" }}></hr>
						<div className="title bg-dark text-white">Featured</div>
					</div>
					<VideoScrollbar videos={scrollbarVideos} />
					<div className="line-with-title-container my-5">
						<hr style={{ color: "white" }}></hr>
						<div className="title bg-dark text-white">The Ride Companion</div>
					</div>
					<VideoScrollbar videos={podcastVideos} />
				</Container>
			</Container>

			{/* Video Modal */}
			<Modal
				show={showModal}
				onHide={() => setShowModal(false)}
				size="lg"
				centered
			>
				<Modal.Body>
					<iframe
						src={selectedVideoUrl}
						title="Video"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
				</Modal.Body>
			</Modal>
		</Container>
	);
};

export default HomePage;
