import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { TbMathFunction } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import getCountryISO2 from "country-iso-3-to-2";
import ReactCountryFlag from "react-country-flag";
import { getGameModeAssignment } from "../../../services/adminService";
import { calculateMultiplier } from "../../../utils/utils";
import { FaEdit, FaList } from "react-icons/fa";

const MyTeam = ({ team, sport, series, leagueId }) => {
	const navigate = useNavigate();
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

	const [gameAssignment, setGameAssignment] = useState(null);

	useEffect(() => {
		fetchGameAssignment();
	}, [sport, series]);

	const fetchGameAssignment = async () => {
		try {
			const res = await getGameModeAssignment({
				sport: sport._id,
				series: series._id,
			});
			if (res) {
				setGameAssignment(res.data[0].gameMode);
			}
		} catch (error) {
			console.error("Error fetching game mode assignment:", error);
		}
	};

	return (
		<Card className="border-0 shadow mt-3">
			<Card.Header className="bg-success text-white pt-3 ps-4">
				<Row>
					<Col>
						<h5>
							{" "}
							<FaList className="mb-1 me-2" /> My Team
						</h5>
					</Col>
					<Col className="text-end">
						<Button
							variant="primary"
							size="sm"
							onClick={() =>
								navigate(
									`/editteam?sport=${sport?._id}&series=${series?._id}&leagueId=${leagueId}`,
								)
							}
						>
							{" "}
							<FaEdit className="mb-1 me-2" />
							Edit Team
						</Button>
					</Col>
				</Row>
			</Card.Header>
			<Card.Body>
				{team &&
					gameAssignment?.name.toLowerCase() == "multiplier mode" &&
					Array.isArray(team.riders) &&
					team.riders.length > 0 &&
					team.riders.map((rider) => {
						return (
							<Card className="mb-1 shadow">
								<Card.Body className="py-1">
									<Row className="align-items-center py-0 my-0 text-sm-start text-center">
										<Col md={4} className="text-start my-2 my-sm-0">
											{getCountryISO2(
												rider.rider.country.toUpperCase().trim(),
											) ? (
												<ReactCountryFlag
													countryCode={getCountryISO2(
														rider.rider.country.toUpperCase().trim(),
													)}
													svg
													style={{ width: "3.5rem" }}
												/>
											) : (
												rider.rider.country
											)}
											<b>{rider.rider.last_name}</b> {rider.rider.first_name}{" "}
											<span style={{ fontSize: "0.6rem" }}>
												({rider.rider.gender})
											</span>
										</Col>
										<Col xs={6} md={2} className="">
											{rider.rider.DOB}
										</Col>
										<Col xs={6} md={6} className="text-end">
											{rider.rider.ratings.map((rating) => {
												if (rating.sport.toString() === sport._id) {
													return (
														<span key={rating._id}>
															<TbMathFunction className="mb-1 me-2" />
															{Math.floor(calculateMultiplier(rating.rating))}
														</span>
													);
												}
												return null;
											})}
										</Col>
									</Row>
								</Card.Body>
							</Card>
						);
					})}
				{team &&
					(gameAssignment?.name.toLowerCase() == "pick and rank" ||
						gameAssignment?.name.toLowerCase() == "pick and rank hardline") &&
					Array.isArray(team.riders) &&
					team.riders.length > 0 &&
					team.riders.map((rider) => {
						return (
							<Card className="mb-1 shadow">
								<Card.Body className="py-1">
									<Row className="align-items-center py-0 my-0 text-start text-md-center">
										<Col md={6} className="text-start my-2 my-sm-0">
											<b>{rider.rank}.</b>
											{getCountryISO2(
												rider?.rider?.country.toUpperCase().trim(),
											) ? (
												<ReactCountryFlag
													countryCode={getCountryISO2(
														rider?.rider?.country.toUpperCase().trim(),
													)}
													svg
													style={{ width: "3.5rem" }}
												/>
											) : (
												rider?.rider?.country
											)}
											<b>{rider?.rider?.last_name}</b>{" "}
											{rider?.rider?.first_name}{" "}
											<span style={{ fontSize: "0.6rem" }}>
												({rider?.rider?.gender})
											</span>
										</Col>
										{!isMobile && (
											<Col md={6} className="text-sm-end text-center">
												{rider?.rider?.DOB}
											</Col>
										)}
									</Row>
								</Card.Body>
							</Card>
						);
					})}

				{team &&
					gameAssignment?.name.toLowerCase() == "budget mode" &&
					Array.isArray(team.riders) &&
					team.riders.length > 0 &&
					team.riders.map((rider) => {
						return (
							<Card className="mb-1 shadow">
								<Card.Body className="py-1">
									<Row className="align-items-center py-0 my-0 text-start text-md-center">
										<Col md={6} className="text-start my-2 my-sm-0">
											{getCountryISO2(
												rider?.rider?.country.toUpperCase().trim(),
											) ? (
												<ReactCountryFlag
													countryCode={getCountryISO2(
														rider?.rider?.country.toUpperCase().trim(),
													)}
													svg
													style={{ width: "3.5rem" }}
												/>
											) : (
												<span className="ms-3 me-3">{rider.rider.country}</span>
											)}
											<b>{rider.rider.last_name}</b> {rider.rider.first_name}{" "}
											<span style={{ fontSize: "0.6rem" }}>
												({rider.rider.gender})
											</span>
										</Col>
										{!isMobile && (
											<Col md={6} className="text-sm-end text-center">
												{rider.rider.DOB}
											</Col>
										)}
									</Row>
								</Card.Body>
							</Card>
						);
					})}

				{team && team.selectedTeam && (
					<Card className="mb-1 mt-3 shadow border-primary">
						<Card.Body className="py-2">
							<Row className="align-items-center py-0 my-0 text-center text-md-center">
								<Col className="text-center my-2 my-sm-0 fw-bold">
									{team.selectedTeam}
								</Col>
							</Row>
						</Card.Body>
					</Card>
				)}
			</Card.Body>
		</Card>
	);
};

export default MyTeam;
