import React, { useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { forgotPassword } from '../../services/authService';

const ForgotPassword = () => {
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [error, setError] = useState('');

	const sendResetLink = async (e) => {
		e.preventDefault();
		setMessage('');
		setError('');

		try {
			const response = await forgotPassword(email);
			if (response.data) {
				setMessage('A reset password link has been sent to your email.');
			}
		} catch (error) {
			setError('Failed to send reset password link. Please try again later.');
			console.error('Error sending reset password link:', error);
		}
	};

	return (
		<div className='forgot-password-page container mt-4'>
			<h2>Forgot Your Password?</h2>
			<p>
				Enter your email address and we will send you a link to reset your
				password.
			</p>
			{error && <Alert variant='danger'>{error}</Alert>}
			{message && <Alert variant='success'>{message}</Alert>}
			<Form onSubmit={sendResetLink}>
				<Form.Group controlId='email'>
					<Form.Label>Email Address</Form.Label>
					<Form.Control
						type='email'
						placeholder='Enter your email'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						required
					/>
				</Form.Group>
				<Button variant='primary' type='submit' className='mt-3'>
					Send Reset Password Link
				</Button>
			</Form>
		</div>
	);
};

export default ForgotPassword;
