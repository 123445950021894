import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Button, Alert } from "react-bootstrap";
import { resetPassword } from "../../services/authService";
import axios from "axios";

const ResetPasswordPage = () => {
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [error, setError] = useState("");
	const [success, setSuccess] = useState(false);
	const { token } = useParams(); // Extracting token from the URL
	const navigate = useNavigate();

	const handleResetPassword = async (e) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			setError("Passwords do not match");
			return;
		}

		try {
			const res = await resetPassword(token, password);
			if (res.status === 200) {
				setSuccess(true);
				setTimeout(() => navigate("/login"), 3000); // Redirect to login after 3 seconds
			}
		} catch (error) {
			setError("Failed to reset password. Please try again later.");
			console.error("Error resetting password:", error);
		}
	};

	return (
		<div className="reset-password-page container">
			<h2 className="mt-3 text-center">Reset Your Password</h2>
			{error && <Alert variant="danger">{error}</Alert>}
			{success && (
				<Alert variant="success">
					Password reset successfully. Redirecting to login...
				</Alert>
			)}
			<Form onSubmit={handleResetPassword} className="">
				<Form.Group controlId="password">
					<Form.Label>New Password</Form.Label>
					<Form.Control
						type="password"
						placeholder="Enter new password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required
					/>
				</Form.Group>
				<Form.Group controlId="confirmPassword" className="mt-3">
					<Form.Label>Confirm New Password</Form.Label>
					<Form.Control
						type="password"
						placeholder="Confirm new password"
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
						required
					/>
				</Form.Group>
				<Button variant="primary" type="submit" className="mt-3">
					Reset Password
				</Button>
			</Form>
		</div>
	);
};

export default ResetPasswordPage;
