import React, { useEffect, useState } from "react";
import {
	Container,
	Button,
	Card,
	Form,
	FormControl,
	Col,
	Row,
	Table,
} from "react-bootstrap";
import AdminOffCanvasSidebar from "./AdminOffCanvasSidebar";
import { getSeries, getSports } from "../../services/series";
import { RxHamburgerMenu } from "react-icons/rx";
import {
	createEvent,
	getEvent,
	getEvents,
	updateEvent,
	uploadImage,
} from "../../services/eventService";
import { getEventAnalytics } from "../../services/resultService";

const AdminEventsPage = () => {
	const [eventName, setEventName] = useState("");
	const [eventList, setEventList] = useState([]);
	const [date, setDate] = useState("");
	const [location, setLocation] = useState("");
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [seriesDropdown, setSeriesDropdown] = useState([]);
	const [selectedSeries, setSelectedSeries] = useState("");
	const [sportDropdown, setSportDropdown] = useState([]);
	const [selectedSport, setSelectedSport] = useState("");
	const [description, setDescription] = useState("");
	const [country, setCountry] = useState("");
	const [showSidebar, setShowSidebar] = useState(false);
	const [image, setImage] = useState(null);
	const [trackImage, setTrackImage] = useState(null);
	const [eventUrl, setEventUrl] = useState("");
	const [analytics, setAnalytics] = useState([]);

	useEffect(() => {
		getAllSeries();
		getAllSports();
		getAllEvents();
	}, []);

	const getAllSeries = async () => {
		try {
			const res = await getSeries();
			if (res) {
				setSeriesDropdown(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getAllSports = async () => {
		try {
			const res = await getSports();
			if (res) {
				setSportDropdown(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getAllEvents = async () => {
		try {
			const res = await getEvents();
			if (res) {
				setEventList(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getAnalytics = async () => {
		try {
			const res = await getEventAnalytics(selectedEvent._id);
			if (res) {
				setAnalytics(res.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleImageChange = (e) => {
		setImage(e.target.files[0]);
	};

	const refreshFields = () => {
		setEventName("");
		setSelectedSport("");
		setDate("");
		setLocation("");
		setSelectedSeries("");
		setDescription("");
		setCountry("");
		setSelectedEvent(null);
	};

	const handleSelectEvent = async (event) => {
		const strippedDate = event.date.split("T")[0];
		setSelectedEvent(event);
		setCountry(event.country);
		setDate(strippedDate);
		setDescription(event.description);
		setEventName(event.name);
		setLocation(event.location);
		setSelectedSeries(event.series);
		setSelectedSport(event.sport._id);
		setEventName(event.name);
	};

	const createNewEvent = async () => {
		try {
			const res = await createEvent({
				name: eventName,
				sport: selectedSport,
				date,
				location,
				series: selectedSeries,
				country: country,
				description,
				eventUrl,
			});
			if (res) {
				console.log(res);
				//reset state on all fields
				setEventName("");
				setSelectedSport("");
				setDate("");
				setLocation("");
				setSelectedSeries("");
				setDescription("");
				setCountry("");
				setEventUrl("");
				getAllEvents();
			}
		} catch (error) {
			console.log(error);
		}
	};

	const updateEventAsync = async () => {
		try {
			const res = await updateEvent(selectedEvent._id, {
				name: eventName,
				sport: selectedSport,
				date,
				location,
				series: selectedSeries,
				country: country,
				description,
				eventUrl,
			});
			if (res) {
				console.log(res);
				//reset state on all fields
				setEventName("");
				setSelectedSport("");
				setDate("");
				setLocation("");
				setSelectedSeries("");
				setDescription("");
				setCountry("");
				setEventUrl("");
				setSelectedEvent(null);

				getAllEvents();
			}
		} catch (error) {
			console.log(error);
			alert("Error updating event");
		}
	};

	const handleSubmitImage = async (e) => {
		e.preventDefault();
		try {
			const res = await uploadImage(selectedEvent._id, image);
			if (res) {
				console.log(res);
				// setImage(null);
			}
		} catch (error) {
			alert("Error uploading image");
		}
	};

	return (
		<Container className="bg-light py-3 px-4">
			<Row>
				<Col>
					<Button variant="primary" onClick={() => setShowSidebar(true)}>
						<RxHamburgerMenu />
					</Button>
					<AdminOffCanvasSidebar
						show={showSidebar}
						handleClose={() => setShowSidebar(false)}
					/>
				</Col>
			</Row>
			{/* Show All Current events */}
			<Card className="mt-4 p-4 shadow border-0">
				<Card.Title className="fw-bold mb-3 ms-4">Current Events</Card.Title>
				<Card.Body
					style={{ maxHeight: "30rem", overflow: "auto", fontSize: "0.8rem" }}
				>
					<Table
						className=""
						striped
						bordered
						hover
						// responsive
					>
						<thead>
							<tr>
								<th>Name</th>
								<th>Sport</th>
								<th>Series</th>
								<th>Date</th>
								<th>Location</th>
								<th>Country</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{eventList.map((event) => (
								<tr key={event._id}>
									<td>{event.name}</td>
									<td>{event.sport.name}</td>
									<td>{event.series.name}</td>
									<td>{event.date}</td>
									<td>{event.location}</td>
									<td>{event.country}</td>
									<td>
										<Button
											variant="outline-primary"
											size={"sm"}
											onClick={() => handleSelectEvent(event)}
										>
											Select
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
			<div className="d-flex flex-row justify-content-center">
				<Button
					variant="outline-primary"
					className="mt-3"
					onClick={getAnalytics}
				>
					{" "}
					Get Analytics
				</Button>
			</div>

			<Card className="mt-4 shadow border-0">
				<Card.Body>
					<Card.Title className="fw-bold ms-3 my-3">
						{selectedEvent?._id ? "Update" : "Create"} a Event
					</Card.Title>
					<Form>
						<Form.Group controlId="formBasicEmail" className="mb-3">
							<Form.Label>Event Name</Form.Label>
							<FormControl
								type="text"
								placeholder="Enter event name"
								value={eventName}
								onChange={(e) => setEventName(e.target.value)}
							/>
						</Form.Group>
						<Form.Group controlId="formBasicEmail" className="mb-3">
							<Form.Label>Sport</Form.Label>
							<Form.Control
								as="select"
								value={selectedSport}
								onChange={(e) => setSelectedSport(e.target.value)}
							>
								<option value="">Select a sport</option>
								{sportDropdown.map((sportOption) => (
									<option key={sportOption._id} value={sportOption._id}>
										{sportOption.name}
									</option>
								))}
							</Form.Control>
						</Form.Group>
						<Form.Group controlId="formBasicEmail" className="mb-3">
							<Form.Label>Date</Form.Label>
							<FormControl
								type="date"
								placeholder="Enter date"
								value={date}
								onChange={(e) => {
									console.log(e.target.value);
									setDate(e.target.value);
								}}
							/>
						</Form.Group>
						<Form.Group controlId="formBasicEmail" className="mb-3">
							<Form.Label>Location</Form.Label>
							<FormControl
								type="text"
								placeholder="Enter location"
								value={location}
								onChange={(e) => setLocation(e.target.value)}
							/>
						</Form.Group>
						<Form.Group controlId="formBasicEmail" className="mb-3">
							<Form.Label>Series</Form.Label>
							<Form.Control
								as="select"
								// @ts-ignore
								value={selectedSeries ? selectedSeries._id : ""}
								onChange={(e) => {
									setSelectedSeries(e.target.value);
								}}
							>
								<option value="">Select a series</option>
								{seriesDropdown.map((seriesOption) => (
									<option key={seriesOption._id} value={seriesOption._id}>
										{seriesOption.name}
									</option>
								))}
							</Form.Control>
						</Form.Group>
						<Form.Group controlId="formBasicEmail" className="mb-3">
							<Form.Label>Country</Form.Label>
							<FormControl
								type="text"
								placeholder="Enter country"
								value={country}
								onChange={(e) => setCountry(e.target.value)}
							/>
						</Form.Group>
						<Form.Group controlId="formBasicEmail" className="mb-3">
							<Form.Label>Description</Form.Label>
							<FormControl
								as="textarea"
								placeholder="Enter description"
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							/>
						</Form.Group>
						<Form.Group controlId="formBasicEmail" className="mb-3">
							<Form.Label>Event Site URL</Form.Label>
							<FormControl
								type="text"
								placeholder="Enter event site url"
								value={eventUrl}
								onChange={(e) => setEventUrl(e.target.value)}
							/>
						</Form.Group>

						{selectedEvent && (
							<Form className="text-end mb-2">
								<Form.Group controlId="formFile" className="mb-3">
									<Form.Label>Main Image</Form.Label>
									<Form.Control type="file" onChange={handleImageChange} />
								</Form.Group>
								<Button
									variant="secondary"
									size={"sm"}
									onClick={handleSubmitImage}
								>
									Upload Image
								</Button>
							</Form>
						)}
					</Form>
					<Card.Footer
						className="d-flex flex-row align-items-end justify-content-end"
						style={{ backgroundColor: "white" }}
					>
						{selectedEvent?._id ? (
							<>
								<Button
									onClick={refreshFields}
									variant="outline-secondary"
									className="me-2"
								>
									Refresh
								</Button>
								<Button
									variant="primary"
									className="me-3"
									onClick={updateEventAsync}
								>
									Update Event
								</Button>
							</>
						) : (
							<Button variant="primary" className="" onClick={createNewEvent}>
								Create Event
							</Button>
						)}
					</Card.Footer>
				</Card.Body>
			</Card>
		</Container>
	);
};

export default AdminEventsPage;
