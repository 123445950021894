import axios from 'axios';
import apiInstance from './api';

const API_URL = process.env.REACT_APP_API_URL;

export const getLeagues = async (params) => {
	return apiInstance.get(`/leagues`, { params });
};

export const getUserLeagues = async (params) => {
	return apiInstance.get(`/leagues/user`, { params });
};

export const getLeague = async (leagueId) => {
	return apiInstance.get(`/leagues/${leagueId}`);
};

export const createLeague = async (leagueData) => {
	return apiInstance.post(`/leagues`, leagueData);
};

export const updateLeague = async (leagueId, leagueData) => {
	return apiInstance.put(`/leagues/${leagueId}`, leagueData);
};

export const deleteLeague = async (leagueId) => {
	return apiInstance.delete(`/leagues/${leagueId}`);
};

export const addAdmin = async (leagueId, adminId) => {
	return apiInstance.put(`/leagues/${leagueId}/admins`, { admin: adminId });
};

export const removeAdmin = async (leagueId, adminId) => {
	return apiInstance.delete(`/leagues/${leagueId}/admins/${adminId}`);
};

export const joinLeague = async (leagueId) => {
	return apiInstance.post(`/leagues/${leagueId}/join`);
};

export const leaveLeague = async (leagueId, team) => {
	return apiInstance.put(`/leagues/${leagueId}/leave`, { team: team });
};

export const uploadImage = async (leagueId, formData) => {
	return apiInstance.post(`/leagues/upload/${leagueId}`, formData);
};

export const getGlobalLeague = async (sport, series) => {
	return apiInstance.get(`/leagues/global/${sport}/${series}`);
};

export const getLeagueAdmin = async (leagueId) => {
	return apiInstance.get(`/leagues/${leagueId}/admins`);
};
