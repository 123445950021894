// src/utils/api.js
import Axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const URL = process.env.REACT_APP_API_URL;

const apiInstance = Axios.create({
    baseURL: `${URL}`,
    withCredentials: true // Ensure cookies are sent with every request
});

apiInstance.interceptors.request.use((config) => {
    const jwtCookie = cookies.get('jwt');
    if (jwtCookie) {
        config.headers.Authorization = `Bearer ${jwtCookie}`;
    }
    return config;
});

export default apiInstance;
