import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import PointsBreakdown from "./PointsBreakdown";

const Leaderboard = ({ leaderboard }) => {
	const [team, setTeam] = useState(null);
	const [showModal, setShowModal] = useState(false);

	return (
		<div className="">
			<Table striped bordered hover style={{ fontSize: "0.7rem" }}>
				<thead>
					<tr>
						<th>Username</th>
						<th>Points</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{Array.isArray(leaderboard.teams) &&
						leaderboard.teams.map((user, index) => (
							<tr key={index} style={{ maxHeight: "1rem" }}>
								<td className="p-0 pt-1 my-0" style={{ maxHeight: "1rem" }}>
									<span className="pe-2">
										{index == 0
											? "🏆"
											: index == 1
											? "🥈"
											: index == 2
											? "🥉"
											: ""}
									</span>
									{user.userName}
								</td>
								<td className="p-0 pt-1 my-0" style={{ maxHeight: "5px" }}>
									{user.totalPoints}
								</td>
								<td className="py-0 my-0" style={{ maxHeight: "5px" }}>
									<Button
										variant="link"
										size={"sm"}
										style={{ fontSize: "0.7rem" }}
										onClick={() => {
											setTeam(user);
											setShowModal(true);
										}}
									>
										Points Breakdown
									</Button>
								</td>
							</tr>
						))}
				</tbody>
			</Table>
			<PointsBreakdown
				leaderboardTeam={team}
				showModal={showModal}
				handleClose={() => setShowModal(false)}
			/>
		</div>
	);
};

export default Leaderboard;
