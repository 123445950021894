import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getGameModeAssignment } from "../../services/adminService";
import { getTeam, getTeams } from "../../services/teamService";
import MultiplierMode from "./components/MutliplierMode";
import { getRiders } from "../../services/riderService";
import { Button, Container, Modal, Spinner } from "react-bootstrap";
import PickAndRankMode from "./components/PickAndRankMode";
import { getGlobalLeague } from "../../services/leagueService";
import { GoRepoLocked } from "react-icons/go";
import { getUser } from "../../services/userService";
import { Helmet } from "react-helmet";
import { getDisabledSportSeries } from "../../services/disableService";
import BudgetMode from "./components/BudgetMode";

const EditTeamPage = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(location.search);

	const sport = searchParams.get("sport");
	const series = searchParams.get("series");
	const teamId = searchParams.get("teamId");
	const leagueId = searchParams.get("leagueId");
	const [loading, setLoading] = useState(false);
	const [globalLeague, setGlobalLeague] = useState(null);
	const [user, setUser] = useState(null);
	const [showSignInModal, setShowSignInModal] = useState(false);
	const [disable, setDisable] = useState(false);
	const [showDisabledModal, setShowDisabledModal] = useState(false);
	const [selectedTeam, setSelectedTeam] = useState("");

	const [eligibleRiders, setEligibleRiders] = useState([]);
	const [gameMode, setGameMode] = useState({
		_id: "",
		name: "",
		numberOfMen: 0,
		numberOfWomen: 0,
		value: 0,
		description: "",
	});
	const [team, setTeam] = useState(null);

	useEffect(() => {
		fetchGameModeAssignment();
		fetchTeam();
		fetchEligibleRiders();
		fetchGlobalLeague();
		fetchUser();
		fetchDisabled();
	}, []);

	const fetchGameModeAssignment = async () => {
		try {
			const params = { sport, series };
			if (!params) {
				alert(
					"An error occured while fetching game mode assignment. Please try again.",
				);
				return;
			}

			const res = await getGameModeAssignment(params);
			if (res) {
				setGameMode(res.data[0].gameMode);
			}
		} catch (error) {
			console.error("Error fetching game mode assignment:", error);
		}
	};

	const fetchDisabled = async () => {
		try {
			const res = await getDisabledSportSeries(sport, series);
			if (!res.data[0]) {
				return;
			}
			setDisable(res.data[0].disable);
		} catch (error) {
			console.error("Error fetching disable:", error);
		}
	};

	const fetchUser = async () => {
		try {
			const res = await getUser();
			if (res) {
				setUser(res.data);
			}
		} catch (error) {
			setShowSignInModal(true);
			console.error("Error fetching user:", error);
		}
	};

	const fetchTeam = async () => {
		try {
			const params = { sport, series };

			const res = await getTeams(params);
			if (res && res.data.length > 0) {
				setTeam(res.data[0]);
			}
		} catch (error) {
			console.error("Error fetching team:", error);
		}
	};

	const fetchEligibleRiders = async () => {
		try {
			const params = { sport, series };
			const res = await getRiders(params);
			if (res) {
				setEligibleRiders(res.data);
			}
		} catch (error) {
			console.error("Error fetching eligible riders:", error);
		}
	};

	const fetchGlobalLeague = async () => {
		try {
			setLoading(true);
			const res = await getGlobalLeague(sport, series);
			if (res) {
				setGlobalLeague(res.data);
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error("Error fetching global league:", error);
		}
	};

	return (
		<Container className="bg-light p-4" style={{ minHeight: "100vh" }}>
			<Helmet>
				<title>Edit Team - The Race Companion</title>
				<meta
					name="description"
					content="Create your dream team for Downhill, Enduro and Slopestyle Mountain Biking. Follow along with Crankworx, World cups, Red Bull Hardline and Red Bull Rampage. Choose from the best riders in the world and compete with your friends in the global league."
				/>
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Helmet>
			{/* Change component based on the game mode. 		 */}
			{gameMode?.name.toLowerCase() == "multiplier mode" && (
				<MultiplierMode
					numberOfMen={gameMode?.numberOfMen}
					numberOfWomen={gameMode?.numberOfWomen}
					eligibleRiders={eligibleRiders}
					team={team}
					setTeam={setTeam}
					globalLeague={globalLeague}
					disable={disable}
				/>
			)}
			{gameMode?.name.toLowerCase() == "budget mode" && (
				<BudgetMode
					numberOfMen={gameMode?.numberOfMen}
					numberOfWomen={gameMode?.numberOfWomen}
					budget={gameMode?.value}
					eligibleRiders={eligibleRiders}
					team={team}
					setTeam={setTeam}
					globalLeague={globalLeague}
					disable={disable}
				/>
			)}
			{gameMode?.name.toLowerCase() == "pick and rank" && (
				<PickAndRankMode
					numberOfMen={gameMode?.numberOfMen}
					numberOfWomen={gameMode?.numberOfWomen}
					eligibleRiders={eligibleRiders}
					initialTeam={team}
					globalLeague={globalLeague}
					disable={disable}
				/>
			)}
			{gameMode?.name.toLowerCase() == "pick and rank hardline" && (
				<PickAndRankMode
					numberOfMen={gameMode?.numberOfMen}
					numberOfWomen={gameMode?.numberOfWomen}
					eligibleRiders={eligibleRiders}
					initialTeam={team}
					globalLeague={globalLeague}
					disable={disable}
				/>
			)}
			{loading && <Spinner animation="border" role="status"></Spinner>}
			{!gameMode?._id && !loading && (
				<div className="d-flex flex-column align-items-center justify-content-center mb-4 text-muted">
					{" "}
					<GoRepoLocked />
					<div className="mt-2">Game Mode coming soon...</div>
				</div>
			)}
			<Modal show={showSignInModal} onHide={() => setShowSignInModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Sign In Required</Modal.Title>
				</Modal.Header>
				<Modal.Body>Please sign in to edit your team.</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={() => navigate("/login")}>
						Sign In
					</Button>
				</Modal.Footer>
			</Modal>
		</Container>
	);
};

export default EditTeamPage;
