import React, { useEffect, useRef, useState } from "react";
import { Button, Card } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import { FaCalendar } from "react-icons/fa";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { LuDot } from "react-icons/lu";

const HomeTopRow = ({
	events,
	setSelectedEvent = (event) => {},
	selectedEvent = null,
}) => {
	const scrollContainerRef = useRef(null);
	const [isMobile, setIsMobile] = useState(false);

	const scroll = (scrollOffset) => {
		scrollContainerRef.current.scrollLeft += scrollOffset;
	};

	const futureEvents = events.filter(
		(event) =>
			new Date(event.date) > new Date(Date.now() - 2 * 24 * 60 * 60 * 1000),
	);

	// Sort the future events by date in ascending order
	// @ts-ignore
	futureEvents.sort((a, b) => new Date(a.date) - new Date(b.date));

	// Get the nearest future event
	const nearestFutureEvent = futureEvents[0];

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768); // Adjust threshold as needed
		};

		handleResize(); // Initial check
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		if (!selectedEvent) {
			setSelectedEvent(nearestFutureEvent);
		}
	}, [events]);

	const handleSelectEvent = (event) => {
		setSelectedEvent(event);
	};

	return (
		<div
			className="bg-dark"
			style={{
				display: "flex",
				alignItems: "center",
				height: "6rem",
				overflow: "hidden",
			}}
		>
			<Button
				variant="dark"
				onClick={() => scroll(-1022)}
				className="mx-md-4 mx-1"
			>
				&lt;
			</Button>
			<div
				ref={scrollContainerRef}
				style={{
					overflowX: isMobile ? "auto" : "hidden",
					whiteSpace: "nowrap",
					flex: "1",
				}}
				className="bg-dark text-white"
			>
				{
					<div
						style={{
							height: "100px",
							width: "1px",
							backgroundColor: "darkgray",
							display: "inline-block",
							verticalAlign: "middle",
						}}
					/>
				}
				{events.map((event, index) => (
					<React.Fragment key={event?._id}>
						<div
							style={{
								display: "inline-block",
								width: "16rem",
								margin: "0.5rem",
								verticalAlign: "middle",
							}}
						>
							<Card
								className={
									`${
										event?._id === nearestFutureEvent?._id
											? "text-white"
											: "text-white"
									}` + " border-0 bg-dark"
								}
								onClick={() => handleSelectEvent(event)}
								style={{ cursor: "pointer" }}
							>
								<Card.Body className="pb-1 pt-2">
									<Card.Text className="my-0" style={{ fontSize: "0.7rem" }}>
										<FaCalendar className="me-2 mb-1" />
										{event.date.split("T")[0]}
										<span style={{ color: "gray" }}>
											<LuDot className="mb-1 ms-1" />
											{new Date(event.date) > new Date() ? " UPCOMING" : ""}
										</span>
									</Card.Text>
									<Card.Text
										className="my-0"
										style={{
											overflowX: "hidden",
											whiteSpace: "nowrap",
											textOverflow: "ellipsis",
										}}
									>
										<b>{event.series.name}: </b> {event.location}
									</Card.Text>
									<Card.Text
										style={{ fontSize: "0.7rem" }}
										className="pb-0 pt-1 mt-0"
									>
										<div className="d-flex flex-row justify-content-between">
											<div className="d-flex flex-row justify-content-start align-items-center">
												<ReactCountryFlag
													countryCode={event.country}
													svg
													className="mb-1 me-2"
													style={{ width: "1.5em", height: "1.5em" }}
												/>
												{event.country}
												<BiDotsVerticalRounded className="mx-2 mb-0" />
												{event.sport.name}
											</div>
											{/* {selectedEvent && selectedEvent?._id !== event?._id && (
												<Button
													variant='light'
													size='sm'
													className='py-0 ms-5'
													style={{ fontSize: '0.7rem' }}
													onClick={() => handleSelectEvent(event)}
												>
													Select
												</Button>
											)} */}
										</div>
									</Card.Text>
								</Card.Body>
								{selectedEvent?._id == event?._id && (
									<Card.Footer
										className="bg-primary pt-1 pb-0"
										style={{}}
									></Card.Footer>
								)}
							</Card>
						</div>
						{
							<div
								style={{
									height: "100px",
									width: "1px",
									backgroundColor: "darkgray",
									display: "inline-block",
									verticalAlign: "middle",
								}}
							/>
						}
					</React.Fragment>
				))}
			</div>

			<Button
				variant="dark"
				onClick={() => scroll(1022)}
				className="mx-md-4 mx-1"
			>
				&gt;
			</Button>
		</div>
	);
};

export default HomeTopRow;
