import React, { useEffect, useState } from 'react';
import { Table, Button, Form, Row, Col, Container } from 'react-bootstrap';
import {
	createWatch,
	deleteWatch,
	getWatch,
	updateWatch,
} from '../../services/watchService';

const AdminWatchManager = () => {
	const [watchList, setWatchList] = useState([]);
	const [selectedWatch, setSelectedWatch] = useState(null);
	const [name, setName] = useState('');
	const [url, setUrl] = useState('');
	const [description, setDescription] = useState('');

	useEffect(() => {
		fetchWatchList();
	}, []);

	const fetchWatchList = async () => {
		try {
			const res = await getWatch();
			setWatchList(res.data);
		} catch (error) {
			console.error('Error fetching watch list:', error);
		}
	};

	const selectWatch = (watch) => {
		setSelectedWatch(watch._id);
		setName(watch.name);
		setUrl(watch.url);
		setDescription(watch.description);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const payload = { name, url, description };

		if (selectedWatch) {
			// Call API to update
			const res = await updateWatch(selectedWatch._id, payload);
		} else {
			// Call API to create
			const res = await createWatch(payload);
		}

		// Refresh the list
		fetchWatchList();
		// Reset form
		setSelectedWatch(null);
		setName('');
		setUrl('');
		setDescription('');
	};

	const handleDelete = async (watch) => {
		try {
			const res = await deleteWatch(watch._id);
			fetchWatchList();
		} catch (error) {
			console.error('Error deleting watch:', error);
		}
	};

	return (
		<Container>
			<Table striped bordered hover>
				<thead>
					<tr>
						<th>Name</th>
						<th>Description</th>
						<th>URL</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{watchList.map((watch) => (
						<tr key={watch._id}>
							<td>{watch.name}</td>
							<td>{watch.description}</td>
							<td>{watch.url}</td>
							<td>
								<Row>
									<Button variant='success' onClick={() => selectWatch(watch)}>
										Edit
									</Button>
									<Button
										variant='outline-dark'
										className='mt-2'
										onClick={() => handleDelete(watch)}
									>
										Delete
									</Button>
								</Row>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
			<Form onSubmit={handleSubmit}>
				<Row className='mb-3'>
					<Col>
						<Form.Group controlId='formName'>
							<Form.Label>Name</Form.Label>
							<Form.Control
								type='text'
								placeholder='Enter name'
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</Form.Group>
					</Col>
					<Col>
						<Form.Group controlId='formURL'>
							<Form.Label>URL</Form.Label>
							<Form.Control
								type='text'
								placeholder='Enter URL'
								value={url}
								onChange={(e) => setUrl(e.target.value)}
							/>
						</Form.Group>
					</Col>
				</Row>
				<Form.Group className='mb-3' controlId='formDescription'>
					<Form.Label>Description</Form.Label>
					<Form.Control
						as='textarea'
						rows={3}
						placeholder='Enter description'
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
				</Form.Group>
				<Button variant='success' type='submit'>
					{selectedWatch ? 'Update' : 'Create'}
				</Button>
			</Form>
		</Container>
	);
};

export default AdminWatchManager;
