import apiInstance from './api';

export const getWatch = async () => {
	try {
		const res = await apiInstance.get('/watch');
		return res;
	} catch (error) {
		console.error('Error fetching watch:', error);
	}
};

export const updateWatch = async (id, data) => {
	try {
		const res = await apiInstance.put(`/watch/${id}`, data);
		return res;
	} catch (error) {
		console.error('Error updating watch:', error);
	}
};

export const createWatch = async (data) => {
	try {
		const res = await apiInstance.post('/watch', data);
		return res;
	} catch (error) {
		console.error('Error creating watch:', error);
	}
};

export const deleteWatch = async (id) => {
	try {
		const res = await apiInstance.delete(`/watch/${id}`);
		return res;
	} catch (error) {
		console.error('Error deleting watch:', error);
	}
};
