import React, { useEffect, useState } from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import VideoSection from './components/videoComponents/videoSection';
import {
	createVideo,
	getVideos,
	getVideosByType,
	updateVideo,
} from '../../services/videoService';

function VideoAdminPage() {
	const [featuredVideos, setFeaturedVideos] = useState([]);
	const [podcastVideos, setPodcastVideos] = useState([]);
	const [scrollbarVideos, setScrollbarVideos] = useState([]);
	const [thumbnailUrl, setThumbnailUrl] = useState('');
	const [videoUrl, setVideoUrl] = useState('');

	// Fetch videos from backend
	useEffect(() => {
		// You should replace the URLs with your actual API endpoint
		fetchFeaturedVideos();
		fetchPodcastVideos();
		fetchScrollbarVideos();
	}, []);

	// Dummy fetch functions, replace with actual API calls
	const fetchFeaturedVideos = async () => {
		// Fetch featured videos
		try {
			const res = await getVideosByType('featured');
			console.log('res:', res);
			setFeaturedVideos(res);
		} catch (error) {
			console.error('Error fetching featured videos:', error);
		}
	};

	const fetchPodcastVideos = async () => {
		// Fetch podcast videos
		try {
			const res = await getVideosByType('podcast');
			setPodcastVideos(res);
		} catch (error) {
			console.error('Error fetching podcast videos:', error);
		}
	};

	const fetchScrollbarVideos = async () => {
		// Fetch scrollbar videos
		try {
			const res = await getVideosByType('scrollbar');
			setScrollbarVideos(res);
		} catch (error) {
			console.error('Error fetching scrollbar videos:', error);
		}
	};

	const extractVideoID = (url) => {
		const regExp =
			/^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
		const match = url.match(regExp);

		return match && match[2].length === 11 ? match[2] : null;
	};

	// Function to generate thumbnail URL and initiate update
	const generateThumbnail = () => {
		if (!videoUrl) {
			alert('Please enter a video URL');
			return;
		}

		const videoID = extractVideoID(videoUrl);
		if (videoID) {
			const thumbnailUrl = `https://img.youtube.com/vi/${videoID}/sddefault.jpg`;
			console.log('thumbnailUrl:', thumbnailUrl);
			setThumbnailUrl(thumbnailUrl);
		} else {
			console.error('Invalid YouTube URL');
		}
	};

	const handleUpdateVideo = async (event, videoId) => {
		event.preventDefault();
		const videoData = {
			title: event.target.title.value,
			videoUrl: event.target.videoUrl.value,
			thumbnailUrl: event.target.thumbnailUrl.value,
			order: event.target.order.value,
		};

		try {
			const res = await updateVideo(videoId, videoData);
			if (res) {
				alert('Video updated successfully');
			}
		} catch (error) {
			console.error('Error updating video:', error);
			alert('Error updating video');
		}
	};

	const handleCreateVideo = async (event) => {
		event.preventDefault();
		const formData = new FormData(event.target);
		const videoData = {
			title: formData.get('title'),
			videoUrl: formData.get('videoUrl'),
			thumbnailUrl: formData.get('thumbnailUrl'),
			featured: formData.get('featured') === 'on',
			podcast: formData.get('podcast') === 'on',
			scrollbar: formData.get('scrollbar') === 'on',
		};

		try {
			const res = await createVideo(videoData);

			fetchFeaturedVideos();
			fetchPodcastVideos();
			fetchScrollbarVideos();
			clearForm();
		} catch (error) {
			console.error('Error creating video:', error);
			alert('Error creating video');
		}
	};

	const clearForm = () => {
		document.getElementById('form').reset();
	};

	// Render sections
	return (
		<div className='container py-4' style={{ minHeight: '100vh' }}>
			<Card className='mb-3'>
				<Card.Header>Upload New Video</Card.Header>
				<Card.Body>
					<Form onSubmit={handleCreateVideo} id='form'>
						<Form.Group className='mb-3' controlId='formBasicEmail'>
							<Form.Label>Title</Form.Label>
							<Form.Control
								name='title'
								type='text'
								placeholder='Enter title'
								required
							/>
						</Form.Group>

						<Form.Group className='mb-3'>
							<Form.Label>Video URL</Form.Label>
							<Form.Control
								name='videoUrl'
								type='text'
								placeholder='Video URL'
								required
								onChange={(e) => setVideoUrl(e.target.value)}
							/>
						</Form.Group>

						<Form.Group className='mb-3'>
							<Form.Label>Thumbnail URL</Form.Label>
							<Form.Control
								name='thumbnailUrl'
								type='text'
								placeholder='Thumbnail URL'
								value={thumbnailUrl}
							/>
						</Form.Group>

						<Form.Group className='mb-3' controlId='formBasicCheckbox'>
							<Form.Check name='featured' type='checkbox' label='Featured' />
							<Form.Check name='podcast' type='checkbox' label='Podcast' />
							<Form.Check name='scrollbar' type='checkbox' label='Scrollbar' />
						</Form.Group>

						<div className='d-flex flex-row justify-content-end'>
							<Button
								variant='secondary'
								type='button'
								className='me-3'
								onClick={() => generateThumbnail()}
							>
								Generate Thumbnail
							</Button>
							<Button variant='primary' type='submit' className='px-5'>
								Submit
							</Button>
						</div>
					</Form>
				</Card.Body>
			</Card>
			<VideoSection
				title='Featured Videos'
				videos={featuredVideos}
				handleUpdateVideo={handleUpdateVideo}
				setVideos={setFeaturedVideos}
			/>
			<VideoSection
				title='Podcasts'
				videos={podcastVideos}
				handleUpdateVideo={handleUpdateVideo}
				setVideos={setPodcastVideos}
			/>
			<VideoSection
				title='Scrollbar Videos'
				videos={scrollbarVideos}
				handleUpdateVideo={handleUpdateVideo}
				setVideos={setScrollbarVideos}
			/>
		</div>
	);
}

export default VideoAdminPage;
