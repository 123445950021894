import React, { useEffect } from 'react';
import { Container, Row, Col, Accordion, Button, Card } from 'react-bootstrap';
import LeftAd from '../Ads/LeftAd';
import MobileAd from '../Ads/MobileAd';
import RightAd from '../Ads/RightAd';
import TopAd from '../Ads/TopAd';
import HomeTopRow from '../HomePage/components/HomeTopRow';
import { getEvents } from '../../services/eventService';
import { getWatch } from '../../services/watchService';

const Watch = () => {
	const [events, setEvents] = React.useState([]);
	const [isMobile, setIsMobile] = React.useState(false);
	const [watchList, setWatchList] = React.useState([]);

	useEffect(() => {
		fetchEvents();
		fetchWatchList();
	}, []);

	const fetchEvents = async () => {
		try {
			const res = await getEvents();
			setEvents(res.data);
		} catch (error) {
			console.error('Error fetching events:', error);
		}
	};

	const fetchWatchList = async () => {
		try {
			const res = await getWatch();
			setWatchList(res.data);
		} catch (error) {
			console.error('Error fetching watch list:', error);
		}
	};

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		handleResize(); // Initial check
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);
	return (
		<Container fluid className='w-100 mx-0 px-0'>
			<Row className='m-0 p-0'>{isMobile ? <MobileAd /> : <TopAd />}</Row>
			{/* Row of Events */}
			<HomeTopRow events={events} />

			<Row noGutters>
				<Col lg={2} className='d-none d-lg-block text-start'>
					<LeftAd />
				</Col>
				<Col xs={12} lg={8} className='bg-light' style={{ zIndex: 1000 }}>
					<Card className='bg-light border-0 mt-3'>
						<Card.Body className=''>
							<Card.Title className='text-center fs-2'>
								Where to Watch
							</Card.Title>
						</Card.Body>
					</Card>
					<Accordion className='mt-4'>
						{watchList.map((watch, index) => (
							<Accordion.Item key={index} eventKey={index.toString()}>
								<Accordion.Header>
									<div className='fs-5 text-success'>{watch.name}</div>
								</Accordion.Header>
								<Accordion.Body>
									<div className=''>{watch.description}</div>
									{watch.url && (
										<div className='text-md-end text-center'>
											<Button
												variant='primary'
												onClick={() => {
													window.open(watch.url, '_blank');
												}}
												className=''
											>
												Watch Now
											</Button>
										</div>
									)}
								</Accordion.Body>
							</Accordion.Item>
						))}
					</Accordion>
				</Col>
				<Col lg={2} className='d-none d-lg-block text-end'>
					<RightAd />
				</Col>
			</Row>
		</Container>
	);
};

export default Watch;
