import React, { useEffect, useState } from "react";
import {
	Modal,
	Button,
	Tabs,
	Tab,
	Col,
	ListGroup,
	Row,
	Card,
	Table,
	Spinner,
} from "react-bootstrap";
import {
	getSnapshots,
	getSnapshotsByTeam,
} from "../../../services/snapshotService";
import { getGameModeAssignment } from "../../../services/adminService";
import { getTeam } from "../../../services/teamService";
import ReactCountryFlag from "react-country-flag";
import getCountryISO2 from "country-iso-3-to-2";
import {
	getResults,
	getResultsBySportAndSeries,
} from "../../../services/resultService";
import {
	calculateMultiplier,
	calculatePickAndRankPoints,
} from "../../../utils/utils";
import { MdLeaderboard } from "react-icons/md";
import { getTeamResults } from "../../../services/teamResultService";

const PointsBreakdown = ({ leaderboardTeam, showModal, handleClose }) => {
	const [snapshots, setSnapshots] = useState([]);
	const [gameModeAssignment, setGameModeAssignment] = useState(null);
	const [numberMen, setNumberMen] = useState(0);
	const [numberWomen, setNumberWomen] = useState(0);
	const [team, setTeam] = useState({});
	const [teamResults, setTeamResults] = useState([]);
	const [results, setResults] = useState([]);

	useEffect(() => {}, []);

	useEffect(() => {
		if (leaderboardTeam?.teamId) {
			fetchTeam();
		}
	}, [leaderboardTeam]);

	useEffect(() => {
		if (snapshots.length > 0) {
			fetchTeamResult(team);
		}
	}, [snapshots]);

	const fetchSnapshots = async (_team) => {
		try {
			const res = await getSnapshotsByTeam(_team._id);
			setSnapshots(res.data);
		} catch (error) {
			console.error("Error fetching snapshots:", error);
		}
	};

	const fetchTeamResult = async (_team) => {
		try {
			const res = await getTeamResults(_team._id);
			setTeamResults(res.data);
		} catch (error) {
			console.error("Error fetching team result:", error);
		}
	};

	const fetchGameModeAssignment = async (_team) => {
		try {
			const res = await getGameModeAssignment({
				sport: _team.sport,
				series: _team.series,
			});
			setGameModeAssignment(res.data[0].gameMode.name);
			setNumberMen(res.data[0].gameMode.numberOfMen);
			setNumberWomen(res.data[0].gameMode.numberOfWomen);
		} catch (error) {
			console.error("Error fetching game mode assignment:", error);
		}
	};

	const fetchTeam = async () => {
		try {
			const res = await getTeam(leaderboardTeam.teamId);
			if (res.data) {
				setTeam(res.data);
				fetchSnapshots(res.data);
				fetchGameModeAssignment(res.data);
				fetchResults(res.data.sport, res.data.series);
			}
		} catch (error) {
			console.error("Error fetching team:", error);
		}
	};

	const fetchResults = async (sport, series) => {
		try {
			const res = await getResultsBySportAndSeries(sport, series);
			setResults(res);
		} catch (error) {
			console.error("Error fetching results:", error);
		}
	};

	return (
		<Modal show={showModal} onHide={handleClose} size="lg">
			<Modal.Header closeButton>
				<Modal.Title>
					{" "}
					<MdLeaderboard className="mb-1 me-1" />
					Points Breakdown
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{snapshots.length == 0 && (
					<Spinner animation="border" className="my-3" />
				)}
				<Tabs defaultActiveKey={0} id="snapshot-tabs">
					{/* Show User Team */}
					{snapshots.length > 0 ? (
						snapshots.map((snapshot, index) => (
							<Tab eventKey={index} title={snapshot.event.location} key={index}>
								<Card className="my-3 shadow ">
									<Card.Header className="bg-success text-white">
										<div className="d-flex flex-row justify-content-between">
											<div className="">{snapshot.event.location}</div>
											<div className="">{leaderboardTeam.totalPoints}</div>
										</div>
									</Card.Header>
									<Card.Body>
										{gameModeAssignment == "Mutliplier Mode" && (
											<ListGroup>
												{snapshot.riders.map((rider, riderIndex) => (
													<ListGroup.Item
														key={rider.rider._id}
														style={{ fontSize: "0.8rem" }}
													>
														<Row>
															<Col
																md={6}
																className="text-sm-start my-0 my-sm-0"
															>
																{getCountryISO2(
																	rider?.rider?.country.toUpperCase().trim(),
																) ? (
																	<ReactCountryFlag
																		countryCode={getCountryISO2(
																			rider.rider.country.toUpperCase().trim(),
																		)}
																		svg
																		style={{ width: "3.5rem" }}
																	/>
																) : (
																	rider.rider.country
																)}
																<b>{rider.rider.last_name}</b>{" "}
																{rider.rider.first_name}
															</Col>
															<Col
																md={6}
																className="text-md-end text-center my-2 my-sm-0"
															>
																{rider.rider.status && rider.rider.status}
															</Col>
														</Row>
													</ListGroup.Item>
												))}
											</ListGroup>
										)}
										{gameModeAssignment == "Budget Mode" && (
											<div className="">
												<ListGroup>
													{snapshot.riders.map((rider, riderIndex) => (
														<ListGroup.Item
															key={rider.rider._id}
															style={{ fontSize: "0.8rem" }}
														>
															<Row>
																<Col
																	md={6}
																	className="text-sm-start my-0 my-sm-0"
																>
																	{getCountryISO2(
																		rider?.rider?.country.toUpperCase().trim(),
																	) ? (
																		<ReactCountryFlag
																			countryCode={getCountryISO2(
																				rider.rider.country
																					.toUpperCase()
																					.trim(),
																			)}
																			svg
																			style={{ width: "3.5rem" }}
																		/>
																	) : (
																		rider.rider.country
																	)}
																	<b>{rider.rider.last_name}</b>{" "}
																	{rider.rider.first_name}
																</Col>
																<Col
																	md={6}
																	className="text-md-end text-center my-2 my-sm-0"
																>
																	{rider.rider.status && rider.rider.status}
																</Col>
															</Row>
														</ListGroup.Item>
													))}
												</ListGroup>
												{snapshot.selectedTeam && (
													<Card
														className="my-3 shadow"
														style={{ fontSize: "0.9rem" }}
													>
														<Card.Header className="text-muted py-1">
															Selected Team
														</Card.Header>
														<Card.Body className="text-center py-2">
															{snapshot.selectedTeam}
														</Card.Body>
													</Card>
												)}
											</div>
										)}
										{(gameModeAssignment === "Pick and Rank" ||
											gameModeAssignment === "Pick and Rank Hardline") && (
											<>
												<ListGroup>
													{/* Display Men Riders */}
													{snapshot.riders
														.filter((rider) => rider.rider?.gender === "m")
														.map((rider, riderIndex) => (
															<ListGroup.Item
																key={rider.rider._id}
																style={{ fontSize: "0.8rem" }}
															>
																<Row>
																	<Col
																		md={6}
																		className="text-sm-start my-0 my-sm-0"
																	>
																		{/* Show chosen rider rank */}
																		{rider.rank}.{" "}
																		{getCountryISO2(
																			rider?.rider?.country
																				.toUpperCase()
																				.trim(),
																		) ? (
																			<ReactCountryFlag
																				countryCode={getCountryISO2(
																					rider.rider.country
																						.toUpperCase()
																						.trim(),
																				)}
																				svg
																				style={{ width: "3.5rem" }}
																			/>
																		) : (
																			rider.rider?.country
																		)}
																		<b>{rider.rider?.last_name}</b>{" "}
																		{rider.rider?.first_name}
																		<span
																			className=" ms-1"
																			style={{ fontSize: "0.6rem" }}
																		>
																			({rider.rider?.gender})
																		</span>
																	</Col>
																	<Col
																		md={6}
																		className="text-md-end text-center my-2 my-sm-0"
																	>
																		{rider.rider.status && rider.rider.status}
																	</Col>
																</Row>
															</ListGroup.Item>
														))}
												</ListGroup>

												<ListGroup className="mt-3">
													{/* Display Women Riders */}
													{snapshot.riders
														.filter((rider) => rider.rider?.gender === "f")
														.map((rider, riderIndex) => (
															<ListGroup.Item
																key={rider.rider._id}
																style={{ fontSize: "0.8rem" }}
															>
																<Row>
																	<Col
																		md={6}
																		className="text-sm-start my-0 my-sm-0"
																	>
																		{/* Show chosen rider rank */}
																		{rider.rank}.{" "}
																		{getCountryISO2(
																			rider?.rider?.country
																				.toUpperCase()
																				.trim(),
																		) ? (
																			<ReactCountryFlag
																				countryCode={getCountryISO2(
																					rider.rider.country
																						.toUpperCase()
																						.trim(),
																				)}
																				svg
																				style={{ width: "3.5rem" }}
																			/>
																		) : (
																			rider.rider.country
																		)}
																		<b>{rider.rider.last_name}</b>{" "}
																		{rider.rider.first_name}
																		<span
																			className=" ms-1"
																			style={{ fontSize: "0.6rem" }}
																		>
																			({rider.rider.gender})
																		</span>
																	</Col>
																	<Col
																		md={6}
																		className="text-md-end text-center my-2 my-sm-0"
																	>
																		{rider.rider.status && rider.rider.status}
																	</Col>
																</Row>
															</ListGroup.Item>
														))}
												</ListGroup>
											</>
										)}
									</Card.Body>
								</Card>

								{/* Show the results for each rider */}
								<Card className="my-3 shadow" style={{ overflow: "scroll" }}>
									<Card.Header>Results</Card.Header>
									<Card.Body>
										{gameModeAssignment == "Multiplier Mode" && (
											<Table
												striped
												bordered
												hover
												className="rounded"
												style={{ fontSize: "0.7rem", borderRadius: "10px" }}
											>
												<thead>
													<tr>
														<th>Rider</th>
														<th>Event Points</th>
														<th>Multiplier</th>
														<th>Total Points</th>
														{/* Add more headers if needed */}
													</tr>
												</thead>
												<tbody>
													{results?.length > 0 &&
														results
															.filter(
																(result) =>
																	result.event === snapshot.event._id &&
																	snapshot.riders.some(
																		(rider) =>
																			rider.rider._id === result.rider._id,
																	),
															)
															.map((filteredResult) => (
																<tr key={filteredResult._id}>
																	<td>
																		{getCountryISO2(
																			filteredResult?.rider?.country
																				.toUpperCase()
																				.trim(),
																		) ? (
																			<ReactCountryFlag
																				countryCode={getCountryISO2(
																					filteredResult.rider.country
																						.toUpperCase()
																						.trim(),
																				)}
																				svg
																				style={{ width: "3.5rem" }}
																			/>
																		) : (
																			filteredResult.rider.country
																		)}
																		<b>{filteredResult.rider.last_name}</b>{" "}
																		{filteredResult.rider.first_name}
																	</td>
																	<td>{filteredResult.total_points}</td>
																	<td>
																		{calculateMultiplier(
																			filteredResult.rider.ratings.find(
																				(rating) =>
																					rating.sport === team?.sport,
																			).rating,
																		)}
																	</td>
																	<td>
																		{" "}
																		{filteredResult.total_points *
																			calculateMultiplier(
																				filteredResult.rider.ratings.find(
																					(rating) =>
																						rating.sport === team?.sport,
																				).rating,
																			)}
																	</td>
																</tr>
															))}
												</tbody>
											</Table>
										)}
										{gameModeAssignment == "Budget Mode" && (
											<div className="">
												<Table
													striped
													bordered
													hover
													className="rounded"
													style={{ fontSize: "0.7rem", borderRadius: "10px" }}
												>
													<thead>
														<tr>
															<th>Rider</th>
															<th>Total Points</th>
															<th>Qualifying Points</th>
															<th>Semis Points</th>
															<th>Finals Points</th>
														</tr>
													</thead>
													<tbody>
														{results?.length > 0 &&
															results
																.filter(
																	(result) =>
																		result.event === snapshot.event._id &&
																		snapshot.riders.some(
																			(rider) =>
																				rider.rider._id === result.rider._id,
																		),
																)
																.map((filteredResult) => (
																	<tr key={filteredResult._id}>
																		<td>
																			{filteredResult.finals_data.rank > 0
																				? filteredResult.finals_data.rank
																				: ""}
																			.
																			{getCountryISO2(
																				filteredResult?.rider?.country
																					.toUpperCase()
																					.trim(),
																			) ? (
																				<ReactCountryFlag
																					countryCode={getCountryISO2(
																						filteredResult.rider.country
																							.toUpperCase()
																							.trim(),
																					)}
																					svg
																					style={{ width: "3.5rem" }}
																				/>
																			) : (
																				filteredResult.rider.country
																			)}
																			<b>{filteredResult.rider.last_name}</b>{" "}
																			{filteredResult.rider.first_name}
																		</td>
																		<td>{filteredResult.total_points}</td>
																		<td>
																			{filteredResult.qualifying_data.points}
																		</td>
																		<td>{filteredResult.semis_data.points}</td>
																		<td>{filteredResult.finals_data.points}</td>
																	</tr>
																))}
													</tbody>
												</Table>
												<Card
													className="my-3 shadow"
													style={{ fontSize: "0.9rem" }}
												>
													<Card.Header className="text-muted py-1">
														<div className="d-flex flex-row justify-content-between">
															<div className="">Team Points</div>
															<div className="">
																{teamResults &&
																	teamResults.length > 0 &&
																	(teamResults.find(
																		(teamResult) =>
																			teamResult.event === snapshot.event._id &&
																			teamResult.name === snapshot.selectedTeam,
																	)
																		? teamResults.find(
																				(teamResult) =>
																					teamResult.event ===
																					snapshot.event._id,
																		  ).points
																		: 0)}
															</div>
														</div>
													</Card.Header>
													<Card.Body className="text-center py-2">
														<Table
															striped
															bordered
															hover
															style={{ fontSize: "0.7rem" }}
														>
															<thead>
																<tr>
																	<th>Rider</th>
																	<th>Points</th>
																</tr>
															</thead>
															<tbody>
																{teamResults &&
																	teamResults.length > 0 &&
																	teamResults
																		.find(
																			(teamResult) =>
																				teamResult.event === snapshot.event._id,
																		)
																		?.riders?.map((rider) => (
																			<tr key={rider.rider._id}>
																				<td>
																					{getCountryISO2(
																						rider?.rider?.country
																							.toUpperCase()
																							.trim(),
																					) ? (
																						<ReactCountryFlag
																							countryCode={getCountryISO2(
																								rider.rider.country
																									.toUpperCase()
																									.trim(),
																							)}
																							svg
																							style={{ width: "3.5rem" }}
																						/>
																					) : (
																						rider.rider.country
																					)}
																					<b>{rider.rider.last_name}</b>{" "}
																					{rider.rider.first_name}
																				</td>
																				<td>{rider.points}</td>
																			</tr>
																		))}
															</tbody>
														</Table>
													</Card.Body>
												</Card>
											</div>
										)}
										{(gameModeAssignment == "Pick and Rank" ||
											gameModeAssignment == "Pick and Rank Hardline") && (
											<Table
												striped
												bordered
												hover
												style={{ fontSize: "0.7rem" }}
											>
												<thead>
													<tr>
														<th>Rider</th>
														<th>Score</th>
														<th>Chosen Rank</th>
														<th>Actual Rank</th>
														<th>Points</th>
													</tr>
												</thead>
												<tbody>
													{results?.length > 0 &&
														results
															.filter(
																(result) =>
																	result.event === snapshot.event?._id &&
																	snapshot.riders.some(
																		(rider) =>
																			rider.rider?._id === result.rider?._id,
																	),
															)
															.map((filteredResult) => (
																<tr key={filteredResult._id}>
																	<td>
																		{getCountryISO2(
																			filteredResult?.rider?.country
																				.toUpperCase()
																				.trim(),
																		) ? (
																			<ReactCountryFlag
																				countryCode={getCountryISO2(
																					filteredResult.rider.country
																						.toUpperCase()
																						.trim(),
																				)}
																				svg
																				style={{ width: "3.5rem" }}
																			/>
																		) : (
																			filteredResult.rider?.country
																		)}
																		<b>{filteredResult.rider?.last_name}</b>{" "}
																		{filteredResult.rider?.first_name}
																	</td>
																	<td>{filteredResult.finals_data.score}</td>
																	<td>
																		{/* Need to find rider in snapshot and get rank
																		 */}
																		{
																			snapshot.riders.find(
																				(rider) =>
																					rider.rider?._id ===
																					filteredResult.rider?._id,
																			).rank
																		}
																	</td>
																	<td>{filteredResult.finals_data.rank}</td>
																	<td>
																		{calculatePickAndRankPoints(
																			filteredResult.finals_data.rank,
																			snapshot.riders.find(
																				(rider) =>
																					rider.rider?._id ===
																					filteredResult.rider?._id,
																			).rank,
																			filteredResult.rider?.gender,
																			numberMen,
																			numberWomen,
																		)}
																	</td>
																</tr>
															))}
												</tbody>
											</Table>
										)}
									</Card.Body>
								</Card>
							</Tab>
						))
					) : (
						<Spinner animation="border" />
					)}
				</Tabs>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default PointsBreakdown;
