import React, { useState } from "react";
import { Table, Form, Button, Row, Col, Card } from "react-bootstrap";
import { toggleRiderStatus } from "../../../../services/riderService";

const RiderList = ({ riders, setRider, toggleRider, sports, series }) => {
	const [sportFilter, setSportFilter] = useState("");
	const [seriesFilter, setSeriesFilter] = useState("");
	const [sortOrder, setSortOrder] = useState("asc");
	const [searchQuery, setSearchQuery] = useState("");

	const handleSelectRider = (rider) => {
		// Set the selected rider
		console.log(rider);
		setRider(rider);
	};

	const filteredRiders = riders.filter((rider) => {
		// Apply filters
		if (sportFilter && rider.sports.indexOf(sportFilter) === -1) {
			return false;
		}
		if (seriesFilter && rider.series.indexOf(seriesFilter) === -1) {
			return false;
		}
		if (
			searchQuery &&
			!rider.first_name.toLowerCase().includes(searchQuery.toLowerCase())
		) {
			return false;
		}
		return true;
	});

	const sortedRiders = [...filteredRiders].sort((a, b) => {
		// Sort riders by name
		const nameA = a.first_name.toLowerCase();
		const nameB = b.first_name.toLowerCase();
		return sortOrder === "asc"
			? nameA.localeCompare(nameB)
			: nameB.localeCompare(nameA);
	});


	return (
		<div>
			{/* Filters and search */}
			<Card className="my-3 p-4 border-0 shadow">
				<Card.Title className="m-3 ms-4 fw-bold">Filter Riders</Card.Title>
				<Card.Body style={{ maxHeight: "30rem", overflow: "auto" }}>
					<Form>
						<Row className="mb-3">
							<Col sm={3}>
								<Form.Control
									as="select"
									value={sportFilter}
									onChange={(e) => setSportFilter(e.target.value)}
								>
									<option value="">All Sports</option>
									{sports.map((sport) => (
										<option key={sport._id} value={sport._id}>
											{sport.name}
										</option>
									))}
								</Form.Control>
							</Col>
							<Col sm={3}>
								<Form.Control
									as="select"
									value={seriesFilter}
									onChange={(e) => setSeriesFilter(e.target.value)}
								>
									<option value="">All Series</option>
									{series.map((series) => (
										<option key={series._id} value={series._id}>
											{series.name}
										</option>
									))}
								</Form.Control>
							</Col>
							<Col sm={3}>
								<Form.Control
									as="select"
									value={sortOrder}
									onChange={(e) => setSortOrder(e.target.value)}
								>
									<option value="asc">Sort A-Z</option>
									<option value="desc">Sort Z-A</option>
								</Form.Control>
							</Col>
							<Col sm={3}>
								<Form.Control
									type="text"
									placeholder="Search rider..."
									value={searchQuery}
									onChange={(e) => setSearchQuery(e.target.value)}
								/>
							</Col>
						</Row>
					</Form>

					{/* Rider list table */}
					<Table striped bordered hover style={{ fontSize: "0.8rem" }}>
						<thead>
							<tr>
								<th>First Name</th>
								<th>Last Name</th>
								<th>Country</th>
								<th>Team</th>
								<th>Status</th>
								<th>Select</th>
							</tr>
						</thead>
						<tbody>
							{/* Map through sorted riders */}
							{sortedRiders.map((rider) => (
								<tr key={rider._id} className="p-0 m-0">
									<td className="p-0 ps-2">{rider.first_name}</td>
									<td className="p-0 ps-2">{rider.last_name}</td>
									<td className="p-0 ps-2">{rider.country}</td>
									<td className="p-0 ps-2">{rider.team}</td>
									<td className="p-0 ps-2">{`${
										rider?.status ? rider.status : "active"
									}`}</td>
									<td className="p-0 ps-2">
										<Button
											size="sm"
											variant="outline-primary"
											onClick={() => toggleRider(rider)}
										>
											Toggle Status
										</Button>
									</td>
									<td className="p-0 ps-2">
										<Button
											size="sm"
											variant="outline-primary"
											onClick={() => handleSelectRider(rider)}
										>
											Select Rider
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
		</div>
	);
};

export default RiderList;
