import axios from "axios";
import apiInstance from "./api";

export const getLeagueComments = async (leagueId) => {
	return apiInstance.get(`/leagueComments/${leagueId}`);
}

export const createLeagueComment = async (commentData) => {
	return apiInstance.post(`/leagueComments`, commentData);
}

export const deleteLeagueComment = async (commentId) => {
	return apiInstance.delete(`/leagueComments/${commentId}`);
}

export const updateLeagueComment = async (commentId, commentData) => {
	return apiInstance.put(`/leagueComments/${commentId}`, commentData);
}

export const upvoteLeagueComment = async (commentId) => {
	return apiInstance.post(`/leagueComments/${commentId}/upvote`);
}

export const downvoteLeagueComment = async (commentId) => {
	return apiInstance.post(`/leagueComments/${commentId}/downvote`);
}