import React, { useEffect, useState } from 'react';
import { getSeries, getSports } from '../../services/series';
import { disableService, getDisabled } from '../../services/disableService';
import { Container, Row, Col, Table, Button, Form } from 'react-bootstrap';

const AdminDisableManager = () => {
	const [sports, setSports] = useState([]);
	const [sport, setSport] = useState(null);
	const [series, setSeries] = useState(null);
	const [seriesList, setSeriesList] = useState([]);
	const [disabledList, setDisabledList] = useState([]);
	const [disable, setDisable] = useState('');

	useEffect(() => {
		fetchDisabled();
		fetchSports();
		fetchSeries();
	}, []);

	const fetchDisabled = async () => {
		try {
			const res = await getDisabled();
			setDisabledList(res.data);
		} catch (error) {
			console.error('Error fetching disabled sports and series: ', error);
		}
	};

	const fetchSports = async () => {
		try {
			const res = await getSports();
			setSports(res.data);
		} catch (error) {
			console.error('Error fetching sports: ', error);
		}
	};

	const fetchSeries = async () => {
		try {
			const res = await getSeries();
			setSeriesList(res.data);
		} catch (error) {
			console.error('Error fetching series: ', error);
		}
	};

	const handleDisable = async () => {
		try {
			const res = await disableService(sport, series, disable);
			setDisable('');
			setSport(null);
			setSeries(null);
			fetchDisabled();
		} catch (error) {
			alert('Error disabling sport or series: ' + error);
		}
	};

	return (
		<Container>
			<Row>
				<Col>
					<h1>Disable Manager</h1>
				</Col>
			</Row>

			{/* List all disabled sports and series */}

			{disabledList.length > 0 && (
				<Row>
					<Col>
						<h4>Disabled Sports and Series</h4>
						<Table striped bordered hover>
							<thead>
								<tr>
									<th>Sport</th>
									<th>Series</th>
									<th>Disabled</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{disabledList.map((disable) => (
									<tr key={disable._id}>
										<td>{disable.sport.name}</td>
										<td>{disable.series.name}</td>
										<td>{disable.disable ? 'Yes' : 'No'}</td>
										<td>
											<Button
												variant='outline-dark'
												onClick={async () => {
													try {
														await disableService(
															disable.sport._id,
															disable.series._id,
															!disable.disable,
														);
														fetchDisabled();
													} catch (error) {
														alert('Error toggling disable: ' + error);
													}
												}}
											>
												Toggle
											</Button>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Col>
				</Row>
			)}

			{/* Form to disable a sport or series */}
			<Row>
				<Col>
					<h2>Disable a Sport or Series</h2>
					<Form>
						<Form.Group controlId='sport'>
							<Form.Label>Sport</Form.Label>
							<Form.Control
								as='select'
								onChange={(e) => setSport(e.target.value)}
							>
								<option value=''>Select a sport</option>
								{sports.map((sport) => (
									<option key={sport._id} value={sport._id}>
										{sport.name}
									</option>
								))}
							</Form.Control>
						</Form.Group>
						<Form.Group controlId='series'>
							<Form.Label>Series</Form.Label>
							<Form.Control
								as='select'
								onChange={(e) => setSeries(e.target.value)}
							>
								<option value=''>Select a series</option>
								{seriesList.map((series) => (
									<option key={series._id} value={series._id}>
										{series.name}
									</option>
								))}
							</Form.Control>
						</Form.Group>
						<Form.Group controlId='disable'>
							<Form.Label>Disable</Form.Label>
							<Form.Control
								as='select'
								onChange={(e) => setDisable(e.target.value)}
							>
								<option value=''>Select an option</option>
								<option value='true'>Yes</option>
								<option value='false'>No</option>
							</Form.Control>
						</Form.Group>
						<Button
							className='mt-2'
							variant='primary'
							onClick={(e) => {
								handleDisable();
								// Call the disableService function to disable the sport or series
							}}
						>
							Disable
						</Button>
					</Form>
				</Col>
			</Row>
		</Container>
	);
};

export default AdminDisableManager;
