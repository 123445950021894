import React, { useState, useEffect } from 'react';
import { Card, Button, Col, Row } from 'react-bootstrap';
import { FaUsers } from 'react-icons/fa';
import { LuDot } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import { FaGlobeAmericas } from 'react-icons/fa';

const FeaturedLeaguesComponent = ({ featuredLeagues }) => {
	const navigate = useNavigate();
	const [visibleLeagues, setVisibleLeagues] = useState(5);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

	const handleShowMore = () => {
		setVisibleLeagues((prevVisible) => prevVisible + 10);
	};

	return (
		<div className='mx-sm-4 mt-5'>
			<div className='d-flex flex-row justify-content-center mb-3'>
				<Card className='bg-light text-dark border-0'>
					<Card.Body className='py-2 px-4 fw-bold fs-5'>
						<FaGlobeAmericas className='mb-1 me-2' />
						Global Leagues
					</Card.Body>
				</Card>
			</div>
			<div className='pb-1'>
				{featuredLeagues.map((league) => (
					<Card key={league._id} className='mb-4 mx-sm-3 shadow'>
						<Card.Header className=' bg-light text-dark'>
							<Row>
								<Col md={7}>
									<Card.Title className='mt-2 ms-2'>
										<div className='d-flex flex-row justify-content-md-between justify-content-center'>
											<div>
												<FaUsers className='mb-1 me-2' />
												{league.name}
											</div>
											{!isMobile && (
												<div
													className=' text-muted'
													style={{ fontSize: '0.8rem' }}
												>
													{league.series.name} <LuDot /> {league.sport.name}
												</div>
											)}
										</div>
									</Card.Title>
								</Col>
								{isMobile && (
									<Col
										md
										className='text-center flex-grow text-muted mb-2'
										style={{ fontSize: '0.8rem' }}
									>
										{league.series.name} <LuDot /> {league.sport.name}
									</Col>
								)}
								<Col className='text-md-end text-center mt-md-0 mt-2'>
									<Button
										variant='dark'
										className='ms-auto me-2 px-3'
										onClick={() => navigate(`/league/${league._id}`)}
									>
										View League
									</Button>
								</Col>
							</Row>
						</Card.Header>
						<Card.Body>
							<Card.Text className='text-md-start text-center'>
								{league.description}
							</Card.Text>
							<Row className='d-flex flex-row justify-content-start w-100 text-md-start text-center'>
								<Col
									className='text-md-end text-center'
									style={{ fontSize: '0.8rem' }}
								>
									<Card.Text className='text-muted'>
										Teams: {league.teams.length}
									</Card.Text>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				))}
			</div>
			<div className='d-flex flex-row justify-content-center mb-3'>
				{featuredLeagues.length > visibleLeagues && (
					<Button variant='outline-success' onClick={handleShowMore}>
						Show 10 More
					</Button>
				)}
			</div>
		</div>
	);
};

export default FeaturedLeaguesComponent;
