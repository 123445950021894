import React, { useState, useEffect, useMemo } from "react";
import { Card, Button, Row, Col, Form, Dropdown, Modal } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import getCountryISO2 from "country-iso-3-to-2";
import { useLocation, useNavigate } from "react-router-dom";
import { createTeam, updateTeam } from "../../../services/teamService";
import { FaRegHandPointRight, FaGamepad } from "react-icons/fa6";
import Prizes from "../../Fantasy/Components/Prizes";
import { updateLeaderbordById } from "../../../services/leaderboardService";
import { IoWarning } from "react-icons/io5";

const PickAndRankMode = ({
	numberOfMen,
	numberOfWomen,
	eligibleRiders,
	initialTeam = null, // Assuming initialTeam is passed as a prop for editing
	globalLeague,
	disable,
}) => {
	const [team, setTeam] = useState(initialTeam ? initialTeam : { riders: [] });
	const [sortKey, setSortKey] = useState("last_name");
	const [sortOrder, setSortOrder] = useState("asc");
	const [filterGender, setFilterGender] = useState("");
	const [filterCountry, setFilterCountry] = useState("");
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
	const [showDisabledModal, setShowDisabledModal] = useState(false);

	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const sport = searchParams.get("sport");
	const series = searchParams.get("series");
	const leagueId = searchParams.get("leagueId");

	const uniqueCountries = useMemo(() => {
		const countrySet = new Set(
			eligibleRiders.map((rider) => rider.country.toUpperCase().trim()),
		);
		return Array.from(countrySet).sort();
	}, [eligibleRiders]);

	console.log("current team", team);

	useEffect(() => {
		if (initialTeam) {
			//if null rider, remove from team.
			initialTeam.riders = initialTeam.riders.filter(
				(rider) => rider.rider !== null,
			);
			setTeam({ riders: initialTeam.riders });
		}
	}, [initialTeam]);

	const addRiderToTeam = (rider) => {
		if (team.riders.length >= numberOfMen + numberOfWomen) {
			return;
		}
		//Need to get the length of team riders for gender m and gender f. Assign based on this.
		const genderCount = team.riders.filter(
			(riderObj) => riderObj.rider.gender === rider.gender,
		).length;

		setTeam({
			...team,
			riders: [...team.riders, { rider: rider, rank: genderCount + 1 }],
		});
	};

	const removeRiderFromTeam = (riderId) => {
		setTeam({
			...team,
			riders: team.riders.filter((rider) => rider.rider !== riderId),
		});
	};

	const handleSaveTeam = async () => {
		// Save the team
		try {
			if (initialTeam) {
				const teamData = {
					...initialTeam,
					riders: team.riders,
				};

				const res = await updateTeam(initialTeam._id, teamData);
				if (res) {
					if (leagueId) {
						navigate(`/league/${leagueId}`);
					} else {
						navigate("/fantasy");
					}
				}
			} else {
				const teamData = {
					league: leagueId ? leagueId : null,
					riders: team.riders,
					name: team?.name ? team.name : "",
					sport: team?.sport ? team.sport : sport,
					series: team?.series ? team.series : series,
				};

				const res = await createTeam(teamData);
				if (res) {
					if (leagueId) {
						await updateLeaderbordById(leagueId);
						navigate(`/league/${leagueId}`);
					} else {
						navigate("/fantasy");
					}
				}
			}
		} catch (error) {
			console.error("Error saving team:", error);
		}
	};

	const updateRiderRank = (riderId, newRank) => {
		let newRiders = team.riders.map((rider) =>
			rider.rider._id === riderId ? { ...rider, rank: newRank } : rider,
		);

		// Sort and re-rank to avoid duplicates
		newRiders.sort((a, b) => a.rank - b.rank);
		setTeam({ ...team, riders: newRiders });
	};

	const sortedAndFilteredRiders = eligibleRiders
		.filter(
			(rider) =>
				(filterGender ? rider.gender === filterGender : true) &&
				(filterCountry
					? rider.country.toLowerCase() === filterCountry.toLowerCase()
					: true),
		)
		.sort((a, b) => {
			let compareA =
				sortKey === "rating"
					? a.ratings.find((r) => r.sport.toString() === sport)?.rating || 0
					: a[sortKey];
			let compareB =
				sortKey === "rating"
					? b.ratings.find((r) => r.sport.toString() === sport)?.rating || 0
					: b[sortKey];

			if (sortOrder === "asc") {
				return compareA > compareB ? 1 : -1;
			} else {
				return compareA < compareB ? 1 : -1;
			}
		});

	const isAddDisabled = (gender, rider) => {
		const isAlreadyInTeam = team.riders.some(
			(teamRider) => teamRider.rider?._id === rider?._id,
		);
		if (isAlreadyInTeam) return true;

		const genderCount = team.riders.filter(
			(r) => r.rider?.gender === gender,
		).length;
		return gender === "m"
			? genderCount >= numberOfMen
			: genderCount >= numberOfWomen;
	};

	// Check if any two riders of the same gender have the same rank
	const hasDuplicateRank = (riders) => {
		return riders.some((rider, index) => {
			const duplicateIndex = riders.findIndex((r, i) => {
				return r.rank === rider.rank && i !== index;
			});
			return duplicateIndex !== -1;
		});
	};
	//Check to see none of the ranks are the same;
	const isSaveDisabled = () => {
		// Separate male and female riders
		const maleRiders = team.riders.filter(
			(rider) => rider?.rider?.gender === "m",
		);
		const femaleRiders = team.riders.filter(
			(rider) => rider?.rider?.gender === "f",
		);

		// Check if save should be disabled
		return (
			team.riders.length !== numberOfMen + numberOfWomen ||
			hasDuplicateRank(maleRiders) ||
			hasDuplicateRank(femaleRiders)
		);
	};

	const renderTeamRiders = (gender) => {
		return (
			Array.isArray(team.riders) &&
			team.riders
				// First, map over the team riders to include full rider details from eligibleRiders
				.map((teamRider) => ({
					...teamRider,
					...eligibleRiders.find(
						(rider) => rider?._id === teamRider?.rider?._id,
					),
				}))
				// Then filter based on the specified gender
				.filter((rider) => rider?.gender === gender)
				// Sort by rank
				.sort((a, b) => a.rank - b.rank)
				.map((rider, index) => (
					<Card className="mb-1 shadow">
						<Card.Body className="py-1 ">
							<Row key={rider.rider} className="align-items-center">
								<Col md={2}>
									<Form.Select
										size="sm"
										className="mb-2 mb-sm-0"
										value={rider.rank}
										onChange={(e) =>
											updateRiderRank(
												rider.rider._id,
												parseInt(e.target.value, 10),
											)
										}
									>
										{Array.from(
											{ length: gender === "m" ? numberOfMen : numberOfWomen },
											(_, i) => i + 1,
										).map((rank) => (
											<option key={rank} value={rank}>
												{rank}
											</option>
										))}
									</Form.Select>
								</Col>
								<Col md={6} className="text-sm-start text-center my-2 my-sm-0">
									{getCountryISO2(rider.country.toUpperCase().trim()) ? (
										<ReactCountryFlag
											countryCode={getCountryISO2(
												rider.country.toUpperCase().trim(),
											)}
											svg
											style={{ width: "3.5rem" }}
										/>
									) : (
										rider.country
									)}
									<b>{rider.last_name}</b> {rider.first_name}
								</Col>
								<Col className="text-md-end text-center" md={4}>
									<Button
										variant="outline-dark"
										size="sm"
										className="px-5"
										onClick={() => removeRiderFromTeam(rider.rider)}
									>
										Remove
									</Button>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				))
		);
	};

	return (
		<div>
			<Prizes leagues={[globalLeague]} />
			<Card className="mb-3 shadow">
				<Card.Header>
					{" "}
					<FaGamepad className="me-2 mb-1" />
					Pick and Rank Mode
				</Card.Header>
				<Card.Body style={{ fontSize: "0.8rem" }}>
					<Card.Text>
						<FaRegHandPointRight className="me-2" />
						You must select {numberOfMen + numberOfWomen} riders for your team.
					</Card.Text>
					<Card.Text>
						<FaRegHandPointRight className="me-2" />
						You must select {numberOfMen} Men, and {numberOfWomen} Women, for
						your team.
					</Card.Text>
					<Card.Text>
						<FaRegHandPointRight className="me-2" />
						Build your team and guess the podium order. Who will clinch 1st,
						2nd, 3rd... Choose wisely and lead your team to victory!
					</Card.Text>
					<Card.Text>
						<FaRegHandPointRight className="me-2" />
						+100 points for each selected rider that finishes in the top{" "}
						{numberOfMen} for men and top {numberOfWomen} for women
					</Card.Text>
					<Card.Text>
						<FaRegHandPointRight className="me-2" />
						+100 points for every podium spot guessed correctly.
					</Card.Text>
				</Card.Body>
			</Card>
			{/* My Team Section with separate listings for men and women */}
			<Card className="mb-3 shadow">
				<Card.Header>My Team - Men</Card.Header>
				<Card.Body>{renderTeamRiders("m")}</Card.Body>
			</Card>
			{numberOfWomen > 0 && (
				<Card className="mb-3">
					<Card.Header>My Team - Women</Card.Header>
					<Card.Body>{renderTeamRiders("f")}</Card.Body>
				</Card>
			)}

			<Row className="mb-3 text-end me-md-4">
				<Col>
					<Button
						className="px-4"
						disabled={isSaveDisabled()}
						onClick={() => {
							if (disable) {
								setShowDisabledModal(true);
								return;
							}

							handleSaveTeam();
						}}
					>
						Save Team
					</Button>
				</Col>
			</Row>

			{/* Eligible Riders Listing (similar to MultiplierMode but without multiplier/rank display) */}
			<Card className="shadow">
				<Card.Header>Eligible Riders</Card.Header>
				<Card.Body>
					<Row className="mb-3">
						<Col sm={3}>
							<Form.Group controlId="filterGender">
								<Form.Label>Filter by Gender</Form.Label>
								<Form.Select
									aria-label="Filter by Gender"
									value={filterGender}
									onChange={(e) => setFilterGender(e.target.value)}
								>
									<option value="">All</option>
									<option value="m">Male</option>
									<option value="f">Female</option>
								</Form.Select>
							</Form.Group>
						</Col>
						<Col sm={3}>
							<Form.Group controlId="filterCountry">
								<Form.Label>Filter by Country</Form.Label>
								<Form.Select
									aria-label="Filter by Country"
									value={filterCountry}
									onChange={(e) => setFilterCountry(e.target.value)}
								>
									<option value="">All</option>
									{uniqueCountries.map((country) => (
										<option key={country} value={country}>
											{country}
										</option>
									))}
								</Form.Select>
							</Form.Group>
						</Col>
					</Row>
					{sortedAndFilteredRiders.map((rider) => (
						<Card key={rider._id} className="mb-2 shadow">
							<Card.Body className="py-1">
								<Row className="align-items-center">
									<Col md={6} className="text-start my-2 my-sm-0">
										{getCountryISO2(rider.country.toUpperCase().trim()) ? (
											<ReactCountryFlag
												countryCode={getCountryISO2(
													rider.country.toUpperCase().trim(),
												)}
												svg
												style={{ width: "2.5rem" }}
											/>
										) : (
											rider.country
										)}
										<b>{rider.last_name}</b> {rider.first_name}{" "}
										<span style={{ fontSize: "0.6rem" }}>({rider.gender})</span>
									</Col>
									{!isMobile && (
										<Col xs={6} md={2} className="text-center">
											{rider.DOB}
										</Col>
									)}
									<Col md={4} className="text-sm-end text-center mt-sm-0 mt-2">
										<Button
											variant={
												isAddDisabled(rider.gender, rider)
													? "secondary"
													: "dark"
											}
											size="sm"
											className="px-5"
											onClick={() => addRiderToTeam(rider)}
											disabled={isAddDisabled(rider.gender, rider)}
										>
											Add to Team
										</Button>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					))}
				</Card.Body>
			</Card>
			<Modal
				show={showDisabledModal}
				onHide={() => setShowDisabledModal(false)}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<IoWarning className="me-2 mb-1" />
						Event Underway
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>An event is currently underway.</p>
					<p>
						Changes to your team will no longer be reflected in the current
						event.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="primary"
						onClick={() => {
							handleSaveTeam();
						}}
					>
						Save Team
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default PickAndRankMode;
