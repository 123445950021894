import React, { useState, useEffect } from "react";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import {
	createLeagueComment,
	downvoteLeagueComment,
	getLeagueComments,
	upvoteLeagueComment,
} from "../../../services/leagueCommentService";
import moment from "moment";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";

const LeagueComments = ({ leagueId }) => {
	const [comments, setComments] = useState([]);
	const [newComment, setNewComment] = useState("");
	const [userId, setUserId] = useState("");
	const [sortMethod, setSortMethod] = useState("date"); // 'date' or 'topRated'

	useEffect(() => {
		setUserId(localStorage.getItem("userId"));
		fetchComments();
	}, [leagueId]);

	const fetchComments = async () => {
		try {
			const res = await getLeagueComments(leagueId);
			setComments(res.data);
		} catch (error) {
			console.error("Error fetching league comments:", error);
		}
	};

	const handleCommentSubmit = async () => {
		try {
			const newLeagueComment = {
				content: newComment,
				league: leagueId,
			};
			const res = await createLeagueComment(newLeagueComment);
			setNewComment("");
			// After successfully creating a comment, fetch updated comments
			fetchComments();
		} catch (error) {
			console.error("Error creating league comment:", error);
		}
	};

	const handleUpvote = async (comment) => {
		try {
			const res = await upvoteLeagueComment(comment._id);

			if (res.status === 200) {
				fetchComments();
			} else {
				console.error("Error upvoting comment:", res.data);
			}
		} catch (error) {
			console.error("Error upvoting comment:", error);
		}
	};

	const handleDownvote = async (comment) => {
		try {
			const res = await downvoteLeagueComment(comment._id);

			if (res.status === 200) {
				fetchComments();
			}
		} catch (error) {
			console.error("Error downvoting comment:", error);
		}
	};

	const sortedComments = () => {
		return comments.slice().sort((a, b) => {
			if (sortMethod === "date") {
				return new Date(a.datePosted) - new Date(b.datePosted);
			} else if (sortMethod === "topRated") {
				return b.upVotes.length - a.upVotes.length;
			}
		});
	};

	return (
		<div className="mt-4">
			{/* Sorting dropdown or buttons */}
			<Form.Group
				controlId="sortMethod"
				className="mb-3"
				style={{ width: "fit-content" }}
			>
				<Form.Label>Sort Comments By</Form.Label>
				<Form.Control
					as="select"
					value={sortMethod}
					onChange={(e) => setSortMethod(e.target.value)}
				>
					<option value="date">Date</option>
					<option value="topRated">Top Rated</option>
				</Form.Control>
			</Form.Group>
			{/* Display existing comments */}
			{sortedComments().map((comment) => (
				<Card key={comment._id} className="mb-2 border-0 shadow">
					<Card.Body className="py-1">
						<div className="d-flex flex-row justify-content-between">
							<div className="flex-grow" style={{ overflow: "hidden" }}>
								<Row>
									<div className="" style={{ fontSize: "0.9rem" }}>
										<b>{comment.author.username} </b>
										<span className="text-muted">
											{moment(comment.datePosted).fromNow()}
										</span>
									</div>
								</Row>
								<Row>
									<Col>
										<Card.Text className="ms-2" style={{ fontSize: "0.8rem" }}>
											{comment.content}
										</Card.Text>
									</Col>
								</Row>
							</div>
							<div
								className="d-flex flex-row"
								style={{ width: "3rem", justifyContent: "space-between" }}
							>
								<div className="d-flex flex-column justify-content-center align-items-center">
									<div
										onClick={() => handleUpvote(comment)}
										className={`me-2 link-${
											comment.upVotes.includes(userId) ? "primary" : "secondary"
										} border-0"`}
									>
										<FaThumbsUp />
									</div>
									<span style={{ fontSize: "0.8rem" }}>
										{comment.upVotes.length}
									</span>
								</div>
								<div className="d-flex flex-column justify-content-center align-items-center">
									<div
										onClick={() => handleDownvote(comment)}
										className={`link-${
											comment.downVotes.includes(userId)
												? "primary"
												: "secondary"
										} border-0`}
									>
										<FaThumbsDown />
									</div>
									<span style={{ fontSize: "0.8rem" }}>
										{comment.downVotes.length}
									</span>
								</div>
							</div>
						</div>
					</Card.Body>
				</Card>
			))}
			{/* Input field for new comment */}
			<Form.Group controlId="newComment" className="mt-3">
				<Form.Label>New Comment</Form.Label>
				<Form.Control
					as="textarea"
					rows={3}
					value={newComment}
					onChange={(e) => setNewComment(e.target.value)}
				/>
			</Form.Group>
			{/* Button to submit new comment */}
			<Row className="d-flex flex-row justify-content-end w-100 my-2">
				<Button
					variant="primary"
					className="py-1 px-4 w-auto"
					onClick={handleCommentSubmit}
				>
					Submit
				</Button>
			</Row>
		</div>
	);
};

export default LeagueComments;
