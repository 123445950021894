import React, { useState, useEffect } from 'react';
import { Table, Button, Card, Container } from 'react-bootstrap';
import axios from 'axios'; // Assuming you're using axios for API requests
import {
	deleteUser,
	getUsers,
	resetPassword,
} from '../../services/userService';

const AdminUser = () => {
	const [users, setUsers] = useState([]);

	useEffect(() => {
		fetchUsers();
	}, []);

	const fetchUsers = async () => {
		try {
			const res = await getUsers();
			setUsers(res.data);
		} catch (error) {
			console.error('Failed to fetch users', error);
		}
	};

	const handleDeleteUser = async (userId) => {
		try {
			const res = await deleteUser(userId);
			fetchUsers();
		} catch (error) {
			console.error('Failed to delete user', error);
		}
	};

	const handleResetPassword = async (userId) => {
		try {
			const res = await resetPassword(userId);
			alert('Password reset successfully');
		} catch (error) {
			alert('Failed to reset password');
		}
	};

	return (
		<Container>
			<Card>
				<Card.Body>
					<Card.Title>Users</Card.Title>

					<Table striped bordered hover>
						<thead>
							<tr>
								<th>ID</th>
								<th>Username</th>
								<th>Email</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{users.map((user) => (
								<tr key={user.id}>
									<td>{user.id}</td>
									<td>{user.username}</td>
									<td>{user.email}</td>
									<td>
										<Button
											variant='outline-danger'
											size='sm'
											onClick={() => handleDeleteUser(user._id)}
										>
											Delete
										</Button>
										{user.email.split('@')[1] != 'gmail.com' && (
											<Button
												className='ms-2'
												size='sm'
												variant='dark'
												onClick={() => handleResetPassword(user._id)}
											>
												Reset Password
											</Button>
										)}
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</Card.Body>
			</Card>
		</Container>
	);
};

export default AdminUser;
