import React, { useState, useEffect } from 'react';
import { Card, Container, Row, Col, Form, Table } from 'react-bootstrap';
import { getOverallResults } from '../../services/resultService';
import { getSeries, getSports } from '../../services/series';
import getCountryISO2 from 'country-iso-3-to-2';
import ReactCountryFlag from 'react-country-flag';
import { getEvents } from '../../services/eventService';
import MobileAd from '../Ads/MobileAd';
import TopAd from '../Ads/TopAd';
import HomeTopRow from '../HomePage/components/HomeTopRow';
import LeftAd from '../Ads/LeftAd';
import RightAd from '../Ads/RightAd';
import { Helmet } from 'react-helmet';

const RankingPage = () => {
	const [series, setSeries] = useState(''); // Assuming series are identified by some ID or name
	const [sport, setSport] = useState(''); // Assuming sports are identified by some ID or name
	const [rankings, setRankings] = useState([]);
	const [sports, setSports] = useState([]);
	const [seriesList, setSeriesList] = useState([]);
	const [events, setEvents] = useState([]);
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		if (series && sport) {
			fetchOverallRankings(series, sport);
		}
	}, [series, sport]);

	useEffect(() => {
		fetchSports();
		fetchSeries();
		fetchEvents();
	}, []);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		handleResize(); // Initial check
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const fetchSports = async () => {
		try {
			const res = await getSports();
			setSports(res.data);
			const slopeIndex = res.data.findIndex(
				(sport) => sport.name === 'Slopestyle',
			);
			setSport(res.data[slopeIndex]._id); // Set default sport
		} catch (error) {
			console.error('Error fetching sports:', error);
		}
	};

	const fetchSeries = async () => {
		try {
			const res = await getSeries();
			setSeriesList(res.data);
			const crankworxIndex = res.data.findIndex(
				(series) => series.name === 'Crankworx',
			);
			setSeries(res.data[crankworxIndex]._id); // Set default series
		} catch (error) {
			console.error('Error fetching series:', error);
		}
	};

	const fetchEvents = async () => {
		try {
			const res = await getEvents();
			setEvents(res.data);
		} catch (error) {
			console.error('Error fetching events:', error);
		}
	};

	const fetchOverallRankings = async (selectedSeries, selectedSport) => {
		try {
			const data = await getOverallResults(selectedSport, selectedSeries);
			setRankings(data);
		} catch (error) {
			console.error('Error fetching overall rankings:', error);
		}
	};

	const renderRankingTable = (gender) => {
		if (!rankings) return null;

		const filteredRankings = rankings.filter(
			(ranking) => ranking?.rider_gender === gender,
		);

		return (
			<Table striped bordered hover>
				<thead>
					<tr>
						<th>Rank</th>
						<th>Rider Name</th>
						<th>Points</th>
					</tr>
				</thead>
				<tbody>
					{filteredRankings.map((ranking, index) => (
						<tr key={index}>
							<td>{index + 1}</td>
							<td>
								<ReactCountryFlag
									countryCode={getCountryISO2(
										ranking.rider_country.toUpperCase().trim(),
									)}
									svg
									style={{ width: '3.5rem' }}
								/>
								{`${ranking.rider_first_name} ${ranking.rider_last_name}`}
							</td>
							<td>{ranking.total_points}</td>
						</tr>
					))}
				</tbody>
			</Table>
		);
	};

	return (
		<Container fluid className='w-100 mx-0 px-0'>
			<Helmet>
				<title>Rankings - The Race Companion</title>
				<meta
					name='description'
					content='Current Rider Rankings for UCI Enduro, Crankworx Slopestyle, UCI and Crankworx Downhill and Freeride in Crankworx, World cups, Red Bull Hardline and Red Bull Rampage.'
				/>
				<meta name='viewport' content='width=device-width, initial-scale=1' />
			</Helmet>
			<Row className='m-0 p-0'>{isMobile ? <MobileAd /> : <TopAd />}</Row>
			<HomeTopRow events={events} />
			<Row noGutters>
				<Col lg={2} className='d-none d-lg-block text-start'>
					<LeftAd />
				</Col>
				<Col xs={12} lg={8} className='bg-light' style={{ zIndex: 1000 }}>
					<Row className='justify-content-md-start mt-4'>
						<Col md={6}>
							<Form className='mx-md-0 mx-2'>
								<Form.Group className='mb-3'>
									<Form.Label>Series</Form.Label>
									<Form.Select
										value={series}
										onChange={(e) => setSeries(e.target.value)}
									>
										{/* Populate with series options */}
										{seriesList.map((series) => (
											<option key={series._id} value={series._id}>
												{series.name}
											</option>
										))}
									</Form.Select>
								</Form.Group>
								<Form.Group className='mb-3'>
									<Form.Label>Sport</Form.Label>
									<Form.Select
										value={sport}
										onChange={(e) => setSport(e.target.value)}
									>
										{/* Populate with sport options */}
										{sports.map((sport) => (
											<option key={sport._id} value={sport._id}>
												{sport.name}
											</option>
										))}
									</Form.Select>
								</Form.Group>
							</Form>
						</Col>
					</Row>
					<Row className='mt-4'>
						<Col md={6}>
							<Card className='mx-md-0 mx-2 mb-md-0 mb-3'>
								<Card.Header className='bg-success text-white'>
									Men's Rankings
								</Card.Header>
								<Card.Body>{renderRankingTable('m')}</Card.Body>
							</Card>
						</Col>
						<Col md={6}>
							<Card className='mx-md-0 mx-2 mb-md-0 mb-3'>
								<Card.Header className='bg-success text-white'>
									Women's Rankings
								</Card.Header>
								<Card.Body>{renderRankingTable('f')}</Card.Body>
							</Card>
						</Col>
					</Row>
				</Col>
				<Col lg={2} className='d-none d-lg-block text-end'>
					<RightAd />
				</Col>
			</Row>
		</Container>
	);
};

export default RankingPage;
