import React from 'react';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';

const AboutUs = () => {
	return (
		<Container className='mt-5'>
			<Row className='justify-content-center'>
				<Col md={8}>
					<Card className='shadow'>
						<Card.Header as='h5'>About Us</Card.Header>
						<Card.Body style={{ fontSize: '0.8rem' }}>
							<Card.Text className='mt-2'>
								Our idea is to create a platform to follow all competitions
								mountain biking. Through fantasy, we hope to expand the audience
								of professional mountain biking and create engagement in the
								sport. We don't know exactly where this will go, but in the
								meantime, we hope to make this as awesome as possible.
							</Card.Text>
							<Card.Title>Kurtis Gassewitz</Card.Title>
							<Card.Text>
								Living in Squamish, British Columbia, I started
								DownhillMTBFantasy last year as a project while recovering from
								an illness. I reached out to The Ride Companion last year and
								together we have tried to create the possible fantasy platform
								to keep everyone engaged with the sport of mountain biking.
							</Card.Text>
							<Card.Title>
								Davi Birks & Olly Wilkins - The Ride Companion
							</Card.Title>
							<Card.Text>
								A podcast featuring Olly Wilkins and Davi Birks, chatting all
								things mountain biking and beyond. Based in the UK, The Ride
								Companion aims to explore various aspects of mountain biking,
								sharing stories, experiences, and insights. For more content,
								visit{' '}
								<a
									href='http://theridecompanion.co.uk'
									target='_blank'
									rel='noopener noreferrer'
								>
									theridecompanion.co.uk
								</a>
								.
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default AboutUs;
