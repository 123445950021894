import apiInstance from "./api";

export const createResult = async (resultData) => {
	try {
		const res = await apiInstance.post("/results", {
			resultData,
		});
		return res;
	} catch (error) {
		console.error("Error creating result:", error);
	}
};

export const getResults = async (event) => {
	try {
		const res = await apiInstance.get(`/results/${event}`);
		return res.data;
	} catch (error) {
		console.error("Error fetching results:", error);
	}
};

export const getOverallResults = async (sport, series) => {
	try {
		const res = await apiInstance.get(`/results/overall/${sport}/${series}`);
		return res.data;
	} catch (error) {
		console.error("Error fetching overall results:", error);
	}
};

export const getResultsBySportAndSeries = async (sport, series) => {
	try {
		const res = await apiInstance.get(
			`/results/sport/${sport}/series/${series}`,
		);
		return res.data;
	} catch (error) {
		console.error("Error fetching results:", error);
	}
};

export const getEventAnalytics = async (eventId) => {
	return apiInstance.get(`/results/mvp/${eventId}`);
};
