export const formatDate = (date) => {
	const dateObject = new Date(date);
	const year = dateObject.getFullYear();
	const month = String(dateObject.getMonth() + 1).padStart(2, "0");
	const day = String(dateObject.getDate()).padStart(2, "0");
	return `${year}-${month}-${day}`;
};

export const calculateMultiplier = (
	riderRating,
	bestRating = 95,
	worstRating = 50,
	maxMultiplier = 10,
	minMultiplier = 1,
) => {
	// Normalize the rider's rating to a 0-1 scale
	const normalizedRating =
		(riderRating - worstRating) / (bestRating - worstRating);
	// Calculate the multiplier inversely proportional to the rating
	const multiplier =
		maxMultiplier - normalizedRating * (maxMultiplier - minMultiplier);

	const roundedMultiplier = Math.round(multiplier);
	return roundedMultiplier;
};

//Calculate points for Pick and rank
//Riders chosen in the top $numberOfMen who are within this number get +100 points
//Riders who's rank is guessed correctly get another +100 points
export const calculatePickAndRankPoints = (
	riderRank,
	riderGuess,
	riderGender,
	numberOfMen,
	numberOfWomen,
) => {
	const number = riderGender === "m" ? numberOfMen : numberOfWomen;
	const correctRankPoints = riderRank === riderGuess ? 100 : 0;
	const withinRangePoints = riderRank <= number ? 100 : 0;
	return correctRankPoints + withinRangePoints;
};

//Calculate the rider value for budget mode
//riders rated 100 get a value of 1000000.
//riders rated as 50 get a value of 100000.
export const calculateRiderValue = (riderRating) => {
	const bestRating = 100;
	const worstRating = 60;
	const bestValue = 1000000;
	const worstValue = 100000;
	const normalizedRating =
		(riderRating - worstRating) / (bestRating - worstRating);
	const value = worstValue + normalizedRating * (bestValue - worstValue);

	//round to the nearest 50000
	return Math.round(value / 50000) * 50000;

	// return value;
};

export const formatCurrency = (value, locale = "en-US", currency = "USD") => {
	return new Intl.NumberFormat(locale, {
		style: "currency",
		currency: currency,
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	}).format(value);
};
