import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Card, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { createAd, getAds, updateAd } from '../../services/adService';

const AdminAdManagerPage = () => {
	const [ads, setAds] = useState([]);
	const [selectedAd, setSelectedAd] = useState(null);
	// const [name, setName] = useState('');
	const [formData, setFormData] = useState({
		leftTowerAdUrl: '',
		rightTowerAdUrl: '',
		bannerAdUrl: '',
		mobileAdUrl: '',
		status: false,
		url: '',
		name: '',
	});

	useEffect(() => {
		fetchAds();
	}, []);

	const fetchAds = async () => {
		try {
			const response = await getAds();
			setAds(response.data);
		} catch (error) {
			console.error('Failed to fetch ads:', error);
		}
	};

	const handleFileChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.files[0] });
	};

	const handleSelectAd = (adId) => {
		const ad = ads.find((ad) => ad._id === adId);
		setSelectedAd(ad);
		setFormData({
			leftTowerAdUrl: ad.leftTowerAdUrl,
			rightTowerAdUrl: ad.rightTowerAdUrl,
			bannerAdUrl: ad.bannerAdUrl,
			mobileAdUrl: ad.mobileAdUrl,
			status: ad.status,
			url: ad?.url,
			name: ad?.name,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const data = new FormData();
		Object.keys(formData).forEach((key) => {
			data.append(key, formData[key]);
		});

		try {
			const response = selectedAd
				? await updateAd(selectedAd._id, data)
				: await createAd(data, formData.name);
			alert('Ad saved successfully!');
			fetchAds();
		} catch (error) {
			console.error('Failed to save ad:', error);
			alert('Failed to save ad.');
		}
	};

	return (
		<Container>
			<Row className='my-4'>
				<Col>
					<h2>Manage Ads</h2>
					<Form onSubmit={handleSubmit}>
						{/* File inputs and status toggle */}
						<Form.Group controlId='formAdName' className='mb-3'>
							<Form.Label>Ad Name</Form.Label>
							<Form.Control
								type='text'
								placeholder='Enter ad name'
								name='name'
								value={formData.name}
								onChange={(e) =>
									setFormData({ ...formData, name: e.target.value })
								}
							/>
						</Form.Group>

						<Form.Group controlId='formAdUrl' className='mb-3'>
							<Form.Label>Ad URL</Form.Label>
							<Form.Control
								type='text'
								placeholder='Enter ad URL'
								name='url'
								value={formData.url}
								onChange={(e) =>
									setFormData({ ...formData, url: e.target.value })
								}
							/>
						</Form.Group>

						<Form.Group controlId='formFileLeftTower' className='mb-3'>
							<Form.Label>Left Tower Ad URL</Form.Label>
							<Form.Control
								type='file'
								name='leftTowerAdUrl'
								onChange={handleFileChange}
							/>
						</Form.Group>
						{/* Repeat for other fields */}
						<Form.Group controlId='formFileRightTower' className='mb-3'>
							<Form.Label>Right Tower Ad URL</Form.Label>
							<Form.Control
								type='file'
								name='rightTowerAdUrl'
								onChange={handleFileChange}
							/>
						</Form.Group>
						<Form.Group controlId='formFileBanner' className='mb-3'>
							<Form.Label>Banner Ad URL</Form.Label>
							<Form.Control
								type='file'
								name='bannerAdUrl'
								onChange={handleFileChange}
							/>
						</Form.Group>
						<Form.Group controlId='formFileMobile' className='mb-3'>
							<Form.Label>Mobile Ad URL</Form.Label>
							<Form.Control
								type='file'
								name='mobileAdUrl'
								onChange={handleFileChange}
							/>
						</Form.Group>
						<Form.Check
							type='switch'
							id='status-switch'
							label='Active'
							checked={formData.status}
							onChange={(e) =>
								setFormData({ ...formData, status: e.target.checked })
							}
						/>
						<Button variant='primary' type='submit'>
							{selectedAd ? 'Update Ad' : 'Create Ad'}
						</Button>
					</Form>
				</Col>
				<Col>
					<h2>Existing Ads</h2>
					{ads.map((ad) => (
						<Card key={ad._id} onClick={() => handleSelectAd(ad._id)}>
							<Card.Body>
								<Card.Title>{ad.name}</Card.Title>
								<Card.Text>
									Status: {ad.status ? 'Active' : 'Inactive'}
								</Card.Text>
							</Card.Body>
						</Card>
					))}
				</Col>
			</Row>
		</Container>
	);
};

export default AdminAdManagerPage;
